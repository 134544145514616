import {useEffect, useMemo, useState} from "react";
import {colors} from "../colors";
import {useUserData} from "../../../hooks/user/useUserData";
import axios from "axios";
import {API} from "../../../helpers/constants";
import moment from "moment";
import {formatDate, formatTypes} from "../../../helpers/date";

export const useTopOrders = (selectedStore) => {
    const [data, setData] = useState(null)
    const [range,] = useState([
        moment().subtract(1, 'months').toDate(), new Date()
    ])
    // const {stores} = useUserData()
    const {data: userData, language, token} = useUserData()
    useEffect(() => {
        axios({
            method: 'get',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: API + `/${language}/b2b/chart/top-five-categories-I-order`,
            params: {
                CodeCust: `'${userData.erpCode}'`,
                ...(selectedStore && selectedStore !== 'all' ? {YpokPelatiCODE: selectedStore.erpCode} : {}),
                From_RegistrationDate: formatDate(range[0], formatTypes.DATE_ONLY_FILTER),
                To_RegistrationDate: formatDate(range[1], formatTypes.DATE_ONLY_FILTER),
                TopSelect: 10
            }
        }).then(({data: unmappedData}) => {
            const mappedData = unmappedData.map(datum => ({
                code: datum.CodeCategory,
                description: {
                    el: datum.DescriptionCategory,
                    en: datum.EngDecrCategory,
                },
                value: Number(datum.Sales_Value)
            }))
            setData(mappedData)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore])
    const result = useMemo(() => data ? {
        labels: data.map(d => d.description[language]),
        datasets: [
            {
                label: '',
                data: data.map(d => d.value),
                backgroundColor: colors,
                borderWidth: 0,
            },
        ]
    } : {
        labels: [],
        datasets: []
    }, [data, language])
    return {data: result}
}