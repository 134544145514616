import type {FileType, UnmappedFileType, Message, UnmappedMessage} from "./type";
import moment from "moment";
import {API} from "../../helpers/constants";

export const mapFile: FileType = (datum: UnmappedFileType) => {
    if (!datum) return
    return {
        id: datum.id,
        name: datum.OriginFileName,
        url: `${API}/en/messages/attachments/download/${datum.id}`
    }
}

export const mapMessage: Message = (datum: UnmappedMessage) => {
    if (!datum) return
    return {
        id: datum.id,
        date: datum.SendDt ? moment(datum.SendDt).toDate() : null,
        title: datum.Subject,
        text: datum.Body,
        viewedDate: datum.Viewed ? moment(datum.Viewed).toDate() : null,

        actionId: datum.ActionId,
        actionTypeId: datum.ActionTypeId,
        lastModifiedAt: moment(datum.LastModifiedAt).toDate(),
        lastModifiedBy: datum.LastModifiedBy,
        senderUsername: datum.username,
        needReply: datum.NeedReply === '1',
        parentId: datum.ParentId,
        replyUntilDate: datum.ReplyUntilDt,
        replyUntilTime: datum.ReplyUntilTm,
        senderId: datum.SenderId,
        type: datum.Type,
        typeId: Number(datum.TypeId),
    }
}
