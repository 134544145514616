//@flow
import React, {Fragment, useEffect, useState} from 'react';
import Button from "../../components/button/button";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_ORDERS} from "../../routers/routes";
import Icon from "../../components/icon/icon";
import Images from "../../assets/images";
import Util from "../../components/util/util";
import Title from "../../components/title/title";
import Divider from "../../components/divider/divider";
import Preview from "../../components/preview/preview";
import "./order.css"
import BillingAndShippingInfo from "../../components/order/partials/billingAndShippingInfo/billingAndShippingInfo";
import useOrderAction from "../../hooks/order/useOrderAction";
import {useParams} from "react-router";
import useOrderDetailsData from "../../hooks/order/useOrderDetailsData";
import {formatDate, formatTypes} from "../../helpers/date";
import {calculateProductTax, categorizeProducts} from "../../helpers/helpers";
import {useTranslation} from "react-i18next";
import {PAGE_ORDERS} from "../../locales/pages/namespaces";
import OrderStatus from "../../components/orderStatus/orderStatus";
import Loading from "../../components/loading/loading";
import {useOrderData} from "../../hooks/order/useOrderData";
import CloneOrderModal from "../../components/cloneOrderModal/cloneOrderModal";
import ExistingOrderModal from "../../components/existingOrderModal/existingOrderModal";
import useOrderCreate from "../../hooks/order/useOrderCreate";
import OrderType from "../../components/orderType/orderType";
import {useUserData} from "../../hooks/user/useUserData";
import {useHasPermission} from "../../modules/permission/hooks/useHasPermission";
import {Permissions} from "../../helpers/constants";

const View = ({order}) => {
    const {t} = useTranslation(PAGE_ORDERS)
    const {
        typeDescription,
        type,
        id,
        price,
        code,
        title,
        date,
        statusId,
        notes,
        billing,
        shipping,
        products,
        acceptBalance,
        orderSheetIsInternal
    } = order
    const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false)
    const [showCloneOrderModal, setShowCloneOrderModal] = useState(false)

    const isAdmin = useHasPermission([Permissions.AccessAdmin], {shouldView: true})
    const isSalesPerson = useHasPermission([Permissions.AccessSalesPerson], {shouldView: true})

    const categorizedProducts = categorizeProducts(products)
    const tax = calculateProductTax(products).toFixed(2)

    const {data} = useUserData()
    const {cloneOrder} = useOrderAction()
    const {activeOrderOfType, showActiveOrderModal, setShowActiveOrderModal} = useOrderCreate(type)

    const onRepeatOrderClick = () => {
        if (!activeOrderOfType) {
            cloneOrder(id)
            setShowCloneOrderModal(true)
            return
        }
        setShowActiveOrderModal(true)
    }

    return (
        <Fragment>
            <Util align={'flex-start'}>
                <div>
                    <Title variant={"h4"} defaultFont>
                        #{code} - {title}
                    </Title>
                    <Divider/>
                </div>
                <div className={'info'}>
                    <Util align={'baseline'}>
                        <strong>{t('Date Placed')}: </strong>
                        <span>{formatDate(date, formatTypes.DATE_ONLY)}</span>
                    </Util>
                    <Divider thin/>
                    <Util align={'baseline'}>
                        <strong>{t('Type')}: </strong>
                        <OrderType type={type} typeDescription={typeDescription}/>
                    </Util>
                    <Divider thin/>
                    <Util align={'baseline'}>
                        <strong>{t('Status')}: </strong>
                        <OrderStatus status={statusId}/>
                    </Util>
                </div>
            </Util>
            <br/>
            {Object
                .keys(categorizedProducts)
                .map(category => {
                    const products = categorizedProducts[category];
                    return (
                        <Fragment key={category}>
                            <br/>
                            <Title style={{marginBottom: '.5rem'}} variant={"h4"}>
                                {category}
                            </Title>
                            {products.map((product) => (
                                <Preview
                                    orderId={id}
                                    key={product.id}
                                    product={product}
                                    disabled
                                    orderType={type}
                                />
                            ))}
                        </Fragment>
                    )
                })}
            <br/>
            <div className="order-page__bottom">
                <div className="order-page__bottom_left">
                    {notes && (
                        <div className={"order-page__notes"}>
                            <Title variant={"h4"}>
                                {t('notes')}
                            </Title>
                            <div dangerouslySetInnerHTML={{__html: notes}}/>
                        </div>
                    )}
                    <br/>
                    {billing && shipping && (
                        <BillingAndShippingInfo
                            billing={billing}
                            shipping={shipping}
                        />
                    )}
                </div>
                <div className="order-page__bottom_right">
                    <Util>
                        <Title variant={"h4"}>
                            {t('total_cost')} {t('with_tax')}
                        </Title>
                        <Title variant={"h4"} subtitle={t('tax') + ' (' + tax + data?.currencySymbol + ')'}>
                            {price}{data?.currencySymbol}
                        </Title>
                    </Util>
                    <Divider spacingTop={1} spacingBottom={1}/>
                    <div>
                        <Util>
                            {acceptBalance ?
                                <div>
                                    <Icon icon={Images.icons.checked}/>
                                    <span style={{paddingLeft: '.5rem'}}>{t('accept_account_balance')}</span>
                                </div>
                                : null}
                        </Util>
                    </div>
                    {((orderSheetIsInternal && (isAdmin || isSalesPerson)) || (!orderSheetIsInternal && !isSalesPerson)) && (
                        <Button.Group>
                            <Button onClick={onRepeatOrderClick}>
                                {t('repeat_order')}
                            </Button>
                            <Button onClick={() => setShowCreateTemplateModal(true)}>
                                {t('create_template')}
                            </Button>
                        </Button.Group>
                    )}
                </div>
            </div>
            <CloneOrderModal
                hideActions
                createTemplate={false}
                show={showCloneOrderModal}
                onClose={() => setShowCloneOrderModal(false)}
                orderId={id}
            />
            <CloneOrderModal
                createTemplate
                show={showCreateTemplateModal}
                onClose={() => setShowCreateTemplateModal(false)}
                orderId={id}
            />
            <ExistingOrderModal
                type={type}
                show={showActiveOrderModal}
                onClose={() => setShowActiveOrderModal(false)}
            />
        </Fragment>
    )
}

const Order = () => {
    const {loading} = useOrderData()
    const {t} = useTranslation(PAGE_ORDERS)
    const {id} = useParams()
    const {getOrderDetails} = useOrderAction()
    const {order} = useOrderDetailsData(id)

    useEffect(() => {
        getOrderDetails(id)
    }, [getOrderDetails, id])

    return (
        <div className={'bg-container contained-page order-page'}>
            <Loading show={loading}/>
            <Button variant={'link'} component={Link} to={getRouteUrl(ROUTE_PAGE_ORDERS)}>
                <Icon icon={Images.icons.arrowLeft}/> {t('back')}
            </Button>
            {order && <View order={order}/>}
        </div>
    )
}

export default Order;