//@flow
import {useCallback} from 'react'
import {useDispatch} from 'react-redux'
import * as actions from '../../actions/actions'
import {AnnouncementTypes, ReadStatus} from "../../helpers/constants";
import {useUserData} from "../user/useUserData";

const useMessageAction = (actionType, category, readStatus) => {
    const dispatch = useDispatch()
    const {selectedStore} = useUserData()

    const getMessages = useCallback((pageIndex, pageSize) => {
        const hasCategory = (category && category !== AnnouncementTypes.All)
        const hasReadStatus = (readStatus && readStatus !== ReadStatus.Any)
        dispatch(actions.getMessages({
                sorted: [
                    {direction: 'descending', name: 'SendDt'}
                ],
                actionType: actionType,
                ...(hasCategory ? {
                    TypeId: category.toString(),
                } : {}),
                ...(hasReadStatus ? {
                    ViewDt: readStatus === ReadStatus.Read,
                } : {}),
                requiresCounts: true,
                skip: pageIndex * pageSize,
                take: pageSize,
                AddressId: selectedStore.id,
            })
        )
    }, [readStatus, actionType, category, selectedStore?.id, dispatch])

    const getMessageDetails = useCallback((id) => {
        dispatch(actions.getMessageDetails({
            actionType,
            id: id
        }))
    }, [actionType, dispatch])

    const getMessageAttachments = useCallback((id) => {
        dispatch(actions.getMessageAttachments({
            id: id
        }))
    }, [dispatch])

    const setMessageViewed = useCallback((id) => {
        dispatch(actions.setMessageViewed({
            data: id
        }))
    }, [dispatch])

    return {
        getMessages,
        getMessageAttachments,
        getMessageDetails,
        setMessageViewed
    }
}

export default useMessageAction