import { useCallback } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../actions/actions";
import { useUserData } from "../user/useUserData";

const useProductsOfInterestAction = () => {
	const dispatch = useDispatch()
	const {selectedStore, data} = useUserData()
	
	const setMessage = useCallback((message) => {
		dispatch(actions.setMessageProdOfInterest(message))
	}, [dispatch])
	
	const getProductsOfInterest = useCallback((pageIndex, pageSize) => {
		dispatch(actions.getProductsOfInterest({
			AddressId: selectedStore.id
		}))
	}, [dispatch, selectedStore.id])
	
	const storeProductsOfInterest = useCallback((items = []) => {
		dispatch(actions.storeProductsOfInterest({
			AddressId: selectedStore.id,
			Items: items
		}))
	}, [dispatch, selectedStore.id])
	
	return {
		getProductsOfInterest,
		storeProductsOfInterest,
		setMessage
	}
}

export default useProductsOfInterestAction