//@flow
import {useTranslation} from 'react-i18next'
import {TABLE_COMPONENT} from "../../../../locales/components/namespaces";
import Pagination from "../pagination/pagination";
import {createContext, Fragment, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import {VariableSizeList as List} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import ListContainer from "./virtualized/listContainer";


type Props = {
    usingInfiniteScroll?: boolean,
    noMeta?: boolean,
    noPagination?: boolean,
    renderRow: Function,
    initialState: {
        pageIndex: number,
        pageSize: number,
    }
}

const UnstructuredTableView = (props: Props) => {
    const {t} = useTranslation(TABLE_COMPONENT)
    const {
        virtualized,
        usingInfiniteScroll,
        noPagination,
        noMeta,
        renderRow,
        loading,
        onPageSizeChange,
        pageCount: controlledPageCount,
    } = props
    const {
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        setPageSize,
        pageIndex,
        pageSize
    } = props.table


    return (
        <Fragment>
            {/*{virtualized ? (*/}
            {/*        <ListContainer*/}
            {/*            page={page}*/}
            {/*            renderRow={renderRow}*/}
            {/*            prepareRow={prepareRow}*/}
            {/*        />*/}
            {/*    )*/}
            {/*    : (*/}
            {/*        page.map((row, index, array) => {*/}
            {/*            prepareRow(row)*/}
            {/*            return renderRow(row, index, page)*/}
            {/*        })*/}
            {/*    )}*/}
            {(
                page.map((row, index, array) => {
                    prepareRow(row)
                    return renderRow(row, index, array)
                })
            )}
            <div className={'table__meta'}>
                {loading ?
                    <span>{t('Loading')}...</span>
                    : page.length > 0 ?
                        !noMeta && !usingInfiniteScroll && (
                            <span>
                                    {t('Showing')} {page.length} {t('of')} ~{controlledPageCount * pageSize}{' '}
                                {t('results')}
                                </span>
                        )
                        : <span>{t('no_results')}</span>}
            </div>
            {!noPagination && !usingInfiniteScroll && (
                <Pagination
                    pageSize={pageSize}
                    setPageSize={(size) => {
                        setPageSize(size)
                        if (onPageSizeChange) {
                            onPageSizeChange(size)
                        }
                    }}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    nextPage={nextPage}
                    pageIndex={pageIndex}
                    previousPage={previousPage}
                />
            )}
        </Fragment>
    )
}

export default UnstructuredTableView