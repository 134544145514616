//@flow
import * as React from 'react';
import classNames from "classnames";
import Button from "../button/button";
import Icon from "../icon/icon";
import Images from "../../assets/images";
import './modal.css'

type Props = {
    show?: boolean,
    children: React.Node,
    onClose?: Function,
    style?: Object,
    className?: string,
    wrapperStyle?: string
};

const Modal = (props: Props) => {
    const {show, onClose, children, style, className, wrapperStyle} = props
    if (!show) return null;
    return (
        <div
            onClick={onClose}
            style={wrapperStyle}
            className={classNames('modal', className, {
                'modal--visible': show
            })}
        >
            <section
                className={'modal__content'}
                onClick={(e) => {
                    e.stopPropagation()
                }}
            >
                <div className={'modal__container'} style={style}>
                    <img src={Images.modal.corner_2} alt="background corner"/>
                    <img src={Images.modal.corner_1} alt="background corner"/>
                    <Button variant={"icon"} onClick={onClose}>
                        <Icon icon={Images.icons.close}/>
                    </Button>
                    <div>
                        {children}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Modal;