//@flow
import * as React from 'react'
import './button.css'
import classNames from "classnames";
import Icon from "../icon/icon";

type ButtonProps = {
    noAction?: boolean,
    outlined?: boolean,
    variant?: 'icon' | 'action' | 'link',
    size?: 'xs' | 'sm' | 'md' | 'lg',
    type?: 'button' | 'submit',
    onClick?: Function,
    disabled?: boolean,
    component?: React.Node,
    children?: React.Node,
    sup?: React.Node,
    icon?: React.Node,
    className?: Object,
    invertSup?: boolean,
}

const Button = (props: ButtonProps) => {
    const {
        invertSup,
        noAction,
        outlined,
        variant = 'default',
        size = 'md',
        image,
        type = 'button',
        component,
        children,
        disabled,
        sup,
        icon,
        ...otherProps
    } = props

    const Component = component ? component : 'button'

    return (
        <Component
            {...otherProps}
            disabled={disabled}
            type={type}
            className={classNames('button', props.className, {
                ['button--' + size]: Boolean(size),
                ['button--' + variant]: Boolean(variant),
                'button--disabled': disabled,
                'button--invert-sup': invertSup,
                'button--no-action': noAction,
                'button--outlined': outlined,
            })}
        >
            <div className={'button__main'}>{children}</div>
            {sup && <sup className={'button__sup'}>{sup}</sup>}
            {icon && <span className={'button__suffix'}><Icon icon={icon}/></span>}
        </Component>
    )
}

type GroupProps = {
    children?: React.Node,
    size?: 'xs' | 'sm' | 'md' | 'lg',
    spacingTop?: 1 | 2,
    spacingBelow?: 1 | 2,
    className?: string,
    dense?: boolean,
    expandedButtons?: boolean,
}

const Group = (props: GroupProps) => {
    const {
        children,
        size,
        className,
        spacingTop,
        spacingBelow,
        dense,
        expandedButtons
    } = props
    return (
        <div
            className={classNames('button-group', className, {
                'button-group--dense': dense,
                'button-group--expandedButtons': expandedButtons,
                ['button-group--' + size]: Boolean(size),
                ['button-group--spacing-top-' + spacingTop]: Boolean(spacingTop),
                ['button-group--spacing-below-' + spacingBelow]: Boolean(spacingBelow),
            })}
        >
            {children}
        </div>
    )
}

Button.Group = Group

export default Button