//@flow
import * as React from 'react';
import {Fragment, useEffect, useState} from 'react';
import ImageGallery from "react-image-gallery";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import './product.css'
import Accordion from "../../../components/accordion/accordion";
import Divider from "../../../components/divider/divider";
import Number from "../../../components/form/elements/number/number";
import Textarea from "../../../components/form/elements/textarea/textarea";
import Title from "../../../components/title/title";
import { PAGE_PRODUCTS_OF_INTEREST } from "../../../locales/pages/namespaces";

type Props = {
    
    onSubmit: Function
}

const ProductOfInterest = (props: Props) => {
    const {t} = useTranslation(PAGE_PRODUCTS_OF_INTEREST)
  
    const {
        onSubmit,
        setNewComment,
        newComment,
        newQuantity,
        setQuantityChangedByUser,
        setNewQuantity,
        step,
        product: {
            basicUnitOfMeasurement,
            comment,
            description,
            id,
            images,
            labels,
            message,
            multiplier,
            quantity,
            thumbnail,
            title,
            unitOfMeasurement
        }
    } = props

    const hasImages = images && images.length > 0
    const [showDescription, setShowDescription] = useState(true)
    return (
        <Fragment>
            <div className={'add-to-card'}>
                <div>
                    <Title defaultFont variant={"h3"}>
                        {title}
                    </Title>
                    <div className={'product__top'}>
                        <span>{t('Package')}: 1 {unitOfMeasurement}</span>
                        <span>|</span>
                        <span>{t('Quantity')}: {multiplier} {basicUnitOfMeasurement}</span>
                    </div>
                </div>
                <div className={classNames('previewInterest__wrapper', {
                    'previewInterest__wrapper--displayAsTile': true,
                })}>
                    <div className={'previewInterest__content'}>
                        <div className={'previewInterest__notes'}>
                            <Textarea
                                placeholder={t('write_your_message')}
                                value={newComment}
                                name={'notes'}
                                rows={5}
                                onChange={(evt) => {
                                    setNewComment(evt.target.value)
                                }}
                                onBlur={(evt) => {
                                    onSubmit()
                                }}
                            />
                        </div>
                        <div className={'previewInterest__quantity-select'}>
                            <div
                                className={'previewInterest__input'}
                            >
                                <Number
                                    value={newQuantity}
                                    min={0}
                                    step={step}
                                    onChange={(evt) => {
                                        setQuantityChangedByUser(true)
                                        let newQuantity = evt.target.valueAsNumber
                                        setNewQuantity(newQuantity);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'product'}>
                <div className={classNames('product__details', {
                    'product__details--no-background': !hasImages
                })}>
                    <div>
                        {hasImages && (
                            <ImageGallery
                                autoPlay={false}
                                showPlayButton={false}
                                showNav={false}
                                thumbnailPosition={'right'}
                                items={images}
                            />
                        )}
                    </div>
                    {description && (
                        <div className={'product__description'} style={{ marginTop: '1rem' }}>
                            <Accordion
                                isActive={showDescription}
                                onExpandedToggle={() => setShowDescription(!showDescription)}
                                title={
                                    <Title variant={"h4"}>
                                        {t('description')}
                                    </Title>
                                }
                            >
                                <div dangerouslySetInnerHTML={{ __html: description }}/>
                            </Accordion>
                            <Divider thin spacingBottom={2}/>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export default ProductOfInterest;