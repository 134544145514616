import {useSalesPersonAction} from "./useSalesPersonAction";
import {useEffect} from "react";
import {useSalesPersonData} from "./useSalesPersonData";

const useSalesPersons = () => {
    const {getSalesPersonUnderlings} = useSalesPersonAction()
    const {salesPersons} = useSalesPersonData()
    useEffect( () => {
        getSalesPersonUnderlings()
    }, [getSalesPersonUnderlings])

    return {
        salesPersons
    }
}

export default  useSalesPersons