//@flow
import React from 'react';
import Modal from "../modal/modal";
import Title from "../title/title";
import {formatDate, formatTypes} from "../../helpers/date";
import Button from "../button/button";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_ORDER_EDIT} from "../../routers/routes";
import {useTranslation} from "react-i18next";
import {CREATE_ORDER_COMPONENT} from "../../locales/components/namespaces";
import {useUserData} from "../../hooks/user/useUserData";
import useOrderCreate from "../../hooks/order/useOrderCreate";
import "./existingOrderModal.css"

type Props = {
    type?: string,
    show?: boolean,
    onClose: Function
};

const ExistingOrderModal = (props: Props) => {
    const {type, show, onClose} = props
    const {t} = useTranslation(CREATE_ORDER_COMPONENT)
    const {selectedStore} = useUserData()
    const {
        // orderType,
        // orderTypes,
        activeOrderOfType,
        openCreateMenu,
        // onCreateButtonClick
    } = useOrderCreate(type)
    if (!activeOrderOfType) return null
    return (
        <Modal
            show={show}
            onClose={onClose}
            className={'existing-order-modal'}
        >
            <div>
                <Title defaultFont variant={'body'}>
                    {selectedStore.address}
                </Title>
                <Title defaultFont variant={'h4'}>
                    {t('pending_order')}:
                </Title>
                <br/>
                <div>
                    {formatDate(activeOrderOfType.date, formatTypes.TITLE)}
                </div>
                <Button.Group spacingBelow={1} spacingTop={1}>
                    <Button onClick={() => {
                        openCreateMenu()
                        onClose()
                    }}>
                        {t('change')}
                    </Button>
                    <Button
                        component={Link}
                        to={getRouteUrl(ROUTE_PAGE_ORDER_EDIT, {id: activeOrderOfType.id})}>
                        {t('view')}
                    </Button>
                </Button.Group>
                {/*{orderTypes && orderTypes.length > 1 && orderType !== orderTypes[1].id && (*/}
                {/*    <Fragment>*/}
                {/*        <div>*/}
                {/*            {t('or')}*/}
                {/*        </div>*/}
                {/*        <Title defaultFont variant={'h4'}>*/}
                {/*            {t('create_order')}*/}
                {/*        </Title>*/}
                {/*        <Button.Group spacingBelow={1} spacingTop={1}>*/}
                {/*            <Button*/}
                {/*                onClick={() => onCreateButtonClick(orderTypes[1].id)}*/}
                {/*                variant={"action"}*/}
                {/*            >*/}
                {/*                {orderTypes[1].title}*/}
                {/*            </Button>*/}
                {/*        </Button.Group>*/}
                {/*    </Fragment>*/}
                {/*)}*/}
            </div>
        </Modal>
    )
}

export default ExistingOrderModal;