//@flow
import React from 'react';
import Title from "../../components/title/title";
import Divider from "../../components/divider/divider";

const Terms = () => {
    return (
        <div className={'bg-container bg-container--transparent contained-page'}>
            <Title>TERMS OF USE</Title>
            <Divider/>
            <p>
                Α. Όροι και Προϋποθέσεις Χρήσης<br/><br/>
                i. Εισαγωγή<br/><br/>
                1. Η ιστοσελίδα (και όλες οι ιστοσελίδες που τυχόν συνδέονται αυτομάτως) (εφεξής "Ιστοσελίδα") έχει
                δημιουργηθεί για την προσωπική ψυχαγωγία, πληροφόρηση, μόρφωση και επικοινωνία των χρηστών. Η χρήση της
                Ιστοσελίδας διέπεται από τους όρους και προϋποθέσεις του παρόντος, τους οποίους οι χρήστες/επισκέπτες
                της Ιστοσελίδας αφού διαβάσουν προσεκτικά αποδέχονται ανεπιφύλακτα, άνευ ετέρου τινός, με τη
                χρήση.<br/><br/>
                2. Οι όροι και προϋποθέσεις που παρατίθενται στο παρόν, καθώς και οποιαδήποτε άλλη εφαρμοστέα εθνική,
                κοινοτική ή διεθνής νομοθεσία που ισχύει αναφορικά με το Διαδίκτυο ή τον Παγκόσμιο Ιστό εν γένει,
                ισχύουν για όλους τους επισκέπτες ή χρήστες της Ιστοσελίδας.<br/><br/>
                3. Η COFFEE ISLAND (εφεξής "COFFEE ISLAND" ή «Η εταιρία») ή συνδεδεμένη μ' αυτή εταιρεία μπορεί ανά πάσα
                στιγμή να αναθεωρήσει αυτούς τους Όρους και Προϋποθέσεις ενημερώνοντας αυτό το μήνυμα. Οι αναθεωρήσεις
                είναι δεσμευτικές για τους χρήστες και θεωρείται ότι γίνονται αποδεκτές, ανεπιφύλακτα, άνευ ετέρου
                τινός, με τη χρήση.<br/><br/>
                4. Σε περίπτωση κατά την οποία η χρήση οποιασδήποτε υπηρεσίας της Ιστοσελίδας ρυθμίζεται από
                ειδικότερους όρους χρήσης, οι όροι αυτοί θεωρούνται ως ένα σύνολο με τους παρόντες όρους και
                προϋποθέσεις χρήσης. Σε περίπτωση όμως σύγκρουσης υπερισχύουν οι ειδικότεροι όροι χρήσης κάθε υπηρεσίας.<br/><br/>
                5. Η COFFEE ISLAND και οι συνδεδεμένες μ' αυτήν εταιρείες δεν δεσμεύονται σε καμία περίπτωση από το
                περιεχόμενο της Ιστοσελίδας.<br/><br/>
                6. Οι ανήλικοι απαγορεύεται να κάνουν χρήση της Ιστοσελίδας και του Περιεχομένου της, που, σύμφωνα με το
                νόμο, απευθύνονται αποκλειστικά σε ενήλικες. Αν παρά ταύτα ανήλικοι αυτοβούλως επισκεφθούν την
                Ιστοσελίδα ή κάνουν χρήση του Περιεχομένου της ή υπηρεσιών που προσφέρονται μέσω αυτής που είναι δυνατόν
                να θεωρηθούν ακατάλληλες γι' αυτούς, η COFFEE ISLAND και οι συνδεδεμένες μ' αυτήν εταιρείες δεν θα
                φέρουν καμία ευθύνη.<br/><br/>
                7. Η COFFEE ISLAND δικαιούται οποτεδήποτε, χωρίς προειδοποίηση, να διακόψει ή να αναστείλει συνολικά ή
                εν μέρει τη λειτουργία της Ιστοσελίδας ή μεταβάλει τη φύση και το περιεχόμενό της. Επίσης, η λειτουργία
                της Ιστοσελίδας ενδέχεται να διακοπεί, να ανασταλεί ή να δυσχερανθεί είτε μόνιμα είτε προσωρινά εξαιτίας
                γεγονότων κειμένων πέραν της δυνατότητας ελέγχου ή ανεξαρτήτως βουλήσεως της COFFEE ISLAND.<br/><br/>
                8. H COFFEE ISLAND θα καταβάλει εύλογες προσπάθειες για να διασφαλίσει τη συντήρηση και η διαθεσιμότητα
                της Ιστοσελίδας και του Περιεχομένου της αλλά η διαθεσιμότητα μπορεί να επηρεάζεται από τον εξοπλισμό
                των χρηστών, άλλα δίκτυα επικοινωνιών, το μεγάλο αριθμό ατόμων που προσπαθούν να χρησιμοποιήσουν το
                Διαδίκτυο ταυτόχρονα ή άλλες αιτίες παρεμβολής και μπορεί να αστοχήσει ή να χρειαστεί συντήρηση χωρίς
                καμία προειδοποίηση.<br/><br/>
                ii. Περιεχόμενο - Δικαιώματα Βιομηχανικής και Πνευματικής Ιδιοκτησίας<br/><br/>
                1. Το σήμα "COFFEE ISLAND" και άλλα συναφή, που χρησιμοποιούνται στην ιστοσελίδα είναι κατοχυρωμένο
                εμπορικό σήμα της "COFFEE ISLAND" ή άλλης συνδεδεμένης με αυτήν εταιρίας. Η Ιστοσελίδα και το
                περιεχόμενό της εν γένει, συμπεριλαμβανομένων χωρίς περιορισμό όλων των κειμένων και των εικόνων,
                φωτογραφιών (εφεξής το "Περιεχόμενο"), εκτός εάν δηλώνεται διαφορετικά για συγκεκριμένα δικαιώματα
                τρίτων, αποτελεί πνευματική και βιομηχανική ιδιοκτησία της COFFEE ISLAND και προστατεύεται από τις
                σχετικές διατάξεις του ελληνικού, κοινοτικού και διεθνούς δικαίου.<br/><br/>
                2. Το Περιεχόμενο χρησιμοποιείται αυτούσιο και είναι ανεπίδεκτο τροποποιήσεων ή άλλων παρεμβάσεων από το
                χρήστη. Επιπλέον, το Περιεχόμενο δεν δύναται να αποτελέσει εν όλω ή εν μέρει αντικείμενο συναλλαγής
                (εμπορικής ή μη), αντιγραφής, τροποποίησης, αναπαραγωγής, αναμετάδοσης, ή να μεταδοθεί ή να διανεμηθεί
                με οποιονδήποτε τρόπο από οποιονδήποτε χρήστη χωρίς την προηγούμενη έγγραφη συναίνεση της COFFEE ISLAND.<br/><br/>
                3. Τα προϊόντα ή/και οι υπηρεσίες ή/και η επωνυμία ή/και τα εμπορικά σήματα ή διακριτικά γνωρίσματα
                τρίτων που τυχόν εμφανίζονται ή εμφανισθούν στην Ιστοσελίδα αποτελούν πνευματική και βιομηχανική
                ιδιοκτησία των τρίτων, οι οποίοι φέρουν τη σχετική ευθύνη για το περιεχόμενο και τις υπηρεσίες ή/και
                προϊόντα.<br/><br/>
                4. Οποιαδήποτε επικοινωνία ή υλικό ή στοιχεία μεταφέρονται ή αναμεταδίδονται ή αποστέλλονται μέσω
                υπηρεσιών που τυχόν προσφέρονται από την Ιστοσελίδα και εν γένει το σύνολο των πληροφοριών που
                υποβάλλεται στην COFFEE ISLAND μέσω της παρούσας Ιστοσελίδας θα θεωρείται και θα αντιμετωπίζεται ως μη
                εμπιστευτικού χαρακτήρα και δεν θα αποτελεί περιουσιακό στοιχείο του εν λόγω χρήστη. Οτιδήποτε
                μεταφέρεται ή αναμεταδίδεται ή αποστέλλεται μέσω της Ιστοσελίδας, εφόσον αυτό είναι δυνατόν, ή
                υποβάλλεται στην COFFEE ISLAND μέσω της Ιστοσελίδας θα θεωρείται και θα παραμένει ιδιοκτησία της COFFEE
                ISLAND ή των συνδεδεμένων μ' αυτή εταιρειών και θα είναι ελεύθερη να χρησιμοποιεί, με την επιφύλαξη της
                ισχύουσας ελληνικής, κοινοτικής και διεθνούς νομοθεσίας, για κάθε σκοπό, κάθε ιδέα, σύλληψη,
                τεχνογνωσία, ή τεχνική μέθοδο που περιέχεται στις πληροφορίες, που οι χρήστες της Ιστοσελίδας παρέχουν
                στην COFFEE ISLAND και τις συνδεδεμένες μ' αυτήν εταιρείες μέσω της Ιστοσελίδας αυτής. Μέσω της χρήσης
                της εν λόγω Ιστοσελίδας, κάθε χρήστης της μπορεί να υποβάλλει και/ή η COFFEE ISLAND μπορεί να συλλέγει
                περιορισμένες πληροφορίες για οποιονδήποτε σκοπό θεωρεί σκόπιμο για τις εμπορικές της
                δραστηριότητες.<br/><br/>
                5. Τα εμπορικά σήματα, λογότυπα, διακριτικά γνωρίσματα, επωνυμίες και διακριτικοί τίτλοι (εφεξής
                συλλογικά «Εμπορικά Σήματα») που εμφανίζονται στην Ιστοσελίδα, είναι είτε κατατεθειμένα είτε όχι
                Εμπορικά Σήματα της COFFEE ISLAND ή/και τρίτων. Τίποτα από όσα περιέχονται στην Ιστοσελίδα δεν θα πρέπει
                να ερμηνευτεί ως ρητή ή σιωπηρή άδεια ή δικαίωμα χρήσης οποιασδήποτε μορφής οποιουδήποτε Εμπορικού
                Σήματος εμφανίζεται στην Ιστοσελίδα χωρίς την έγγραφη άδεια της COFFEE ISLAND ή των τρίτων που μπορεί να
                κατέχουν τα Εμπορικά Σήματα που εμφανίζονται στην Ιστοσελίδα. Η χρήση, εκ μέρους των χρηστών, των
                Εμπορικών Σημάτων που εμφανίζονται στην Ιστοσελίδα, ή οποιουδήποτε άλλου περιεχομένου αυτής, εκτός των
                όσων προβλέπονται στο παρόν κείμενο των Όρων και Προϋποθέσεων απαγορεύεται ρητώς και αυστηρώς.<br/><br/>
                6. Απεικονίσεις προσώπων ή τόπων ή πραγμάτων που αποτελούν μέρος του περιεχομένου της Ιστοσελίδας είτε
                είναι ιδιοκτησία της COFFEE ISLAND είτε των συνδεδεμένων μ' αυτήν εταιριών είτε χρησιμοποιούνται στην
                Ιστοσελίδα κατόπιν σχετικής άδειας των δικαιούχων τους. Η οποιαδήποτε μορφή χρήσης αυτών των
                απεικονίσεων από τους χρήστες της Ιστοσελίδας εκτός των ορίων της τελευταίας, ή από οποιονδήποτε άλλον
                που εξουσιοδοτείται από τους χρήστες, απαγορεύεται εκτός εάν επιτρέπεται ειδικά, από αυτούς τους
                παρόντες Όρους και Προϋποθέσεις ή παρέχεται ειδική άδεια κάπου αλλού στην Ιστοσελίδα.<br/><br/>
                7. Το λογισμικό και το περιεχόμενο εν γένει της Ιστοσελίδας μπορεί να υπόκειται σε Έλεγχο Εξαγωγών
                σύμφωνα με την ισχύουσα σχετική νομοθεσία των Ηνωμένων Πολιτειών της Αμερικής. Η νομοθεσία περί Ελέγχου
                Εξαγωγών ορισμένων απαγορεύουν την εξαγωγή ορισμένων τεχνικών δεδομένων και λογισμικού σε ορισμένες
                χώρες ή περιοχές ανά των κόσμο. Η COFFEE ISLAND δεν εγκρίνει την λήψη ή την εξαγωγή οποιουδήποτε
                λογισμικού ή τεχνικών δεδομένων από την Ιστοσελίδα σε οποιαδήποτε απαγορευμένη δικαιοδοσία από τη
                νομοθεσία περί Ελέγχου Εξαγωγών των χωρών αυτών.<br/><br/>
                iii. Ευθύνη της COFFEE ISLAND - Υποχρεώσεις των Χρηστών<br/><br/>
                1. Παρά την επισταμένη προσπάθεια της COFFEE ISLAND και των συνδεδεμένων μ' αυτήν εταιρειών, ώστε το
                περιεχόμενο της Ιστοσελίδας να περιλαμβάνει πλήρεις, ακριβείς, έγκυρες και επίκαιρες πληροφορίες, η
                COFFEE ISLAND δεν εγγυάται την ακρίβεια των στοιχείων αυτών. Σε κάθε περίπτωση οι χρήστες της
                Ιστοσελίδας αναγνωρίζουν και αποδέχονται ότι η COFFEE ISLAND ενδέχεται να μην είναι σε θέση να ασκήσει
                έλεγχο του συνόλου του Περιεχομένου και της ασφάλειας της Ιστοσελίδας και των υπηρεσιών της. Για το λόγο
                αυτό, η COFFEE ISLAND ούτε δεσμεύεται, ούτε παρέχει κανενός είδους εγγύηση, ούτε αναλαμβάνει κανενός
                είδους ευθύνη ή υποχρέωση αναφορικά με την ασφάλεια και το περιεχόμενο της Ιστοσελίδας. Επιπλέον, η
                COFFEE ISLAND δεν εγγυάται ότι η χρήση των πληροφοριών, στοιχείων ή υλικών που περιλαμβάνονται στο
                Περιεχόμενο που αναρτάται κάθε φορά στην Ιστοσελίδα δεν θα προσβάλει δικαιώματα τρίτων που δεν ανήκουν ή
                δεν είναι συνδεδεμένοι με την COFFEE ISLAND.<br/><br/>
                2. Οι χρήστες αποδέχονται ότι πρέπει να αξιολογούν το περιεχόμενο της ιστοσελίδας και ότι είναι
                υπεύθυνοι για κάθε κίνδυνο που μπορεί να προκαλέσει η χρήση οποιουδήποτε τμήματος του Περιεχομένου,
                συμπεριλαμβανομένης οποιασδήποτε απόφασής τους να βασιστούν στην ορθότητα, πληρότητα, εγκυρότητα ή/και
                χρησιμότητα οποιουδήποτε τμήματος του Περιεχομένου. Συνεπώς, η χρήση της Ιστοσελίδας από κάθε χρήστη
                γίνεται με δική του αποκλειστικά ευθύνη. Ούτε η COFFEE ISLAND, ούτε οι συνδεδεμένες μ' αυτήν εταιρείες,
                ούτε τα στελέχη τους ή οι διευθυντές τους, οι εργαζόμενοι, οι συνεργάτες τους, οποιοιδήποτε αντιπρόσωποί
                τους, ούτε κανένα άλλο μέρος που εμπλέκεται στην δημιουργία, την παραγωγή ή την παράδοση του
                Περιεχομένου ή εν γένει της λειτουργίας της Ιστοσελίδας ευθύνεται για οποιεσδήποτε άμεσες, επιγενόμενες,
                παρεπόμενες, έμμεσες ή αποθετικές ζημιές προκύψουν από την πρόσβαση κάθε χρήστη στην Ιστοσελίδα, ή την
                χρήση αυτής. Χωρίς περιορισμό των ανωτέρω, όλα όσα περιέχει η Ιστοσελίδα παρέχονται στους χρήστες ως
                έχουν, χωρίς οποιασδήποτε μορφής εγγύηση, ρητή ή σιωπηρή, συμπεριλαμβανομένων ιδίως τυχόν σιωπηρών
                εγγυήσεων εμπορικής εκμετάλλευσης, καταλληλότητες για συγκεκριμένο σκοπό, ή απαραβίαστου. Η COFFEE
                ISLAND και/ή συνδεδεμένες μ' αυτήν εταιρείες σε καμία περίπτωση δεν θα ευθύνονται για ζημίες,
                συμπεριλαμβανομένων, ενδεικτικά, άμεσων ή έμμεσων, ειδικών, τυχαίων ή παρεμπιπτουσών ζημίων, θετικών ή
                αποθετικών, εξόδων που προκύπτουν αναφορικά με αυτήν την Ιστοσελίδα ή τη χρήση της, ή σε αδυναμία χρήσης
                της, από οποιοδήποτε πρόσωπο, ή αναφορικά με αδυναμία εκτέλεσης, λάθος, παράλειψη, διακοπή, ελάττωμα,
                καθυστέρηση λειτουργίας ή εκπομπής ή πτώση γραμμής συστήματος καθώς επίσης ουδεμία ευθύνη αναλαμβάνουν,
                ούτε θα ευθύνονται για οποιαδήποτε ζημία ή βλάβη τυχόν προκληθεί στον ηλεκτρονικό υπολογιστή ή σε άλλο
                ηλεκτρονικό μέσο που χρησιμοποιηθεί από χρήστη για την πρόσβασή του στην Ιστοσελίδα ή για ιούς ή άλλης
                φύσεως λογισμικό, που μπορεί να μολύνουν τον ηλεκτρονικό υπολογιστή ή μέσο ή άλλο περιουσιακό στοιχείο
                του χρήστη το οποίο χρησιμοποιεί για την πρόσβασή του στην Ιστοσελίδα, κατά την επίσκεψη, χρήση ή
                περιήγηση στην Ιστοσελίδα ή για την λήψη οποιουδήποτε υλικού, δεδομένων, κειμένου, εικόνων, βίντεο, ή
                ήχου του Περιεχομένου της Ιστοσελίδας από αυτήν, ακόμη και αν η COFFEE ISLAND ή εκπρόσωποι αυτής έχουν
                ενημερωθεί σχετικά με την πιθανότητα ανάλογων ζημιών, απωλειών ή εξόδων. Οι σύνδεσμοι μ' άλλες πηγές του
                Διαδικτύου λαμβάνουν χώρα με δική τους αποκλειστικά ευθύνη.<br/><br/>
                3. Αν και η COFFEE ISLAND μπορεί, ανά διαστήματα, μη εκ των προτέρων καθορισμένα, να παρακολουθεί ή να
                αναθεωρεί συζητήσεις, συνομιλίες, μηνύματα, αναμεταδόσεις, ηλεκτρονικούς πίνακες ανακοινώσεων και
                οποιοδήποτε άλλο υλικό ή στοιχείο που τυχόν μεταφέρεται ή διακινείται μέσω της Ιστοσελίδας, η COFFEE
                ISLAND δεν έχει καμία υποχρέωση να το πράξει και δεν αναλαμβάνει καμία ευθύνη ή υποχρέωση για αξίωση που
                τυχόν εγερθεί αναφορικά με το Περιεχόμενο της Ιστοσελίδας ούτε για οποιοδήποτε σφάλμα, απλή ή
                συκοφαντική δυσφήμηση, εξύβριση, προσβολή, παράλειψη, ψεύδος, βωμολοχία, πορνογραφία, βλασφημία, κίνδυνο
                ή ανακρίβεια περιέχεται σε οποιαδήποτε πληροφορία εμπεριέχεται στο Περιεχόμενο της Ιστοσελίδας.
                Απαγορεύεται να αποστέλλεται ή να αναμεταδίδεται, ή να μεταφέρεται ή διακινείται οποιοδήποτε παράνομο,
                απειλητικό, συκοφαντικό, δυσφημιστικό, υβριστικό, εμπρηστικό, πορνογραφικό ή βλάσφημο υλικό ή
                οποιοδήποτε υλικό θα μπορούσε να αποτελέσει ή να ενθαρρύνει συμπεριφορά που θα μπορούσε να θεωρηθεί
                ποινικό αδίκημα, να εγείρει αστική ευθύνη, ή να παραβαίνει οποιονδήποτε νόμο καθ' οιονδήποτε άλλον τρόπο
                μέσω της Ιστοσελίδας.<br/><br/>
                4. Η Ιστοσελίδα μπορεί να παραπέμπει μέσω «συνδέσμων», hyperlinks ή διαφημιστικών banners σε άλλες
                ιστοσελίδες, οι πάροχοι των οποίων έχουν και την πλήρη (αστική και ποινική) ευθύνη για την ασφάλεια, το
                σύννομο και την εγκυρότητα του περιεχομένου των ιστοσελίδων και υπηρεσιών τους και τις οποίες η COFFEE
                ISLAND μπορεί να μην τις έχει επιθεωρήσει. Ως εκ τούτου η COFFEE ISLAND δεν φέρει ουδεμία ευθύνη, όπως
                ενδεικτικά ευθύνη περί δικαιωμάτων πνευματικής και βιομηχανικής ιδιοκτησίας ή οποιουδήποτε δικαιώματος
                τρίτου, για το περιεχόμενο ιστοσελίδων ή άλλων ηλεκτρονικών/διαδικτυακών τοποθεσιών που τυχόν συνδέονται
                με την Ιστοσελίδα. Η σύνδεση των χρηστών μέσω της Ιστοσελίδας με άλλες ιστοσελίδες ή
                ηλεκτρονικές/διαδικτυακές τοποθεσίες γίνεται υπ' ευθύνη τους και χωρίς άδεια από την COFFEE ISLAND και
                αυτοί έχουν την υποχρέωση να συμμορφώνονται με τους όρους χρήσης των ιστοσελίδων ή
                ηλεκτρονικών/διαδικτυακών τοποθεσιών αυτών και να απευθύνονται απευθείας στους παρόχους αυτών για
                ο,τιδήποτε προκύψει από την επίσκεψη ή/και χρήση αυτών.<br/><br/>
                5. Οι χρήστες της Ιστοσελίδας αποδέχονται, συμφωνούν και συνομολογούν ότι θα κάνουν σύννομη και πρόσφορη
                χρήση της Ιστοσελίδας, υποκείμενοι εκτός των άλλων και στη νομοθεσία που αφορά τη μετάδοση δεδομένων από
                την Ελλάδα σε Κράτη-Μέλη της Ευρωπαϊκής Ένωσης και τρίτες χώρες.<br/><br/>
                Β. Τήρηση Eχεμύθειας<br/>i . Εισαγωγή<br/><br/>
                Η COFFEE ISLAND και οι συνδεδεμένες μ' αυτήν εταιρείες σέβονται το προσωπικό απόρρητο κάθε χρήστη της
                Ιστοσελίδας. Η παρούσα παράγραφος αναφέρει συνοπτικά τις πληροφορίες που μπορεί να συγκεντρώσει η
                Εταιρεία και τον τρόπο με τον οποίο μπορεί να τις χρησιμοποιήσει καθώς επίσης πληροφορίες σχετικά με τις
                ενέργειες στις οποίες ο χρήστης της Ιστοσελίδας πρέπει να προβεί αν δεν επιθυμεί τη συλλογή ή περαιτέρω
                επεξεργασία των προσωπικών του πληροφοριών κατά την επίσκεψή του στην Ιστοσελίδα.<br/><br/>
                ii. Προσωπικά Δεδομένα<br/><br/>
                1. Η COFFEE ISLAND ή οι συνδεδεμένες με αυτήν εταιρίες δύναται να τηρεί Αρχείο και να επεξεργάζεται
                τυχόν δεδομένα των χρηστών (π.χ., το όνομα, τη διεύθυνση, τον αριθμό τηλεφώνου ή τη διεύθυνση του
                ηλεκτρονικού ταχυδρομείου) (εφεξής «προσωπικά δεδομένα»), τα οποία θα περιέρχονται στη γνώση της, μέσω
                της Ιστοσελίδας και των παρεχομένων μέσω αυτής, μόνο αν τα δεδομένα αυτά παρέχονται οικειοθελώς από τους
                χρήστες. Στην περίπτωση αυτή, η Εταιρεία μπορεί να διαβιβάσει τα Προσωπικά Δεδομένα σε συνδεδεμένες μ'
                αυτήν επιχειρήσεις, εφόσον αυτό επιβάλλεται από λόγους λειτουργικής και μηχανογραφικής εξυπηρέτησης της
                συναλλακτικής σχέσης της με το χρήστη ή για στατιστικούς ή ιστορικούς λόγους. Επίσης αποδέκτες των
                στοιχείων του Αρχείου για την προώθηση, υποστήριξη και εκτέλεση της μεταξύ της Εταιρείας και του χρήστη
                της Ιστοσελίδας συναλλακτικής σχέσης μπορεί να είναι τρίτοι, οι οποίοι συνεργάζονται με την Εταιρεία για
                την παροχή των σχετικών με το Αρχείο υπηρεσιών ή η Εταιρεία μπορεί να (εφόσον δεν λάβει ειδική αντίθετη
                εντολή του χρήστη) ανακοινώνει τα Προσωπικά Δεδομένα των Χρηστών σε τρίτους για τη διαφημιστική προβολή
                των προϊόντων της Εταιρείας ή επιχειρήσεων με τις οποίες η Εταιρεία έχει συνεργασία, την έρευνα αγοράς ή
                άλλη παρόμοια δραστηριότητα, και την προώθηση από απόσταση προϊόντων δικών της ή τρίτων. Αν κάποιος
                χρήστης της Ιστοσελίδας δεν επιθυμεί τη συλλογή και πιθανή τήρηση και επεξεργασία των Προσωπικών
                Δεδομένων του σύμφωνα με τα ανωτέρω τότε πρέπει να αποφεύγει να τα υποβάλλει στην Εταιρεία.<br/><br/>
                2. Ωστόσο, σε περίπτωση που ο χρήστης συναινέσει στην προαναφερόμενη συλλογή, τήρηση και επεξεργασία των
                Προσωπικών του Δεδομένων σύμφωνα με όσα ορίζονται στο παρόν τότε σημειώνεται ρητά ότι σε οποιαδήποτε
                χρονική στιγμή διατηρεί το δικαίωμα ενημέρωσης ή αντίρρησης στην περαιτέρω επεξεργασία των Προσωπικών
                του Δεδομένων βάσει των άρθρων 11 και 13 του Ν. 2472/97 για την προστασία προσωπικών δεδομένων. Για την
                εξάσκηση του δικαιώματός του αυτού ο κάθε χρήστης μπορεί να επικοινωνεί με την Εταιρεία στην ακόλουθη
                ηλεκτρονική διεύθυνση.
            </p>
        </div>
    );
};

export default Terms;