import {useEffect} from "react";
import {useUserData} from "../user/useUserData";
import {useOrderData} from "./useOrderData";
import useOrderAction from "./useOrderAction";
import {isNumber} from "lodash";

export const useOrderBalance = () => {
    const {data} = useUserData()
    const {orderBalance: {totalPurchase, totalRemainder}} = useOrderData()
    const {getOrderBalance} = useOrderAction()

    useEffect(() => {
        getOrderBalance()
    }, [getOrderBalance])

    return {
        totalPurchase: isNumber(totalPurchase) ? totalPurchase.toFixed(2) + data?.currencySymbol : null,
        totalRemainder: isNumber(totalRemainder) ? totalRemainder.toFixed(2) + data?.currencySymbol : null,
        totalRemainderAsNumber: isNumber(totalRemainder) ? totalRemainder.toFixed(2) : null
    }
}