//@flow
import type {Payment} from "./types";

export const parsePayment: Payment = (datum) => {
    if (!datum) return;
    return {
        id: datum.get('id'),
        code: datum.get('code'),
        date: datum.get('date'),
        card: datum.get('card'),
        amount: datum.get('amount'),
    }
}