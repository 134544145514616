//@flow
import React, {useEffect, useRef} from 'react';

type Props = {
    value?: string,
    onChange?: Function,
    onBlur?: Function,
    name?: string,
    id?: string,
    autoFocus?: boolean
};

const TextField = (props: Props) => {
    const ref = useRef(null)
    const {autoFocus, ...otherProps} = props
    
    useEffect(() => {
        if (autoFocus){
            ref.current.focus()
        }
    },[autoFocus])

    return (
        <input
            ref={ref}
            type="text"
            placeholder={'text'}
            {...otherProps}
        />
    );
};

export default TextField;