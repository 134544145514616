//@flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {parseMultipleFromReference} from "../../helpers/parse";
import {parseProduct, sortProducts} from "../../types/product/parse";
import _ from "lodash";

const selectLoading = createSelector(
    (state) => state.product,
    (item) => item.get('loading')
)

const selectById = createSelector(
    [
        (state) => state.productCached.get('byId'),
        (state) => state.currentUser.get('language'),
        (state, id) => id
    ],
    (byId, language, id) => {
        return parseProduct(
            byId.get(id),
            language,
            byId
        )
    }
)

const selectSearchResultsIndexTable = createSelector(
    [
        (state) => state.product.get('searchResultsIndexTable'),
        (state) => state.productCached.get('byId'),
        (state) => state.currentUser.get('language'),
    ],
    (indexTable, byId, language) => ({
        loading: indexTable.get('loading'),
        count: indexTable.get('count'),
        items: sortProducts(parseMultipleFromReference(indexTable.get('items'), byId, (i) => parseProduct(i, language, byId))),
    })
)

const sortBannerProducts = _.memoize((items) => _.orderBy(items, ['popularWeight', 'weight'], ['desc']))

const selectBanner = createSelector(
    [
        (state) => state.productCached.get('banner'),
        (state) => state.productCached.get('byId'),
        (state) => state.currentUser.get('language'),
    ],
    (banner, byId, language) => ({
        loading: banner.get('loading'),
        nearUs: sortBannerProducts(parseMultipleFromReference(banner.get('nearUs'), byId, (i) => parseProduct(i, language, byId))),
        popular: sortBannerProducts(parseMultipleFromReference(banner.get('popular'), byId, (i) => parseProduct(i, language, byId))),
        new: sortBannerProducts(parseMultipleFromReference(banner.get('new'), byId, (i) => parseProduct(i, language, byId))),
        favorites: sortBannerProducts(parseMultipleFromReference(banner.get('favorites'), byId, (i) => parseProduct(i, language, byId))),
        offers: sortBannerProducts(parseMultipleFromReference(banner.get('offers'), byId, (i) => parseProduct(i, language, byId))),
        category: sortBannerProducts(parseMultipleFromReference(banner.get('category'), byId, (i) => parseProduct(i, language, byId))),
    })
)

const useProductData = ({id} = {}) => {
    const byId = useSelector(state => selectById(state, id))
    const loading = useSelector(selectLoading)
    const searchResultsIndexTable = useSelector(selectSearchResultsIndexTable)
    const banner = useSelector(selectBanner)
    return {
        byId,
        loading,
        searchResultsIndexTable,
        banner
    }
}

export default useProductData