//@flow
import * as React from 'react';
import "./title.css"
import classNames from "classnames";

type Props = {
    disabled?: boolean,
    defaultFont?: boolean,
    invertSup?: boolean,
    children?: React.Node,
    sup?: React.Node,
    subtitle?: React.Node,
    className?: string,
    style?: Object,
    variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'body',
    onClick?: Function,
    hideSubtitleOnMobile?: boolean
}

const Title = (props: Props) => {
    const {
        onClick,
        invertSup,
        style,
        sup,
        children,
        variant = 'h2',
        className,
        subtitle,
        defaultFont,
        disabled,
        hideSubtitleOnMobile,
    } = props
    const Component = variant && variant !== 'body' ? variant : 'h2'
    return (
        <Component
            onClick={onClick}
            style={style}
            className={classNames('title', className, {
                ['title--' + variant]: true,
                'title--default': defaultFont,
                'title--disabled': disabled,
                'title--with-action': Boolean(onClick),
                'title--invert-sup': invertSup,
            })}>
            <div className={'title__main'}>{children}</div>
            {sup && <span className={'title__sup'}>{sup}</span>}
            {subtitle && <span className={classNames('title__subtitle', {
                'hide-mobile': hideSubtitleOnMobile
            })}>{subtitle}</span>}
        </Component>
    );
};

export default Title;