import {useDispatch} from "react-redux";
import * as actions from "../../actions/actions";
import {useCallback} from "react";
import {useUserData} from "../user/useUserData";


export const useSalesPersonAction = () => {
    const dispatch = useDispatch()
    const {selectedStore} = useUserData()

    const getSalesPersonUnderlings = useCallback(() => {
        dispatch(actions.getSalesPersonUnderlings({
            SalesPersonId: selectedStore.id,
            omitSameRank: true
        }))
    }, [selectedStore?.id, dispatch])

    return {
        getSalesPersonUnderlings
    }
}
