import React from "react";
import {useTranslation} from "react-i18next";
import {TABLE_COMPONENT} from "../../../../../locales/components/namespaces";

export default function DropdownFilter({
                                           column: {filterValue, setFilter, preFilteredRows, id},
                                       }) {
    const {t} = useTranslation(TABLE_COMPONENT)
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">{t('All')}</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}