import * as React from 'react'
import './checkbox.css'

type Props = {
    label?: string,
    value?: string,
    checked?: boolean,
    onChange?: Function,
    onBlur?: Function,
    name?: string,
    id?: string,
}

const Checkbox = (props: Props) => {
    const {label, checked, onChange, onBlur, id, name} = props
    return (
        <div className={'checkbox'}>
            <input
                type="checkbox"
                name={name}
                id={id}
                checked={checked}
                onChange={onChange}
                onBlur={onBlur}
            />
            <label htmlFor={id}>
                {label}
            </label>
        </div>
    )
}

export default Checkbox