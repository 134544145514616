//@flow
import React from 'react';
import Icon from "../icon/icon";
import Images from "../../assets/images";

const Social = () => {
    return (
        <div>
            <a target={'_blank'} rel={'noopener noreferrer'}
               href="https://www.facebook.com/CoffeeIslandGreece/"><Icon icon={Images.icons.social.fb}/></a>
            <a target={'_blank'} rel={'noopener noreferrer'}
               href="https://www.instagram.com/coffee_island_official"><Icon
                icon={Images.icons.social.instagram}/></a>
            <a target={'_blank'} rel={'noopener noreferrer'} href="https://twitter.com/CoffeeIsland_GR"><Icon
                icon={Images.icons.social.twitter}/></a>
            <a target={'_blank'} rel={'noopener noreferrer'}
               href="https://www.youtube.com/channel/UC1mHUL6bdVkGx56yRkZeYng"><Icon
                icon={Images.icons.social.youtube}/></a>
            <a target={'_blank'} rel={'noopener noreferrer'}
               href="https://gr.linkedin.com/company/coffee-island"><Icon icon={Images.icons.social.linkedIn}/></a>
        </div>
    )
}

export default Social;