import * as ActionTypes from '../../actions/types'
import {
    mapCustomerTabInfo,
    mapReport1Info,
    mapReport2Info,
    mapReport3Info, mapReport4Info, mapReport4OrderProducts,
    mapReport5Info, mapReport6Info, mapReport7Info,
    mapStore,
    mapUser
} from "../../types/user/map";
import {fromJS} from "immutable";
import {mapMultiple} from "../../helpers/map";

const INITIAL_STATE = {
    loading: false,
    loadingStores: false,
    errorLogin: null,
    errorStores: null,
    token: null,
    tokenExpirationDateTime: null,
    data: null,
    customerTabInfo: null,
    isLoggedIn: false,
    storesById: {},
    selectedStore: null,
    language: 'el',
    report1IndexTable: {
        loading: false,
        items: [],
        count: 0
    },
    report2IndexTable: {
        loading: false,
        items: [],
        count: 0
    },
    report3IndexTable: {
        loading: false,
        items: [],
        count: 0
    },
    report4IndexTable: {
        loading: false,
        items: [],
        count: 0
    },
    report4OrderProducts: {
        loading: false,
        items: [],
        count: 0
    },
    report5IndexTable: {
        loading: false,
        items: [],
        count: 0
    },
    report6IndexTable: {
        loading: false,
        items: [],
        count: 0
    },
    report7IndexTable: {
        loading: false,
        items: [],
        count: 0
    },
}

function userLogin(state) {
    return state
        .set('errorLogin', null)
        .set('token', null)
        .set('loading', true)
        .set('selectedStore', null)
        .set('isLoggedIn', false)
}

function userLoginSucceeded(state, action) {
    const {access_token, expires_in} = action.payload
    const tokenExpirationDateTime = new Date()
    tokenExpirationDateTime.setSeconds(tokenExpirationDateTime.getSeconds() + expires_in)
    return state
        .set('storesById', fromJS({}))
        .set('errorLogin', null)
        .set('loading', false)
        .set('token', access_token)
        .set('tokenExpirationDateTime', tokenExpirationDateTime)
        .set('isLoggedIn', true)
}

function userLoginFailed(state, action) {
    const {message} = action.payload
    return state
        .set('errorLogin', message)
        .set('token', null)
        .set('loading', false)
        .set('isLoggedIn', false)
}

function getUserDetails(state) {
    return state
        .set('loading', true)
}

function getUserDetailsSucceeded(state, action) {
    const {user} = action.payload;
    const mappedUser = mapUser(user[0])
    return state
        .set('loading', false)
        .set('language', mappedUser.locale)
        .set('data', fromJS(mappedUser));
}

function getUserDetailsFailed(state) {
    return state
        .set('loading', false);
}

function setUserStore(state, action) {
    return state
        .set('selectedStore', action.payload);
}

function setUserLanguage(state, action) {
    return state
        .set('language', action.payload);
}

function getUserStores(state) {
    return state
        .set('errorStores', null)
        .set('loadingStores', true)
}

function getUserStoresSucceeded(state, action) {
    const {addresses} = action.payload;
    const mappedData = mapMultiple(addresses, mapStore)
    return state
        .set('errorStores', null)
        .set('loadingStores', false)
        .set('storesById', state.get('storesById').merge(fromJS(mappedData)))
}

function getUserStoresFailed(state, action) {
    const {message} = action.payload
    return state
        .set('errorStores', message)
        .set('loadingStores', false);
}


function getCustomerTabInfo(state) {
    return state
        .set('loading', true)
}

function getCustomerTabInfoSucceeded(state, action) {
    return state
        .set('loading', false)
        .set('customerTabInfo', fromJS(mapCustomerTabInfo(action.payload)))
}

function getCustomerTabInfoFailed(state) {
    return state
        .set('loading', false)
}

function getReport1Info(state) {
    return state
        .setIn(['report1IndexTable', 'loading'], true)
}

function getReport1InfoSucceeded(state, action) {
    const {payload} = action
    const mappedData = mapMultiple(payload, mapReport1Info)
    return state
        .setIn(['report1IndexTable', 'count'], parseInt(mappedData.length))
        .setIn(['report1IndexTable', 'items'], fromJS(mappedData))
        .setIn(['report1IndexTable', 'loading'], false)
}

function getReport1InfoFailed(state, action) {
    return state
        .setIn(['report1IndexTable', 'loading'], false)
}

function getReport2Info(state) {
    return state
        .setIn(['report2IndexTable', 'loading'], true)
}

function getReport2InfoSucceeded(state, action) {
    const {payload} = action
    // const mappedData = mapMultiple(payload, mapReport2Info)
    const mappedData = payload.map(item => mapReport2Info(item))
    return state
        .setIn(['report2IndexTable', 'count'], parseInt(mappedData.length))
        .setIn(['report2IndexTable', 'items'], fromJS(mappedData))
        .setIn(['report2IndexTable', 'loading'], false)
}

function getReport2InfoFailed(state, action) {
    return state
        .setIn(['report2IndexTable', 'loading'], false)
}

function getReport3Info(state) {
    return state
        .setIn(['report3IndexTable', 'loading'], true)
}

function getReport3InfoSucceeded(state, action) {
    const {payload} = action
    const mappedData = mapMultiple(payload, mapReport3Info)
    return state
        .setIn(['report3IndexTable', 'count'], parseInt(mappedData.length))
        .setIn(['report3IndexTable', 'items'], fromJS(mappedData))
        .setIn(['report3IndexTable', 'loading'], false)
}

function getReport3InfoFailed(state, action) {
    return state
        .setIn(['report3IndexTable', 'loading'], false)
}

function getReport4Info(state) {
    return state
        .setIn(['report4IndexTable', 'loading'], true)
}

function getReport4InfoSucceeded(state, action) {
    const {orders} = action.payload
    const mappedData = mapMultiple(orders, mapReport4Info)
    return state
        .setIn(['report4IndexTable', 'count'], parseInt(mappedData.length))
        .setIn(['report4IndexTable', 'items'], fromJS(mappedData))
        .setIn(['report4IndexTable', 'loading'], false)
}

function getReport4InfoFailed(state, action) {
    return state
        .setIn(['report4IndexTable', 'loading'], false)
}

function getReport4OrderProducts(state) {
    return state
        .setIn(['report4OrderProducts', 'loading'], true)
}

function getReport4OrderProductsSucceeded(state, action) {
    const {items} = action.payload
    const mappedData = items.map(item => mapReport4OrderProducts(item))
    return state
        .setIn(['report4OrderProducts', 'count'], parseInt(items.length))
        .setIn(['report4OrderProducts', 'items'], fromJS(mappedData))
        .setIn(['report4OrderProducts', 'loading'], false)
}

function getReport4OrderProductsFailed(state, action) {
    return state
        .setIn(['report4OrderProducts', 'loading'], false)
}

function clearReport4OrderProducts(state, action) {
    return state
        .setIn(['report4OrderProducts', 'count'], 0)
        .setIn(['report4OrderProducts', 'items'], [])
        .setIn(['report4OrderProducts', 'loading'], false)
}


function getReport5Info(state) {
    return state
        .setIn(['report5IndexTable', 'loading'], true)
}

function getReport5InfoSucceeded(state, action) {
    const {orders} = action.payload
    const mappedData = orders.map(order => mapReport5Info(order))
    return state
        .setIn(['report5IndexTable', 'count'], parseInt(mappedData.length))
        .setIn(['report5IndexTable', 'items'], fromJS(mappedData))
        .setIn(['report5IndexTable', 'loading'], false)
}

function getReport5InfoFailed(state, action) {
    return state
        .setIn(['report5IndexTable', 'loading'], false)
}


function getReport6Info(state) {
    return state
        .setIn(['report6IndexTable', 'loading'], true)
}

function getReport6InfoSucceeded(state, action) {
    const {orders} = action.payload
    const mappedData = orders.map(order => mapReport6Info(order))
    return state
        .setIn(['report6IndexTable', 'count'], parseInt(mappedData.length))
        .setIn(['report6IndexTable', 'items'], fromJS(mappedData))
        .setIn(['report6IndexTable', 'loading'], false)
}

function getReport6InfoFailed(state, action) {
    return state
        .setIn(['report6IndexTable', 'loading'], false)
}


function getReport7Info(state) {
    return state
        .setIn(['report7IndexTable', 'loading'], true)
}

function getReport7InfoSucceeded(state, action) {
    const {orders} = action.payload
    const mappedData =orders.map(order => mapReport7Info(order))
    return state
        .setIn(['report7IndexTable', 'count'], parseInt(mappedData.length))
        .setIn(['report7IndexTable', 'items'], fromJS(mappedData))
        .setIn(['report7IndexTable', 'loading'], false)
}

function getReport7InfoFailed(state, action) {
    return state
        .setIn(['report7IndexTable', 'loading'], false)
}

const currentUser = {
    initialState: INITIAL_STATE,
    handlers: {
        [ActionTypes.USER_LOGIN]: userLogin,
        [ActionTypes.USER_LOGIN_SUCCEEDED]: userLoginSucceeded,
        [ActionTypes.USER_LOGIN_FAILED]: userLoginFailed,

        [ActionTypes.GET_USER_DETAILS]: getUserDetails,
        [ActionTypes.GET_USER_DETAILS_SUCCEEDED]: getUserDetailsSucceeded,
        [ActionTypes.GET_USER_DETAILS_FAILED]: getUserDetailsFailed,

        [ActionTypes.SET_USER_STORE]: setUserStore,
        [ActionTypes.SET_USER_LANGUAGE]: setUserLanguage,

        [ActionTypes.GET_USER_STORES]: getUserStores,
        [ActionTypes.GET_USER_STORES_SUCCEEDED]: getUserStoresSucceeded,
        [ActionTypes.GET_USER_STORES_FAILED]: getUserStoresFailed,

        [ActionTypes.GET_CUSTOMER_TAB_INFO]: getCustomerTabInfo,
        [ActionTypes.GET_CUSTOMER_TAB_INFO_SUCCEEDED]: getCustomerTabInfoSucceeded,
        [ActionTypes.GET_CUSTOMER_TAB_INFO_FAILED]: getCustomerTabInfoFailed,

        [ActionTypes.GET_REPORT_1_INFO]: getReport1Info,
        [ActionTypes.GET_REPORT_1_INFO_SUCCEEDED]: getReport1InfoSucceeded,
        [ActionTypes.GET_REPORT_1_INFO_FAILED]: getReport1InfoFailed,

        [ActionTypes.GET_REPORT_2_INFO]: getReport2Info,
        [ActionTypes.GET_REPORT_2_INFO_SUCCEEDED]: getReport2InfoSucceeded,
        [ActionTypes.GET_REPORT_2_INFO_FAILED]: getReport2InfoFailed,

        [ActionTypes.GET_REPORT_3_INFO]: getReport3Info,
        [ActionTypes.GET_REPORT_3_INFO_SUCCEEDED]: getReport3InfoSucceeded,
        [ActionTypes.GET_REPORT_3_INFO_FAILED]: getReport3InfoFailed,

        [ActionTypes.GET_REPORT_4_INFO]: getReport4Info,
        [ActionTypes.GET_REPORT_4_INFO_SUCCEEDED]: getReport4InfoSucceeded,
        [ActionTypes.GET_REPORT_4_INFO_FAILED]: getReport4InfoFailed,

        [ActionTypes.GET_REPORT_4_ORDER_PRODUCTS]: getReport4OrderProducts,
        [ActionTypes.GET_REPORT_4_ORDER_PRODUCTS_SUCCEEDED]: getReport4OrderProductsSucceeded,
        [ActionTypes.GET_REPORT_4_ORDER_PRODUCTS_FAILED]: getReport4OrderProductsFailed,
        [ActionTypes.CLEAR_REPORT_4_ORDER_PRODUCTS]: clearReport4OrderProducts,

        [ActionTypes.GET_REPORT_5_INFO]: getReport5Info,
        [ActionTypes.GET_REPORT_5_INFO_SUCCEEDED]: getReport5InfoSucceeded,
        [ActionTypes.GET_REPORT_5_INFO_FAILED]: getReport5InfoFailed,

        [ActionTypes.GET_REPORT_6_INFO]: getReport6Info,
        [ActionTypes.GET_REPORT_6_INFO_SUCCEEDED]: getReport6InfoSucceeded,
        [ActionTypes.GET_REPORT_6_INFO_FAILED]: getReport6InfoFailed,

        [ActionTypes.GET_REPORT_7_INFO]: getReport7Info,
        [ActionTypes.GET_REPORT_7_INFO_SUCCEEDED]: getReport7InfoSucceeded,
        [ActionTypes.GET_REPORT_7_INFO_FAILED]: getReport7InfoFailed,
    }
}

export default currentUser