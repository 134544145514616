import { mapLabel } from "../product/map";
import type {
	ProductOfInterest,
	UnmappedProductOfInterest,
} from "./types";
import { API } from "../../helpers/constants";
import { mapMultiple } from "../../helpers/map";

export const mapProductOfInterest: ProductOfInterest = (datum: UnmappedProductOfInterest) => {
	if (!datum) return
	const packaging = datum.ItemPackaging ? datum.ItemPackaging.split(' ') : null
	const packagingEn = datum.ItemPackagingEn ? datum.ItemPackagingEn.split(' ') : null
	const images = datum.Images ? datum.Images
	.split(',')
	.map((l, i) => {
		const splittedImage = l.split('#')
		return {
			original: API + '/uploads/itemImgs/' + splittedImage[0],
			thumbnail: API + '/uploads/itemImgs/' + splittedImage[0],
			isMainImage: splittedImage[1] === "1"
		}
	}) : []
	const thumbnail = images.filter(f => f.isMainImage)
	return {
		labels: datum.LabelData ?
			mapMultiple(
				datum.LabelData
				.split(',')
				.map((l, i) => {
					const splittedLabel = l.split('#')
					return {
						LabelId: splittedLabel[0] + i,
						ItemLabelDescription: splittedLabel[0],
						ItemLabelDescriptionEn: splittedLabel[1],
						LabelBackgroundColor: splittedLabel[2],
						LabelDisplayOrder: i,
						LabelFontColor: splittedLabel[3],
					}
				}), mapLabel) : [],
		thumbnail: thumbnail.length > 0 ? thumbnail[0]?.thumbnail : '',
		weight: Number(datum.ItemPriorityNum),
		description: {
			en: datum.ItemSummaryEn,
			el: datum.ItemSummaryEl,
		},
		message: {
			en: datum.ItemWebDescriptionEn,
			el: datum.ItemWebDescription,
		},
		multiplier: packaging && packaging.length > 0 ? Number(packaging[0]) : 1,
		id: datum.id,
		unitOfMeasurement: {
			el: datum.unitOfMeasurement,
			en: datum.unitOfMeasurementEn
		},
		basicUnitOfMeasurement: {
			el: packaging && packaging.length > 0 ? packaging.slice(1).join(' ') : datum.unitOfMeasurement,
			en: packagingEn && packagingEn.length > 0 ? packagingEn.slice(1).join(' ') : datum.unitOfMeasurementEn
		},
		title: {
			el: datum.ItemDescription,
			en: datum.ItemDescriptionEn,
		},
		images: images,
		quantity: Number(datum.ucQuantity),
		comment: datum.ucComments ? datum.ucComments : ''
	}
}