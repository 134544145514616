//@flow
import {useEffect, useState} from 'react';
import useProductAction from "./useProductAction";
import {useDebounce} from "use-debounce";

const useFavoriteProduct = ({id, isFavorite}) => {
    const [favorite, setFavorite] = useState(false)
    const [debouncedFavorite] = useDebounce(favorite, 1000);
    const [favoriteChangedByUser, setFavoriteChangedByUser] = useState(false)

    const {favoriteProduct} = useProductAction()

    useEffect(() => {
        setFavorite(isFavorite)
    }, [isFavorite])

    useEffect(() => {
        if (favoriteChangedByUser) {
            favoriteProduct(id, debouncedFavorite)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedFavorite])

    const toggleFavorite = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setFavorite(!favorite)
        setFavoriteChangedByUser(true)
    }
    return {
        favorite,
        toggleFavorite,
    }
}

export default useFavoriteProduct;