//@flow
import React, {Fragment, useEffect, useState} from 'react';
import {useUserData} from "../../hooks/user/useUserData";
import Button from "../button/button";
import useUserAction from "../../hooks/user/useUserAction";
import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../locales/pages/namespaces";
import "./selectStore.css"

type Props = {
    requireConfirmation?: boolean,
    updateAcrossApplication?: boolean,
    showAllOption?: boolean,
    onSelection?: Function,
    selected?: string,
}

const SelectStore = (props: Props) => {
    const {t} = useTranslation(PAGE_LOGIN)
    const {showAllOption, requireConfirmation, updateAcrossApplication = true} = props
    const [selectedStore, setSelectedStore] = useState(props.selected);
    const {sortedStores: stores, selectedStore: userSelectedStore} = useUserData()
    const {setUserStore} = useUserAction()

    useEffect(() => {
        if (props.selected && props.selected !== selectedStore) {
            setSelectedStore(props.selected)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selected])

    useEffect(() => {
        if (userSelectedStore && updateAcrossApplication) {
            setSelectedStore(userSelectedStore.id)
        }
    }, [updateAcrossApplication, userSelectedStore])

    useEffect(() => {
        if (requireConfirmation && updateAcrossApplication) {
            if (stores && stores.length === 1) {
                setUserStore(stores[0].id)
            } else if (stores && stores.length > 0) {
                setSelectedStore(stores[0].id)
            }
        }
    }, [requireConfirmation, setSelectedStore, setUserStore, updateAcrossApplication])

    const handleChange = (e) => {
        const storeId = e.target.value
        setSelectedStore(storeId)
        if (!requireConfirmation && updateAcrossApplication) {
            setUserStore(storeId)
        }
        if (props.onSelection) {
            props.onSelection(
                storeId === 'all' ? storeId :
                    stores.find(s => s.id === storeId)
            )
        }
    }

    return (
        <Fragment>
            <select
                className={'select-store'}
                value={selectedStore}
                onChange={handleChange}
            >
                {showAllOption && stores.length > 1 && (
                    <option value={'all'}>
                        {t('all')}
                    </option>
                )}
                {stores.map(store => (
                    <option key={store.id} value={store.id}>
                        {!store.isAdmin ? store.address : `${store.customerErpCode}-${store.erpCode} / ${store.address} / ${store.countryCode} / ${store.ciIdCode}`.toUpperCase()}
                    </option>
                ))}
            </select>
            {requireConfirmation && (
                <Button.Group spacingTop={1}>
                    <Button onClick={() => setUserStore(selectedStore)}>
                        {t('login')}
                    </Button>
                </Button.Group>
            )}
        </Fragment>
    )
}

export default SelectStore;