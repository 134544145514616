//@flow
import type {CurrentPayment, Payment, UnmappedCurrentPayment, UnmappedPayment} from "./types";

export const mapPayment: Payment = (datum: UnmappedPayment) => {
    if (!datum) return
    return {
        id: datum.PaymentId,
        code: datum.Code,
        date: new Date(datum.CreatedAt),
        card: datum.CardNumber,
        amount: Number(datum.Amount)
    }
}

export const mapCurrentPayment: CurrentPayment = (datum: UnmappedCurrentPayment) => {
    if(!datum) return
    return {
        paymentId: datum.PaymentId,
        status: datum.Status,
        transactionTicket: datum.TransactionTicket,
        resultCode: datum.ResultCode,
        resultDescription: datum.ResultDescription,
        username: datum.Username,
        posId: datum.PosId,
        acquirerId: datum.AcquirerId,
        merchantId: datum.MerchantId,
        merchantReference: datum.MerchantReference,
        addressId: datum.AddressId,
        minutesToExpiration: datum.MinutesToExpiration,
        timestamp: datum.Timestamp
    }
}