//@flow
import * as React from 'react';
import {Fragment, useEffect, useState} from 'react';
import Title from "../title/title";
import ImageGallery from "react-image-gallery";
import Accordion from "../accordion/accordion";
import AvailabilityStatus from "../availabilityStatus/availabilityStatus";
import type {Product as ProductProps} from "../../types/product/types";
import useProductAction from "../../hooks/product/useProductAction";
import Preview from "../preview/preview";
import Divider from "../divider/divider";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {PRODUCT_COMPONENT} from "../../locales/components/namespaces";
import './product.css'
import useProductData from "../../hooks/product/useProductData";
import {useUserData} from "../../hooks/user/useUserData";

type Props = {
    product: ProductProps,
    onSubmit: Function,
    onClose: Function,
    orderType?: number
}

const Product = (props: Props) => {
    const {t} = useTranslation(PRODUCT_COMPONENT)
    const [showDescription, setShowDescription] = useState(true)
    const {getProduct} = useProductAction()

    const {
        onAdd,
        product: {
            id
        }
    } = props

    const {loading, byId: detailedProduct} = useProductData({id})

    const {
        orderType,
        title,
        code,
        basicUnitOfMeasurement,
        unitOfMeasurement,
        multiplier,
        availabilityStatus,
        retailPrice,
        description,
        images,
        similar
    } = Boolean(detailedProduct) ? detailedProduct : props.product

    useEffect(() => {
        getProduct(id)
    }, [getProduct, id])
    const {data} = useUserData()
    const hasImages = images && images.length > 0
    return (
        <Fragment>
            <div className={'add-to-card'}>
                <div>
                    <Title defaultFont variant={"h3"}>
                        {code} - {title}
                    </Title>
                    <div className={'product__top'}>
                        <span>{t('Package')}: 1 {unitOfMeasurement}</span>
                        <span>|</span>
                        <span>{t('Quantity')}: {multiplier} {basicUnitOfMeasurement}</span>
                        <span>|</span>
                        <span>{t('Retail Price')}: <strong>{parseInt(retailPrice) === 0 ? '-' : `${retailPrice}${data?.currencySymbol}/${t('Package')}`}</strong></span>
                        <span>|</span>
                        <AvailabilityStatus status={availabilityStatus}/>
                    </div>
                </div>
                {/*<br/>*/}
                <Preview
                    displayAsTile
                    product={props.product}
                    onAdd={props.onAdd}
                    orderType={orderType}
                />
            </div>
            <div className={'product'}>
                {loading && (
                    <div>
                        {t('loading')}...
                    </div>
                )}
                <div className={classNames('product__details', {
                    'product__details--no-background': !hasImages
                })}>
                    {/*<img src={Images.product.background_2} alt={'background 2'}/>*/}
                    {/*<img src={Images.product.background_1} alt={'background 1'}/>*/}
                    <div>
                        {hasImages && (
                            <ImageGallery
                                autoPlay={false}
                                showPlayButton={false}
                                showNav={false}
                                thumbnailPosition={'right'}
                                items={images}
                            />
                        )}
                        {/*{description && (*/}
                        {/*    <div className={'product__description'} style={{marginTop: '1rem'}}>*/}
                        {/*        <Accordion*/}
                        {/*            isActive={showDescription}*/}
                        {/*            onExpandedToggle={() => setShowDescription(!showDescription)}*/}
                        {/*            title={*/}
                        {/*                <Title variant={"h4"}>*/}
                        {/*                    {t('description')}*/}
                        {/*                </Title>*/}
                        {/*            }*/}
                        {/*        >*/}
                        {/*            <div dangerouslySetInnerHTML={{__html: description}}/>*/}
                        {/*        </Accordion>*/}
                        {/*        <Divider thin spacingBottom={2}/>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                    </div>
                </div>
                {similar && similar.length > 0 && (
                    <div className={'product__similar'}>
                        <Title variant={'h3'}>
                            {t('similar')} {t('products').toLowerCase()}
                        </Title>
                        {similar.map((product) => (
                            <Preview
                                key={product.id}
                                product={product}
                                onAdd={onAdd}
                                orderType={orderType}

                            />
                        ))}
                        <br/>
                    </div>
                )}
            </div>
        </Fragment>
    )
}

export default Product;