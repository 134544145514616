//@flow
import React, {useState} from 'react';
import Util from "../../components/util/util";
import Title from "../../components/title/title";
import Divider from "../../components/divider/divider";
import Table from "../../components/table/table";
import {useTranslation} from "react-i18next";
import Button from "../../components/button/button";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_ONLINE_PAYMENTS} from "../../routers/routes";
import Icon from "../../components/icon/icon";
import Images from "../../assets/images";
import usePaymentAction from "../../hooks/payment/usePaymentAction";
import usePaymentData from "../../hooks/payment/usePaymentData";
import {PAGE_PAYMENTS} from "../../locales/pages/namespaces";
import {formatDate, formatTypes} from "../../helpers/date";
import "./onlinePaymentsHistory.css"
import RangeSelect from "../../components/rangeSelect/rangeSelect";
import {useUserData} from "../../hooks/user/useUserData";
import {withPermissionHOC} from "../../modules/permission/components/permissionHOC/permissionHOC";
import {Permissions} from "../../helpers/constants";

const OnlinePaymentsHistory = () => {
    const {t} = useTranslation(PAGE_PAYMENTS)
    const [range, setRange] = useState([new Date(), new Date()])
    const {getPayments} = usePaymentAction(range)
    const {indexTable} = usePaymentData()
    const {data} = useUserData()

    const onSubmit = (values) => {
        const {range} = values
        setRange(range)
    }

    return (
        <div className={'bg-container contained-page'}>
            <Button variant={'link'} component={Link} to={getRouteUrl(ROUTE_PAGE_ONLINE_PAYMENTS)}>
                <Icon icon={Images.icons.arrowLeft}/> {t('back')}
            </Button>
            <Util>
                <Title variant={"h2"}>
                    {t('payment_history_title')}
                </Title>
            </Util>
            <Divider/>
            <p>
                {t('message')}
            </p>
            <RangeSelect
                initialRange={range}
                onSubmit={onSubmit}
            />
            <br/>
            <Table
                data={indexTable}
                dataManager={getPayments}
                noMeta
                noHeader
                columns={[
                    {
                        accessor: 'code',
                        Cell: ({value}) => (
                            <div>
                                <strong>{t('payment')}</strong> #{value}
                            </div>
                        )
                    },
                    {
                        accessor: 'date',
                        Cell: ({value}) => <span>{formatDate(value, formatTypes.DATE_ONLY)}</span>
                    },
                    {
                        accessor: 'card',
                        Cell: ({value}) => (
                            <div>
                                <strong>{t('card')}</strong> {value}
                            </div>
                        )
                    },
                    {
                        accessor: 'amount',
                        Cell: ({value}) => (
                            <div>
                                {value}{data?.currencySymbol}
                            </div>
                        )
                    },
                ]}
            />
        </div>
    )
}

export default withPermissionHOC(OnlinePaymentsHistory,[Permissions.AccessPayments])