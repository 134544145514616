// @flow
import * as React from 'react'
import {useMemo, useState} from 'react'
import './table.css'
import DefaultView from './partials/views/defaultView'
import AxiosTable from './partials/axiosTable'
import ReduxTable from './partials/reduxTable'
import Main from "./partials/views/main";

const DEFAULT_PAGE_SIZE = 200
const DEFAULT_PAGE_INDEX = 0

type Column = {
    Header: string,
    accessor: string,
    Cell: Function,
    onRowClick?: Function,
}

type Props = {
    noHeader?: boolean,
    noMeta?: boolean,
    noPagination?: boolean,
    showIndex?: boolean,
    renderRow?: Function,
    dataManager: Function,
    data?: {//only for redux table
        loading: boolean,
        items: Array<Object>,
        count: number,
    },
    pageSize?: number,
    pageIndex?: number,
    onRowClick?: Function,
    usingAxiosResponse?: boolean,
    getRowProps?: Function,
    columns: Array<Column & {
        columns?: Column
    }>,
    view?: React.Node,
}

const Table = (props: Props) => {
    const {
        virtualized,
        usingAxiosResponse,
        dataManager,
        onRowClick,
        showIndex,
        noPagination,
        noMeta,
        noHeader,
        getRowProps,
        view,
        renderRow,
        customStyle,
    } = props

    const [pageSize, setPageSize] = useState(props.pageSize ? props.pageSize : DEFAULT_PAGE_SIZE)
    const pageIndex = props.pageIndex ? props.pageIndex : DEFAULT_PAGE_INDEX

    const columns = useMemo(() => props.columns, [props.columns])
    const groupBy = useMemo(() => props.groupBy ? props.groupBy : [], [props.groupBy])
    const expanded = useMemo(() => props.expanded ? props.expanded : {}, [props.expanded])
    const sortBy = useMemo(() => props.sortBy ? props.sortBy : [], [props.sortBy])

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pageCount, setPageCount] = useState(0)

    const WrapperComponent = usingAxiosResponse ? AxiosTable : ReduxTable
    const ViewComponent = view ? view : DefaultView

    return (
        <WrapperComponent
            pageSize={pageSize}
            sortBy={sortBy}
            data={props.data}
            dataManager={dataManager}
            setData={setData}
            setLoading={setLoading}
            setPageCount={setPageCount}
        >
            {fetchData => (
                <Main
                    virtualized={virtualized}
                    customStyle={customStyle}
                    viewComponent={ViewComponent}
                    initialState={{
                        pageSize,
                        pageIndex,
                        groupBy,
                        expanded,
                        sortBy
                    }}
                    renderRow={renderRow}
                    onPageSizeChange={setPageSize}
                    columns={columns}
                    data={data}
                    fetchData={fetchData}
                    loading={loading}
                    pageCount={pageCount}
                    onRowClick={onRowClick}
                    showIndex={showIndex}
                    noPagination={noPagination}
                    noMeta={noMeta}
                    noHeader={noHeader}
                    getRowProps={getRowProps}
                />
            )}
        </WrapperComponent>
    )
}

export default Table