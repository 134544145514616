//@flow
import React from 'react';
import './divider.css'
import classNames from "classnames";

type Props = {
    thin?: boolean,
    spacingTop?: number,
    spacingBottom?: number,
};

const Divider = (props: Props) => {
    const {thin, spacingTop, spacingBottom} = props;
    return (
        <hr className={
            classNames('divider', {
                'divider--thin': thin,
                ['divider--spacing-top-' + spacingTop]: spacingTop,
                ['divider--spacing-below-' + spacingBottom]: spacingBottom,
            })
        }/>
    );
};

export default Divider;