//@flow
import * as React from 'react';
import Modal from "../modal/modal";
import {useTranslation} from "react-i18next";
import Button from "../button/button";
import {DELETE_CONFIRMATION} from "../../locales/components/namespaces";

type Props = {
    children: React.Node,
    show: boolean,
    onClose: Function,
    onSubmit: Function,
};

const DeleteConfirmation = (props: Props) => {
    const {t} = useTranslation(DELETE_CONFIRMATION)
    const {children, show, onClose, onSubmit} = props
    return (
        <Modal show={show} onClose={onClose}>
            <p>
                {children}
            </p>
            <Button.Group spacingTop={2}>
                <Button onClick={onSubmit}>
                    {t('delete')}
                </Button>
                <Button variant={'link'} onClick={onClose}>
                    {t('cancel')}
                </Button>
            </Button.Group>
        </Modal>
    );
};

export default DeleteConfirmation;