//@flow
import React, {useCallback, useState} from 'react';
import {useUserData} from "../../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import useUserAction from "../../../hooks/user/useUserAction";
import Title from "../../title/title";
import Divider from "../../divider/divider";
import RangeSelect from "../../rangeSelect/rangeSelect";
import moment from "moment";
import {PAGE_REPORTS} from "../../../locales/pages/namespaces";
import Util from "../../util/util";
import Table from "../../table/table";
import './report6.css'
import {withPermissionHOC} from "../../../modules/permission/components/permissionHOC/permissionHOC";
import {API, Permissions} from "../../../helpers/constants";
import DropdownFilter from "../../table/partials/filters/dropdownFilter/dropdownFilter";
import TextFilter from "../../table/partials/filters/textFilter/textFilter";
import NumberRangeFilter from "../../table/partials/filters/numberRangeFilter/numberRangeFilter";
import {formatDate, formatTypes} from "../../../helpers/date";
import useSalesPersons from "../../../hooks/salesPersons/useSalesPersons";
import SelectSalesPerson from "../../selectSalesPerson/selectSalesPerson";
import Button from "../../button/button";
import {AuthenticatedLink} from "../../authenticatedLink/authenticatedLink";
import Icon from "../../icon/icon";
import Images from "../../../assets/images";

const NumberCell = ({value}) => (
    <div style={{textAlign: "right"}}>
        {value.toFixed(2)}
    </div>
)
const Report6 = () => {
    const {t} = useTranslation(PAGE_REPORTS)
    const {data} = useUserData()
    const {report6IndexTable} = useUserData()
    const {getReport6Info} = useUserAction()
    const {salesPersons} = useSalesPersons()
    const [selectedSalesPerson, setSelectedSalesPerson] = useState(data.id)

    const [range, setRange] = useState([
        moment().subtract(3, 'months').toDate(), new Date()
    ])
    const onSubmit = (values) => {
        const {range} = values
        setRange(range)
    }
    const salesPersonsIds = selectedSalesPerson === 'all' ? salesPersons.map(salesPerson => salesPerson.id).toString() : selectedSalesPerson
    const dataManager = useCallback((pageIndex, pageSize, sortBy) => {
        getReport6Info(pageIndex, pageSize, range, salesPersonsIds, sortBy)
    }, [getReport6Info, range, salesPersonsIds])

    return (
        <div className={'report1-page contained-page bg-container'}>
            <Util>
                <Title variant={"h3"}>
                    {t('SALES PERSON ORDERS - TOTAL AMOUNTS PER STATUS')}
                </Title>
            </Util>
            <Divider/>
            <RangeSelect
                initialRange={range}
                onSubmit={onSubmit}
            />
            <div className={'report1-page-toolbar'}>
                <SelectSalesPerson
                    value={selectedSalesPerson}
                    onChange={(evt) => setSelectedSalesPerson(evt.target.value)}
                    salesPersons={salesPersons}
                />
                <Button.Group size={'xs'}>
                    <Button
                        url={`${API}/en/b2b/report/salesperson/totals/orders?fromDate=${formatDate(range[0], formatTypes.DATE_ONLY_FILTER)}&toDate=${formatDate(range[1], formatTypes.DATE_ONLY_FILTER)}&salesPersonsIds=${salesPersonsIds}&isExport=1`}
                        size={'sm'}
                        filename={'reportExport'}
                        variant={'link'}
                        component={AuthenticatedLink}
                    >
                        <Util>
                            <Icon icon={Images.icons.paperclip}/>
                            {t('Export Report')}
                        </Util>
                    </Button>
                </Button.Group>
            </div>
            <br/>
            <div className="table-wrapper">
                <Table
                    data={report6IndexTable}
                    noPagination
                    dataManager={dataManager}
                    noMeta
                    customStyle
                    groupBy={['salesPersonName']}
                    sortBy={[{id: 'customer', desc: false}]}
                    expanded={report6IndexTable
                        .items
                        .reduce((p, n) => ({
                            ...p,
                            ['salesPersonName:' + n.salesPersonName]: true
                        }), {})
                    }
                    columns={[
                        {
                            Header: t('Sales Person'),
                            accessor: 'salesPersonName',
                            Filter: TextFilter,
                            filter: 'includes',
                        },
                        {
                            Header: t('customer'),
                            accessor: 'customer',
                            Filter: TextFilter,
                            filter: 'includes',
                        },
                        {
                            Header: t('Address Erp Code'),
                            accessor: 'addressErpCode',
                            Filter: TextFilter,
                            filter: 'includes',
                        },
                        {
                            Header: t('address'),
                            accessor: 'address',
                            Filter: TextFilter,
                            filter: 'includes',
                        },
                        {
                            Header: t('Country Code'),
                            accessor: 'countryCode',
                            Filter: TextFilter,
                            filter: 'includes',
                        },
                        {
                            Header: t('ciIdCode'),
                            accessor: 'ciIdCode',
                            Filter: TextFilter,
                            filter: 'includes',
                        },
                        // {
                        //     Header: t('orderSheet'),
                        //     accessor: 'orderSheet.description',
                        //     Filter: DropdownFilter,
                        //     filter: 'includes',
                        // },
                        {
                            Header: t('Waiting Orders Total'),
                            accessor: 'waitingOrders',
                            aggregate: 'sum',
                            Filter: NumberRangeFilter,
                            filter: 'between',
                            Cell: NumberCell
                        },
                        {
                            Header: t('Completed Orders Total'),
                            accessor: 'completedOrders',
                            aggregate: 'sum',
                            Filter: NumberRangeFilter,
                            filter: 'between',
                            Cell: NumberCell
                        },
                        {
                            Header: t('All Orders Total'),
                            accessor: 'allOrders',
                            aggregate: 'sum',
                            Filter: NumberRangeFilter,
                            filter: 'between',
                            Cell: NumberCell
                        },
                        {
                            Header: t('Waiting Orders Total (VAT)'),
                            accessor: 'waitingOrdersVat',
                            aggregate: 'sum',
                            Filter: NumberRangeFilter,
                            filter: 'between',
                            Cell: NumberCell
                        },
                        {
                            Header: t('Completed Orders Total (VAT)'),
                            accessor: 'completedOrdersVat',
                            aggregate: 'sum',
                            Filter: NumberRangeFilter,
                            filter: 'between',
                            Cell: NumberCell
                        },
                        {
                            Header: t('All Orders Total (VAT)'),
                            accessor: 'allOrdersVat',
                            aggregate: 'sum',
                            Filter: NumberRangeFilter,
                            filter: 'between',
                            Cell: NumberCell
                        },

                    ]}
                />
            </div>
        </div>
    )
}

export default withPermissionHOC(Report6, [Permissions.AccessSalesPerson])
