//@flow
import * as React from 'react';
import classNames from "classnames";
import Icon from "../icon/icon";
import Images from "../../assets/images";
import './accordion.css'

type Props = {
    title: React.Node,
    isActive: boolean,
    onExpandedToggle?: Function,
    children?: React.Node
}

const Accordion = (props: Props) => {
    const {
        title,
        isActive,
        onExpandedToggle,
        children
    } = props

    return (
        <div className={
            classNames('accordion', {
                'accordion--active': isActive,
                'accordion--inactive': !isActive
            })
        }>
            <div
                className={classNames('accordion__summary', {
                    "accordion__summary--no-action": !onExpandedToggle
                })}
                onClick={(evt) => onExpandedToggle && onExpandedToggle(evt, title)}
            >
                <div className={'accordion__summary-title'}>
                    {title}
                </div>
                {onExpandedToggle && (
                    <div className={'accordion__summary-icon'}>
                        {isActive ?
                            <Icon icon={Images.icons.arrowUp}/> :
                            <Icon icon={Images.icons.arrowDown}/>
                        }
                    </div>
                )}
            </div>
            <div className={'accordion__details'}>
                {children}
            </div>
        </div>
    )
}

export default Accordion