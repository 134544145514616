import {useEffect, useState} from "react";
import useQueryParams from "./useQueryParams";

function useSearchQuery() {
    const queryParams = useQueryParams();
    const queryFromUrl = queryParams.get('query')
    const [query, setQuery] = useState('')

    useEffect(() => {
        if (queryFromUrl) {
            setQuery(queryFromUrl)
        }
    }, [queryFromUrl])

    return {query, setQuery}
}

export default useSearchQuery