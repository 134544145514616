//@flow
import React from 'react';
import Title from "../../../title/title";
import "./billingAndShippingInfo.css"

type Info = {
    id: string,
    fullName: string,
    address: string,
    city: string,
    postalCode: string,
    countryCode: string
}

type Props = {
    billing: Info,
    shipping: Info,
};

const BillingAndShippingInfo = (props: Props) => {
    const {billing, shipping} = props;
    return (
        <div className={"billing-info"}>
            <div>
                <Title variant={"h4"}>
                    YOUR BILLING DETAILS
                </Title>
                <div>
                    <div>{billing.fullName}</div>
                    <div>{billing.address}</div>
                    <div>{billing.city}</div>
                    <div>{billing.postalCode}</div>
                    <div>{billing.countryCode}</div>
                </div>
            </div>
            <div>
                <Title variant={"h4"}>
                    YOUR SHIPPING DETAILS
                </Title>
                <div>
                    <div>{shipping.fullName}</div>
                    <div>{shipping.address}</div>
                    <div>{shipping.city}</div>
                    <div>{shipping.postalCode}</div>
                    <div>{shipping.countryCode}</div>
                </div>
            </div>
        </div>
    );
};

export default BillingAndShippingInfo;