//@flow
import * as React from 'react';
import './formGroup.css'

type Props = {
    id: string,
    label: string,
    children: React.Node
};

const FormGroup = (props: Props) => {
    const {children, id, label} = props;
    return (
        <div className="formGroup">
            {children}
            <label htmlFor={id} className="formGroup__label">
                {label}
            </label>
        </div>
    );
};

export default FormGroup;