//@flow
import React from 'react';

type Props = {
    value?: string,
    onChange?: Function,
    onBlur?: Function,
    name?: string,
    id?: string,
    rows?: number,
    cols?: number,
};

const Textarea = (props: Props) => {
    return (
        <textarea
            {...props}
        />
    );
};

export default Textarea;