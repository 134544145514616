//@flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import Loading from "../../components/loading/loading";
import Modal from "../../components/modal/modal";
import history from "../../store/history";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_ONLINE_PAYMENTS} from "../../routers/routes";
import {useTranslation} from "react-i18next";
import {PAYMENT_CONFIRMATION} from "../../locales/components/namespaces";
import axios from "axios";
import {API} from "../../helpers/constants";
import {useUserData} from "../../hooks/user/useUserData";
import useQueryParams from "../../hooks/useQueryParams";

const OnlinePaymentsCancel = () => {
    const [loading, setLoading] = useState(false)
    const {t} = useTranslation(PAYMENT_CONFIRMATION)
    const [open, setOpen] = useState(false)
    const {language, token} = useUserData()
    const queryParams = useQueryParams()
    useEffect(() => {
        setOpen(true)
        axios({
            method: 'post',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: API + `/${language}/b2b/payments/cancel`,
            params: queryParams
        }).then((response) => {
            setTimeout(() => history.push(getRouteUrl(ROUTE_PAGE_ONLINE_PAYMENTS)), 4000)
        }).catch(error => {
            setLoading(false)
            setTimeout(() => history.push(getRouteUrl(ROUTE_PAGE_ONLINE_PAYMENTS)), 4000)
        })
    }, [])

    return (
        <div className={'bg-container'}>
            <Loading show={loading}/>
            <Modal show={open} onClose={() => setOpen(false)}>
                <div className={'error-message'} style={{
                    padding: '0.5rem 1rem',
                    border: '1px solid #FF0000',
                    margin: '0.5rem 0',
                    color: '#FF0000',
                    fontWeight: '700',
                    fontSize: '16px'
                }}>
                    {t('Payment Canceled')}
                </div>
            </Modal>
        </div>
    );
};

export default OnlinePaymentsCancel;