import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { parseMultiple } from "../../helpers/parse";
import { sortProducts } from "../../types/product/parse";
import { parseProductOfInterest } from "../../types/productsOfInterest/parse";
import { parseUserMessage } from "../../types/user/parse";

const selectMessage = createSelector(
	(state) => state.productsOfInterest.get('message'),
	(item) => parseUserMessage(item)
)

const selectLoading = createSelector(
	(state) => state.productsOfInterest,
	(item) => item.get('loading')
)

const selectProductsOfInterest = createSelector(
	[
		(state) => state.productsOfInterest.get('productsOfInterest'),
		(state) => state.currentUser.get('language'),
	],
	(indexTable, language) => ({
		loading: indexTable.get('loading'),
		count: indexTable.get('count'),
		items: sortProducts(parseMultiple(indexTable.get('items'), (i) => parseProductOfInterest(i, language))),
	})
)

const useProductsOfInterestData = () => {
	const loading = useSelector(selectLoading)
	const message = useSelector(selectMessage)
	const productsOfInterest = useSelector(selectProductsOfInterest)
	return {
		loading,
		message,
		productsOfInterest
	}
}

export default useProductsOfInterestData