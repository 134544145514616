//@flow
import React from 'react';
import Button from "../../../button/button";
import {useTranslation} from "react-i18next";
import {TABLE_COMPONENT} from "../../../../locales/components/namespaces";

type Props = {
    pageIndex: number,
    pageSize: number,
    setPageSize: Function,
    previousPage: Function,
    nextPage: Function,
    canPreviousPage: boolean,
    canNextPage: boolean,
}

const Pagination = (props: Props) => {
    const {t} = useTranslation(TABLE_COMPONENT)
    const {pageSize, setPageSize, canPreviousPage, canNextPage, nextPage, previousPage, pageIndex} = props
    return (
        <div className="pagination">
            <select
                className={'form-control form-control-sm'}
                value={pageSize}
                onChange={e => {
                    setPageSize(Number(e.target.value))
                }}
            >
                {[10, 20, 30, 40, 50, 100, 200].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                        {pageSize} {t('per page')}
                    </option>
                ))}
            </select>
            <Button.Group size={'sm'} dense>
                <Button
                    variant={"icon"}
                    onClick={() => previousPage()} disabled={!canPreviousPage}
                >
                    {'<'}
                </Button>
                <span><strong>{pageIndex * pageSize} - {pageSize * pageIndex + pageSize}</strong></span>
                <Button
                    variant={"icon"}
                    onClick={() => nextPage()} disabled={!canNextPage}
                >
                    {'>'}
                </Button>
            </Button.Group>
        </div>
    );
};

export default Pagination;