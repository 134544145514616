import * as ActionTypes from '../../actions/types'
import {fromJS} from "immutable";
import {mapMultiple, preserveFieldsIfNotPresentInNew} from "../../helpers/map";
import {mapOrder, mapOrderProducts} from "../../types/order/map";
import _ from "lodash"
import {OrderStatus as OrderStatusTypes} from "../../helpers/constants";

export const INITIAL_STATE = {
    orderBalance: {
        totalPurchase: null,
        totalRemainder: null,
    },
    loading: false,
    creatingOrder: false,
    cloneOrder: {
        loading: false,
        error: null,
        created: null
    },
    byId: {},
    activeOrder: [],
    indexTable: {
        loading: false,
        items: [],
        count: 0
    },
    templatesIndexTable: {
        loading: false,
        items: [],
        count: 0
    },
    ordersFromSalesPersonsById: {},
    ordersFromSalesOpen: true,
    ordersFromSalesPersons: {
        loading: false,
        items: [],
        count: 0
    }
}

const mergeProducts = (state, toStore) => (
    state.get('byId')
        .mergeWith(
            preserveFieldsIfNotPresentInNew(['products']), fromJS(toStore)
        )
)

function getOrders(state) {
    return state
        .setIn(['indexTable', 'loading'], true)
}

function getOrdersSucceeded(state, action) {
    const {payload: {count, result}} = action
    const mappedData = mapMultiple(result, mapOrder)
    const sortedKeys = _.orderBy(mappedData, 'date', 'desc').map(i => i.id);

    return state
        .set('byId', mergeProducts(state, mappedData))
        // .set('byId', state.get('byId').merge(fromJS(mappedData)))
        .setIn(['indexTable', 'count'], parseInt(Number(count)))
        .setIn(['indexTable', 'items'], fromJS(sortedKeys))
        .setIn(['indexTable', 'loading'], false)
}

function getOrdersFailed(state) {
    return state
        .setIn(['indexTable', 'loading'], false)
}

function getOrderDetails(state) {
    return state
        .set('loading', true);
}

function getOrderDetailsSucceeded(state, action) {
    const mappedData = mapOrder(action.payload)
    return state
        .set('loading', false)
        .setIn(['byId', mappedData.id], fromJS(mappedData))
        .update('activeOrder', items => (
            !mappedData.isTemplate &&
            mappedData.statusId === OrderStatusTypes.Change &&
            !items.includes(mappedData.id)) ? items.push(mappedData.id) : items
        )
}

function getOrderDetailsFailed(state) {
    return state
        .set('loading', false);
}

function getActiveOrder(state) {
    return state
        .set('loading', true);
}

function getActiveOrderSucceeded(state, action) {

    const {result} = action.payload
    const mappedData = mapMultiple(result, mapOrder)
    return state
        .set('loading', false)
        .set('byId', mergeProducts(state, mappedData))
        .set('activeOrder', fromJS(Object.keys(mappedData)))
}

function getActiveOrderFailed(state) {
    return state
        .set('loading', false)
}

function createOrder(state) {
    return state
        .set('creatingOrder', true)
        .set('loading', true)
}

function createOrderSucceeded(state, action) {
    const mappedData = mapOrder(action.payload)
    return state
        .set('creatingOrder', false)
        .set('loading', false)
        .setIn(['byId', mappedData.id], fromJS(mappedData))
        .updateIn(['indexTable', 'items'], items => items.unshift(mappedData.id))
        .update('activeOrder', items => (!mappedData.isTemplate && mappedData.statusId === OrderStatusTypes.Change) ? items.push(mappedData.id) : items)
}

function createOrderFailed(state) {
    return state
        .set('creatingOrder', false)
        .set('loading', false)
}

function updateOrder(state, action) {
    const {payload: {id, products}} = action
    const newProducts = mapOrderProducts(_.groupBy(products, 'ItemId'), true)
    return state
        .set('loading', true)
        .setIn(['byId', id, 'products'], fromJS(newProducts))
}

function updateOrderSucceeded(state, action) {
    const {payload} = action
    const mappedData = mapOrder(payload)
    return state
        .set('loading', false)
        .update('activeOrder', items => (!mappedData.isTemplate && mappedData.statusId === OrderStatusTypes.Sent) ? items.filter(key => key !== mappedData.id) : items)
        .setIn(['byId', mappedData.id, 'statusId'], mappedData.statusId)
        .setIn(['byId', mappedData.id, 'notes'], mappedData.notes)
}

function updateOrderFailed(state) {
    return state
        .set('loading', false)
}

function deleteOrder(state) {
    return state
        .set('loading', true)
}

function deleteOrderSucceeded(state, action) {
    const {parameters: {id}} = action
    return state
        .set('loading', false)
        .update('activeOrder', items => items.filter(key => key !== id))
        .updateIn(['indexTable', 'items'], items => items.filter(key => key !== id))
        .removeIn(['byId', id])
}

function deleteOrderFailed(state) {
    return state
        .set('loading', false)
}

function getOrderTemplates(state) {
    return state
        .setIn(['templatesIndexTable', 'loading'], true)
}

function getOrderTemplatesSucceeded(state, action) {
    const {payload: {count, result}} = action
    const mappedData = mapMultiple(result, mapOrder)
    return state
        .set('byId', state.get('byId').merge(fromJS(mappedData)))
        .setIn(['templatesIndexTable', 'count'], parseInt(Number(count)))
        .setIn(['templatesIndexTable', 'items'], fromJS(Object.keys(mappedData)))
        .setIn(['templatesIndexTable', 'loading'], false)
}

function getOrderTemplatesFailed(state) {
    return state
        .setIn(['templatesIndexTable', 'loading'], false)
}

function cloneOrder(state) {
    return state
        .setIn(['cloneOrder', 'error'], null)
        .setIn(['cloneOrder', 'created'], null)
        .setIn(['cloneOrder', 'loading'], true)
}

function cloneOrderSucceeded(state, action) {
    const mappedData = mapOrder(action.payload)
    return state
        .setIn(['cloneOrder', 'created'], mappedData.id)
        .setIn(['cloneOrder', 'loading'], false)
        .setIn(['byId', mappedData.id], fromJS(mappedData))
        .update('activeOrder', items => (!mappedData.isTemplate && mappedData.statusId === OrderStatusTypes.Change) ? items.push(mappedData.id) : items)
}

function cloneOrderFailed(state, action) {
    const {message} = action.payload
    return state
        .setIn(['cloneOrder', 'error'], message)
        .setIn(['cloneOrder', 'loading'], false)
}

function getOrderBalance(state) {
    return state
        .set('loading', true);
}

function getOrderBalanceSucceeded(state, action) {
    const {CustomerBalance, CustomerSales} = action.payload
    return state
        .setIn(['orderBalance', 'totalPurchase'], Number(CustomerSales))
        .setIn(['orderBalance', 'totalRemainder'], Number(CustomerBalance))
}

function getOrderBalanceFailed(state, action) {
    return state
        .set('loading', false);
}

function getOrdersFromSalesPersons(state) {
    return state
        .set('loading', true)
}

function getOrdersFromSalesPersonsSucceeded(state, action) {
    const {payload: {count, result}} = action
    const mappedData = mapMultiple(result, mapOrder)
    const sortedKeys = _.orderBy(mappedData, 'date', 'desc').map(i => i.id);
    return state
        .set('ordersFromSalesPersonsById', mergeProducts(state, mappedData))
        .setIn(['ordersFromSalesPersons', 'count'], parseInt(Number(count)))
        .setIn(['ordersFromSalesPersons', 'items'], fromJS(sortedKeys))
        .setIn(['ordersFromSalesPersons', 'loading'], false)
}


function getOrdersFromSalesPersonsFailed(state) {
    return state
        .set('loading', false)
}

function toggleOrdersFromSalesPersons(state, action) {
    const {payload} = action
    return state
        .set('ordersFromSalesOpen', payload)
}

const order = {
    initialState: INITIAL_STATE,
    handlers: {
        [ActionTypes.GET_ORDERS]: getOrders,
        [ActionTypes.GET_ORDERS_SUCCEEDED]: getOrdersSucceeded,
        [ActionTypes.GET_ORDERS_FAILED]: getOrdersFailed,

        [ActionTypes.GET_ACTIVE_ORDER]: getActiveOrder,
        [ActionTypes.GET_ACTIVE_ORDER_SUCCEEDED]: getActiveOrderSucceeded,
        [ActionTypes.GET_ACTIVE_ORDER_FAILED]: getActiveOrderFailed,

        [ActionTypes.GET_ORDER_DETAILS]: getOrderDetails,
        [ActionTypes.GET_ORDER_DETAILS_SUCCEEDED]: getOrderDetailsSucceeded,
        [ActionTypes.GET_ORDER_DETAILS_FAILED]: getOrderDetailsFailed,

        [ActionTypes.CREATE_ORDER]: createOrder,
        [ActionTypes.CREATE_ORDER_SUCCEEDED]: createOrderSucceeded,
        [ActionTypes.CREATE_ORDER_FAILED]: createOrderFailed,

        [ActionTypes.UPDATE_ORDER]: updateOrder,
        [ActionTypes.UPDATE_ORDER_SUCCEEDED]: updateOrderSucceeded,
        [ActionTypes.UPDATE_ORDER_FAILED]: updateOrderFailed,

        [ActionTypes.DELETE_ORDER]: deleteOrder,
        [ActionTypes.DELETE_ORDER_SUCCEEDED]: deleteOrderSucceeded,
        [ActionTypes.DELETE_ORDER_FAILED]: deleteOrderFailed,

        [ActionTypes.GET_ORDER_TEMPLATES]: getOrderTemplates,
        [ActionTypes.GET_ORDER_TEMPLATES_SUCCEEDED]: getOrderTemplatesSucceeded,
        [ActionTypes.GET_ORDER_TEMPLATES_FAILED]: getOrderTemplatesFailed,

        [ActionTypes.CLONE_ORDER]: cloneOrder,
        [ActionTypes.CLONE_ORDER_SUCCEEDED]: cloneOrderSucceeded,
        [ActionTypes.CLONE_ORDER_FAILED]: cloneOrderFailed,

        [ActionTypes.GET_ORDER_BALANCE]: getOrderBalance,
        [ActionTypes.GET_ORDER_BALANCE_SUCCEEDED]: getOrderBalanceSucceeded,
        [ActionTypes.GET_ORDER_BALANCE_FAILED]: getOrderBalanceFailed,

        [ActionTypes.GET_ORDERS_FROM_SALES_PERSONS]: getOrdersFromSalesPersons,
        [ActionTypes.GET_ORDERS_FROM_SALES_PERSONS_SUCCEEDED]: getOrdersFromSalesPersonsSucceeded,
        [ActionTypes.GET_ORDERS_FROM_SALES_PERSONS_FAILED]: getOrdersFromSalesPersonsFailed,
        [ActionTypes.TOGGLE_ORDERS_FROM_SALES_PERSONS]: toggleOrdersFromSalesPersons,
    }
};

export default order