//@flow
import React, {Fragment, useState} from 'react';
import Util from "../../components/util/util";
import Title from "../../components/title/title";
import Divider from "../../components/divider/divider";
import Button from "../../components/button/button";
import usePaymentAction from "../../hooks/payment/usePaymentAction";
import usePaymentData from "../../hooks/payment/usePaymentData";
import {useTranslation} from "react-i18next";
import {PAGE_PAYMENTS} from "../../locales/pages/namespaces";
import {useUserData} from "../../hooks/user/useUserData";
import {withPermissionHOC} from "../../modules/permission/components/permissionHOC/permissionHOC";
import {Permissions} from "../../helpers/constants";
import {useOrderBalance} from "../../hooks/order/useOrderBalance";
import OnlinePaymentConfirm from "../../components/onlinePaymentConfirm/onlinePaymentConfirm";
import {useFormik} from "formik";
import * as yup from "yup";
import OnlinePaymentRedirectForm from "../../components/onlinePaymentRedirectForm/onlinePaymentRedirectForm";

const OnlinePayments = () => {
    const {t} = useTranslation(PAGE_PAYMENTS)
    const {getPaymentTicketingSOAP} = usePaymentAction()
    const {currentPayment} = usePaymentData()
    const {language} = useUserData()
    const {totalRemainderAsNumber} = useOrderBalance()
    const [openOnlinePaymentConfirm, setOpenOnlinePaymentConfirm] = useState(false)
    const [proceedToPayment, setProceedToPayment] = useState(false)

    const validationSchema = yup.object({
        amount: yup
            .number()
            .positive(t('amount must be positive number'))
            .required(t('required'))
    })

    const onPay = (values, {resetForm}) => {
        getPaymentTicketingSOAP(values)
        setOpenOnlinePaymentConfirm(false)
        resetForm()
    }
    const formik = useFormik({
        initialValues: {
            amount: '',
        },
        validationSchema: validationSchema,
        onSubmit: onPay,
    })

    const onClose = () => {
        formik.resetForm()
        setOpenOnlinePaymentConfirm(false)
    }

    return (
        <div className={'bg-container bg-container--transparent contained-page'}>
            <Util>
                <Title variant={"h2"}>
                    {t('title')}
                </Title>
            </Util>
            <Divider/>
            <p>
                {t('message')}
            </p>
            <Divider thin/>
            <Util>
                {totalRemainderAsNumber ? (
                    <Fragment>
                        <Title variant={"h4"}>
                            {t('remainder')}
                        </Title>
                        <Title variant={"h4"}>
                            {totalRemainderAsNumber}
                        </Title>
                    </Fragment>
                ) : <div/>}
                <Button
                    onClick={() => setOpenOnlinePaymentConfirm(true)}
                    disabled={proceedToPayment}
                >
                    {t('pay')}
                </Button>
            </Util>
            <Divider thin/>
            <br/>
            <OnlinePaymentConfirm
                show={openOnlinePaymentConfirm}
                onSubmit={formik.handleSubmit}
                onClose={onClose}
                formik={formik}
                setProceedToPayment={setProceedToPayment}
            />
            {currentPayment.status === 'Succeed' && currentPayment.transactionTicket.length > 0 &&
                <OnlinePaymentRedirectForm currentPayment={currentPayment} language={language} />}
        </div>
    )
}

export default withPermissionHOC(OnlinePayments, [Permissions.AccessPayments])