// @flow
import React from 'react';
import useTableIndex from "../useTableIndex";

type Props = {
    viewComponent: any
}

const Main = (props: Props) => {
    const {viewComponent: ViewComponent, ...otherProps} = props;
    const {
        initialState,
        columns,
        data,
        fetchData,
        loading,
        pageCount: controlledPageCount,
    } = props
    const table = useTableIndex({
        columns,
        data,
        controlledPageCount,
        initialState,
        fetchData,
        loading
    })
    return (
        <ViewComponent
            table={table}
            {...otherProps}
        />
    )
}

export default Main;