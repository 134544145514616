//@flow
import * as React from 'react';
import './list.css'
import classNames from "classnames";

type Props = {
    children: React.Node,
    fullHeight?: boolean,
    dense?: boolean
};

const List = (props: Props) => {
    const {children, fullHeight, dense} = props
    return (
        <ul className={classNames('list', {
            'list--fullHeight': fullHeight,
            'list--dense': dense
        })}>
            {children}
        </ul>
    );
};

export default List;