import {applyMiddleware, compose, createStore} from 'redux';
import {persistReducer, persistStore} from 'redux-persist';
import {routerMiddleware} from 'connected-react-router'
import storage from 'redux-persist/es/storage' // default: localStorage if web, AsyncStorage if react-native
import immutableTransform from 'redux-persist-transform-immutable';
import {reducers} from './reducers/reducers';
import {createLogger} from 'redux-logger';
import Immutable from 'immutable';
import axiosMiddleware from "./middleware/axios";
import history from './history';

const middleWare = [];

middleWare.push(axiosMiddleware)
middleWare.push(routerMiddleware(history));

const config = {
    transforms: [immutableTransform({
        whitelist: ['currentUser', 'productCached']
    })],
    key: 'rootB2BCI',
    whitelist: ['currentUser', 'productCached'],
    storage,
};

if (process.env.NODE_ENV === `development`) {
    const logger = createLogger({
        stateTransformer: (state) => {
            let newState = {};
            for (let i of Object.keys(state)) {
                if (Immutable.Iterable.isIterable(state[i])) {
                    newState[i] = state[i].toJS();
                } else {
                    newState[i] = state[i];
                }
            }
            return newState;
        }
    });
    middleWare.push(logger);
}

const store = createStore(
    persistReducer(config, reducers),
    compose(applyMiddleware(...middleWare)),
);

export const persistor = persistStore(store);

export default store;