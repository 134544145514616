// @flow
import {useSelector} from "react-redux";
import {parseCategoryIndexTable, parseProductCategory} from "../../types/product/parse";
import {createSelector} from "reselect";
import {parseMultiple} from "../../helpers/parse";

const selectLoading = createSelector(
    (state) => state.product.get('loading'),
    (item) => item
)

const selectById = createSelector(
    [
        (state, id, subCategoryId) => state.product.get(subCategoryId ? 'subCategoriesById' : 'categoriesById'),
        (state) => state.productCached.get('byId'),
        (state) => state.currentUser.get('language'),
        (state, id) => id,
        (state, id, subCategoryId) => subCategoryId
    ],
    (categoriesById, productsById, language, id, subCategoryId) => {
        // if (result && subCategoryId) {
        //     return result.children.find(c => c.id === subCategoryId)
        // }
        return parseProductCategory(
            categoriesById.get(subCategoryId ? subCategoryId : id),
            productsById,
            language,
        )
    }
)

const selectAllIndexTable = createSelector(
    [
        (state) => state.productCached.get('allIndexTable'),
        (state) => state.productCached.get('byId'),
        (state) => state.currentUser.get('language'),
    ],
    (indexTable, productsById, language) => parseCategoryIndexTable(indexTable, productsById, language)
)

const selectCategories = createSelector(
    [
        (state) => state.product.get('categoriesById'),
        (state) => state.currentUser.get('language'),
        (state, typeId) => typeId
    ],
    (items, language, typeId) => {
        const result = parseMultiple(items, (i) => parseProductCategory(i, null, language))
        return typeId ? result.filter(c => c.typeId === typeId) : result
    }
)

const useProductCategoryDetailsData = ({id, subCategoryId, typeId} = {}) => {
    const loading = useSelector(selectLoading)
    const byId = useSelector(state => selectById(state, id, subCategoryId))
    const all = useSelector(state => selectCategories(state, typeId))
    const allIndexTable = useSelector(selectAllIndexTable)
    return {
        loading,
        byId,
        all,
        allIndexTable
    }
}

export default useProductCategoryDetailsData