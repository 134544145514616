//@flow
import React from 'react';
import ListItemSubtitle from "../../components/list/partials/listItemSubtitle/listItemSubtitle";
import {formatDate, formatTypes} from "../../helpers/date";
import ListItemTitle from "../../components/list/partials/listItemTitle/listItemTitle";
import ListItemText from "../../components/list/partials/listItemText/listItemText";
import Util from "../../components/util/util";
import Fab from "../../components/fab/fab";
import Images from "../../assets/images";
import ListItem from "../../components/list/partials/listItem/listItem";
import Dotdotdot from "react-dotdotdot";
import type {Message} from "../../types/message/type";
import {useTranslation} from "react-i18next";
import {PAGE_ANNOUNCEMENTS} from "../../locales/pages/namespaces";
import Attachments from "./attachments/attachments";

type Props = {
	isActive?: boolean,
	isRead?: boolean,
	announcement: Message,
	onExpandButtonClick: Function,
	onCloseButtonClick: Function,
};

export const FabButton = ({
					   isActive,
					   isRead,
					   onExpandButtonClick,
					   onCloseButtonClick
				   }) => (
	<div className={'list-fab'}>
		{!isActive && (
			<	Fab
				onClick={onExpandButtonClick}
				icon={Images.icons.plus}
				variant={isRead ? 1 : 2}
			/>
		)}
		{isActive && (
			<Fab
				onClick={onCloseButtonClick}
				icon={Images.icons.close}
				variant={8}
			/>
		)}
	</div>
)


const AnnouncementItem = (props: Props) => {
	const {t} = useTranslation(PAGE_ANNOUNCEMENTS)
	const {
		announcement: {
			date,
			title,
			id,
			text,
			typeId,
			files
		},
		isActive,
		isRead,
		onExpandButtonClick,
		onCloseButtonClick,
	} = props
	const TextWrapper = !isActive ? Dotdotdot : 'div'
	return (
		<ListItem
			key={id}
			contained
			className={isActive ? 'active-announcement' : null}
			noBorders={isActive}
		>
			<div id={id}/>
			<ListItemSubtitle>
				<ListItemSubtitle.Primary>
					{t('announcement_type_' + typeId)}
				</ListItemSubtitle.Primary>
				<ListItemSubtitle.Separator/>
				<ListItemSubtitle.Secondary>
					{formatDate(date, formatTypes.DATE_ONLY_LONG).toUpperCase()}
				</ListItemSubtitle.Secondary>
			</ListItemSubtitle>
			<ListItemTitle variant={"h3"}>
				<Util>
					<div>
						{title}
					</div>
					<div className="show-mobile">
						<FabButton
							isActive={isActive}
							isRead={isRead}
							onExpandButtonClick={onExpandButtonClick}
							onCloseButtonClick={onCloseButtonClick}
						/>
					</div>
				</Util>
			</ListItemTitle>
			<ListItemText>
				{isActive &&
					(
						<div
							style={isActive ? {marginBottom: '.5rem',} : {display: 'none'}}>
							<Attachments
								id={id}
								files={files}
							/>
						</div>
					)}
				<Util align={"flex-start"}>
					<TextWrapper
						{...TextWrapper !== "div" ? {clamp: 2, useNativeClamp: true} : {}}
						style={{overflow: "auto"}}
					>
						<div dangerouslySetInnerHTML={{__html: text}}/>
					</TextWrapper>
					<div className="hide-mobile">
						<FabButton
							isActive={isActive}
							isRead={isRead}
							onExpandButtonClick={onExpandButtonClick}
							onCloseButtonClick={onCloseButtonClick}
						/>
					</div>
				</Util>
			</ListItemText>
		</ListItem>
	)
}

export default AnnouncementItem;