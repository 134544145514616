//@flow
import React, {useCallback, useState} from 'react';
import {useUserData} from "../../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import {CUSTOMER_INFO} from "../../../locales/components/namespaces";
import useUserAction from "../../../hooks/user/useUserAction";
import Title from "../../title/title";
import Divider from "../../divider/divider";
import RangeSelect from "../../rangeSelect/rangeSelect";
import moment from "moment";
import {PAGE_REPORTS} from "../../../locales/pages/namespaces";
import SelectStore from "../../selectStore/selectStore";
import Util from "../../util/util";
import Table from "../../table/table";
import './report1.css'
import {withPermissionHOC} from "../../../modules/permission/components/permissionHOC/permissionHOC";
import {Permissions} from "../../../helpers/constants";
import DropdownFilter from "../../table/partials/filters/dropdownFilter/dropdownFilter";
import TextFilter from "../../table/partials/filters/textFilter/textFilter";
import NumberRangeFilter from "../../table/partials/filters/numberRangeFilter/numberRangeFilter";
import {useHasPermission} from "../../../modules/permission/hooks/useHasPermission";

const NumberCell = ({value}) => (
    <div style={{textAlign: "right"}}>
        {Number(value).toFixed(2)}
    </div>
)

const Report1 = () => {
    const [selectedStore, setSelectedStore] = useState('all');
    const isAdmin = useHasPermission([Permissions.AccessAdmin], {shouldView: true})
    const isSalesPerson = useHasPermission([Permissions.AccessSalesPerson], {shouldView: true})
    const [range, setRange] = useState([
        moment().subtract(31, 'days').toDate(), new Date()
    ])
    const {t} = useTranslation([CUSTOMER_INFO, PAGE_REPORTS])
    const {report1IndexTable} = useUserData()
    const {getReport1Info} = useUserAction()
    const onSubmit = (values) => {
        const {range} = values
        setRange(range)
    }
    const dataManager = useCallback((pageIndex, pageSize) => {
        getReport1Info(pageIndex, pageSize, range, selectedStore)
    }, [getReport1Info, range, selectedStore])
    return (
        <div className={'report1-page contained-page bg-container'}>
            <Util>
                <Title variant={"h3"}>
                    {t(PAGE_REPORTS + ':PERIOD SALES PER ADDRESS AND PRODUCT')}
                </Title>
                {!isAdmin && !isSalesPerson && <SelectStore
                    showAllOption
                    updateAcrossApplication={false}
                    selected={selectedStore === 'all' ? selectedStore : selectedStore.id}
                    onSelection={setSelectedStore}
                />}
            </Util>
            <Divider/>
            <RangeSelect
                initialRange={range}
                onSubmit={onSubmit}
            />
            <br/>
            <div className="table-wrapper">
                <Table
                    data={report1IndexTable}
                    pageSize={10}
                    dataManager={dataManager}
                    // noHeader
                    noMeta
                    noPagination
                    customStyle
                    groupBy={['category.title', 'subcategory.title']}
                    expanded={report1IndexTable
                        .items
                        .reduce((p, n) => ({
                            ...p,
                            ['category.title:' + n.category.title]: true
                        }), {})
                    }
                    columns={[
                        {
                            Header: t('Category'),
                            accessor: 'category.title',
                            Filter: DropdownFilter,
                            filter: 'includes',
                        },
                        {
                            Header: t('Sub Category'),
                            accessor: 'subCategory.title',
                            Filter: DropdownFilter,
                            filter: 'includes',
                        },
                        {
                            Header: t('Item'),
                            accessor: 'description',
                            Filter: TextFilter,
                            filter: 'includes',
                        },
                        {
                            Header: t('Quantity'),
                            accessor: 'quantity',
                            aggregate: 'sum',
                            Filter: NumberRangeFilter,
                            filter: 'between',
                            Cell: NumberCell,
                            Aggregated: NumberCell,
                        },
                        {
                            Header: t('Price'),
                            accessor: 'price',
                            aggregate: 'sum',
                            Filter: NumberRangeFilter,
                            filter: 'between',
                            Cell: NumberCell,
                            Aggregated: NumberCell,
                        },
                    ]}
                />
            </div>
        </div>
    )
}

export default withPermissionHOC(Report1, [Permissions.AccessReports])
