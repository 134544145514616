//@flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {
    parseCustomerTabInfo,
    parseReport1Info,
    parseReport2Info,
    parseReport3Info, parseReport4Info,
    parseReport4ProductData, parseReport5Info, parseReport6Info, parseReport7Info,
    parseStore,
    parseUser
} from "../../types/user/parse";
import {parseMultiple} from "../../helpers/parse";
import type {CustomerTabInfo, Store} from "../../types/user/types";
import _ from "lodash"

const selectIsLoggedIn = createSelector(
    (state) => state.currentUser,
    (item) => item.get('isLoggedIn')
)

const selectLoading = createSelector(
    (state) => state.currentUser,
    (item) => item.get('loading')
)

const selectToken = createSelector(
    (state) => state.currentUser,
    (item) => item.get('token')
)

const selectLoadingStores = createSelector(
    (state) => state.currentUser,
    (item) => item.get('loadingStores')
)

const selectErrorLogin = createSelector(
    (state) => state.currentUser,
    (item) => item.get('errorLogin')
)

const selectErrorStores = createSelector(
    (state) => state.currentUser,
    (item) => item.get('errorStores')
)

const selectLanguage = createSelector(
    (state) => state.currentUser,
    (item) => item.get('language')
)

const selectSelectedStore = createSelector(
    [
        (state) => state.currentUser.get('storesById'),
        (state) => state.currentUser.get('selectedStore'),
        (state) => state.currentUser.get('language'),
    ],
    (byId, storeId, language) => parseStore(byId.get(storeId), language)
)

const selectStores = createSelector(
    [
        (state) => state.currentUser.get('storesById'),
        (state) => state.currentUser.get('language'),
    ],
    (items, language) => parseMultiple(items, (i) => parseStore(i, language))
)

const selectData = createSelector(
    [
        (state) => state.currentUser.get('data'),
        (state) => state.currentUser.get('language'),
    ],
    (item, language) => parseUser(item, language)
)

const selectCustomerTabInfo = createSelector(
    [
        (state) => state.currentUser.get('customerTabInfo'),
        (state) => state.currentUser.get('language'),
    ],
    (item, language) => parseCustomerTabInfo(item, language)
)

const selectReport1IndexTable = createSelector(
    [
        (state) => state.currentUser.get('report1IndexTable'),
        (state) => state.currentUser.get('byId'),
        (state) => state.currentUser.get('language'),
    ],
    (indexTable, byId, language) => ({
        loading: indexTable?.get('loading'),
        count: indexTable?.get('count'),
        items: parseMultiple(indexTable?.get('items'), (i) => parseReport1Info(i, language)),
    })
)

const selectReport2IndexTable = createSelector(
    [
        (state) => state.currentUser.get('report2IndexTable'),
        (state) => state.currentUser.get('byId'),
        (state) => state.currentUser.get('language'),
    ],
    (indexTable, byId, language) => ({
        loading: indexTable?.get('loading'),
        count: indexTable?.get('count'),
        items: parseMultiple(indexTable?.get('items'), (i) => parseReport2Info(i, language)),
    })
)

const selectReport3IndexTable = createSelector(
    [
        (state) => state.currentUser.get('report3IndexTable'),
        (state) => state.currentUser.get('byId'),
        (state) => state.currentUser.get('language'),
    ],
    (indexTable, byId, language) => ({
        loading: indexTable?.get('loading'),
        count: indexTable?.get('count'),
        items: parseMultiple(indexTable?.get('items'), (i) => parseReport3Info(i, language)),
    })
)

const selectReport4IndexTable = createSelector(
    [
        (state) => state.currentUser.get('report4IndexTable'),
        (state) => state.currentUser.get('byId'),
        (state) => state.currentUser.get('language'),
    ],
    (indexTable, byId, language) => ({
        loading: indexTable?.get('loading'),
        count: indexTable?.get('count'),
        items: parseMultiple(indexTable?.get('items'), (i) => parseReport4Info(i, language)),
    })
)

const selectReport4OrderProducts = createSelector(
    [
        (state) => state.currentUser.get('report4OrderProducts'),
        (state) => state.currentUser.get('byId'),
        (state) => state.currentUser.get('language'),
    ],
    (indexTable, byId, language) => ({
        loading: indexTable?.get('loading'),
        count: indexTable?.get('count'),
        items: parseMultiple(indexTable?.get('items'), (i) => parseReport4ProductData(i, language)),
    })
)

const selectReport5IndexTable = createSelector(
    [
        (state) => state.currentUser.get('report5IndexTable'),
        (state) => state.currentUser.get('byId'),
        (state) => state.currentUser.get('language'),
    ],
    (indexTable, byId, language) => ({
        loading: indexTable?.get('loading'),
        count: indexTable?.get('count'),
        items: parseMultiple(indexTable?.get('items'), (i) => parseReport5Info(i, language)),
    })
)

const selectReport6IndexTable = createSelector(
    [
        (state) => state.currentUser.get('report6IndexTable'),
        (state) => state.currentUser.get('byId'),
        (state) => state.currentUser.get('language'),
    ],
    (indexTable, byId, language) => ({
        loading: indexTable?.get('loading'),
        count: indexTable?.get('count'),
        items: parseMultiple(indexTable?.get('items'), (i) => parseReport6Info(i, language)),
    })
)

const selectReport7IndexTable = createSelector(
    [
        (state) => state.currentUser.get('report7IndexTable'),
        (state) => state.currentUser.get('byId'),
        (state) => state.currentUser.get('language'),
    ],
    (indexTable, byId, language) => ({
        loading: indexTable?.get('loading'),
        count: indexTable?.get('count'),
        items: parseMultiple(indexTable?.get('items'), (i) => parseReport7Info(i, language)),
    })
)

export const useUserData = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn)
    const loading = useSelector(selectLoading)
    const token = useSelector(selectToken)
    const errorLogin = useSelector(selectErrorLogin)
    const loadingStores = useSelector(selectLoadingStores)
    const errorStores = useSelector(selectErrorStores)
    const language = useSelector(selectLanguage)
    const data = useSelector(selectData)
    const customerTabInfo: CustomerTabInfo = useSelector(selectCustomerTabInfo)
    const selectedStore = useSelector(selectSelectedStore)
    const stores: Array<Store> = useSelector(selectStores)
    const sortedStores = _.sortBy(stores, ['customerErpCode', 'erpCode'])
    const report1IndexTable = useSelector(selectReport1IndexTable)
    const report2IndexTable = useSelector(selectReport2IndexTable)
    const report3IndexTable = useSelector(selectReport3IndexTable)
    const report4IndexTable = useSelector(selectReport4IndexTable)
    const report4OrderProducts = useSelector(selectReport4OrderProducts)
    const report5IndexTable = useSelector(selectReport5IndexTable)
    const report6IndexTable = useSelector(selectReport6IndexTable)
    const report7IndexTable = useSelector(selectReport7IndexTable)
    return {
        isLoggedIn,
        loading,
        loadingStores,
        errorLogin,
        errorStores,
        language,
        data,
        customerTabInfo,
        report1IndexTable,
        report2IndexTable,
        report3IndexTable,
        report4IndexTable,
        report4OrderProducts,
        report5IndexTable,
        report6IndexTable,
        report7IndexTable,
        selectedStore,
        stores,
        sortedStores,
        token
    }
}