import * as ActionTypes from '../../actions/types'
import {mapMultiple, preserveFieldsIfNotPresentInNew} from "../../helpers/map";
import {mapBanner} from "../../types/banner/map";
import {fromJS} from "immutable";

const INITIAL_STATE = {
    loading: false,
    byId: {},
}


function getBanners(state) {
    return state
        .set('loading', true)
}

function getBannersSucceeded(state, action) {
    const {payload: {banners}} = action
    const mappedData = mapMultiple(banners, mapBanner)
    return state
        .set('loading', false)
        .set('byId',
            state.get('byId')
                .mergeWith(
                    preserveFieldsIfNotPresentInNew([]), fromJS(mappedData)
                ))
}

function getBannersFailed(state, action) {
    return state
        .set('loading', false)
}

const banner = {
    initialState: INITIAL_STATE,
    handlers: {
        [ActionTypes.GET_BANNERS]: getBanners,
        [ActionTypes.GET_BANNERS_SUCCEEDED]: getBannersSucceeded,
        [ActionTypes.GET_BANNERS_FAILED]: getBannersFailed,
    }
}

export default banner
