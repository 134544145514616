// @flow
import React, {Fragment, useContext, useEffect, useState} from 'react';
import Button from "../../button/button";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import {ROUTE_PAGE_ORDERS, ROUTE_PAGE_PRODUCT_CATEGORY_PARENT} from "../../../routers/routes";
import Icon from "../../icon/icon";
import Images from "../../../assets/images";
import Util from "../../util/util";
import Title from "../../title/title";
import Divider from "../../divider/divider";
import Preview from "../../preview/preview";
import Textarea from "../../form/elements/textarea/textarea";
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import {Trans, useTranslation} from "react-i18next";
import {PAGE_ORDERS} from "../../../locales/pages/namespaces";
import type {Order} from "../../../types/order/types";
import {calculateProductSum, calculateProductTax, categorizeProducts, calculateProductDiscount} from "../../../helpers/helpers";
import {MenuContext} from "../../../pages/main/main";
import * as yup from "yup";
import Error from "../../error/error";
import DeleteConfirmation from "../../deleteConfirmation/deleteConfirmation";
import useActiveOrderUpdate from "../../../hooks/order/useActiveOrderUpdate";
import {useHistory} from "react-router";
import {useUserData} from "../../../hooks/user/useUserData";

type Props = {
    order: Order,
    onSubmit: Function,
    onDelete: Function
};

const Form = (props: Props) => {
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
    const {order, onSubmit, onDelete} = props
    const {t} = useTranslation(PAGE_ORDERS)
    const menu = useContext(MenuContext)

    const {data} = useUserData()
    const {onProductQuantitySelect} = useActiveOrderUpdate()
    const validationSchema = yup.object({
        products: yup.array().min(1, t('at_least_one'))
    })

    const formik = useFormik({
        initialValues: {
            products: order.products,
            notes: order.notes
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    })

    useEffect(() => {
        formik.setFieldValue('products', order.products)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order.products])

    const history = useHistory()
    const {title, products, notes} = formik.values
    const categorizedProducts = categorizeProducts(products)
    const sum = calculateProductSum(products).toFixed(2)
    const tax = calculateProductTax(products).toFixed(2)
    const discount = calculateProductDiscount(products).toFixed(2)
    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
                <div className={'bg-container contained-page order-create-page'}>
                    <Button variant={'link'} component={Link} to={getRouteUrl(ROUTE_PAGE_ORDERS)}>
                        <Icon icon={Images.icons.arrowLeft}/> {t('back')}
                    </Button>
                    <Util>
                        <div className={'expand-mobile'}>
                            {title && (
                                <Title variant={"h4"} defaultFont>
                                    {title}
                                </Title>
                            )}
                            <Divider/>
                            <Util spacedItems>
                                <Title variant={"h4"}>
                                    {t('total_cost')} {t('with_tax')}
                                </Title>
                                <Title variant={"h4"} hideSubtitleOnMobile
                                       subtitle={t('tax') + ' (' + tax + data?.currencySymbol + ')'}>
                                    {sum}{data?.currencySymbol}
                                </Title>
                                <Button.Group className={'hide-mobile'}>
                                    <Button onClick={() => {
                                        menu.setMenuCategoryType(order.type)
                                        history.push(getRouteUrl(ROUTE_PAGE_PRODUCT_CATEGORY_PARENT, {
                                            id: 'all',
                                        }))
                                        // menu.setOpenMenu(true)
                                    }}>
                                        {t('change')}
                                    </Button>
                                    <Button onClick={() => setOpenDeleteConfirmation(true)}>
                                        {t('delete')}
                                    </Button>
                                </Button.Group>
                            </Util>
                            <DeleteConfirmation
                                show={openDeleteConfirmation}
                                onSubmit={onDelete}
                                onClose={() => setOpenDeleteConfirmation(false)}
                            >
                                <Trans
                                    i18nKey={'delete_warning'}
                                    ns={PAGE_ORDERS}>
                                    You are about to delete this order.<br/>
                                    This action cannot be undone.<br/>
                                    Are you sure you want to delete this order?<br/>
                                </Trans>
                            </DeleteConfirmation>
                            <Divider/>
                            <Button.Group className={'show-mobile'} spacingTop={1}>
                                <Button onClick={() => {
                                    menu.setMenuCategoryType(order.type)
                                    menu.setOpenMenu(true)
                                }}>
                                    {t('change')}
                                </Button>
                                <Button onClick={() => setOpenDeleteConfirmation(true)}>
                                    {t('delete')}
                                </Button>
                            </Button.Group>
                        </div>
                    </Util>
                    <br/>
                    <ErrorMessage component={Error} name="products"/>
                    {Object
                        .keys(categorizedProducts)
                        .map(category => {
                            const products = categorizedProducts[category];
                            return (
                                <Fragment key={category}>
                                    <br/>
                                    <Title style={{marginBottom: '.5rem'}} variant={"h4"}>
                                        {category}
                                    </Title>
                                    {products.map((product) => (
                                        <Preview
                                            key={product.id}
                                            product={product}
                                            onAdd={onProductQuantitySelect}
                                            orderType={order.type}
                                        />
                                    ))}
                                </Fragment>
                            )
                        })}
                    <br/>
                    <div className="order-create-page__bottom">
                        <div className="order-create-page__bottom_left hide-mobile">
                            <div className={"order-create-page__notes"}>
                                <Title variant={"h4"}>
                                    {t('notes')}
                                </Title>
                                <Textarea
                                    placeholder={t('write_your_message')}
                                    value={notes}
                                    name={'notes'}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>
                        <div className="order-create-page__bottom_right expand-mobile">
                            <Util>
                                <Title variant={"body"} style={{ fontFamily: 'CI', color: '#26BC7D', paddingBottom: '4px' }}>
                                    {t('discount').toUpperCase()}
                                </Title>
                                <Title variant={"body"} style={{ fontFamily: 'CI', color: '#26BC7D', paddingBottom: '4px'}}>
                                    {discount}{data?.currencySymbol}
                                </Title>
                            </Util>
                            <Util>
                                <Title variant={"h4"}>
                                    {t('total_cost')} {t('with_tax')}
                                </Title>
                                <Title variant={"h4"} subtitle={t('tax') + ' (' + tax + ')'}>
                                    {sum}{data?.currencySymbol}
                                </Title>
                            </Util>
                            <Divider spacingTop={1} spacingBottom={1}/>
                            <div className={"show-mobile"}>
                                <Title variant={"h4"}>
                                    {t('notes')}
                                </Title>
                                <Textarea
                                    className={'expand-mobile'}
                                    placeholder={t('write_your_message')}
                                    value={notes}
                                    name={'notes'}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    style={{
                                        marginBottom: '1rem'
                                    }}
                                />
                            </div>
                            <Button.Group>
                                <Button type={'submit'} variant={'action'} sup={t('finalize_2')}>
                                    {t('finalize_1')}
                                </Button>
                            </Button.Group>
                        </div>
                    </div>
                </div>
            </form>
        </FormikProvider>
    )
}

export default Form;