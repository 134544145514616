import React from 'react';
import {Bar} from 'react-chartjs-2';
import {useUserData} from "../../../../hooks/user/useUserData";

type Props = {
    data: {
        labels: Array<string>,
        datasets: Array<{
            label: string,
            data: Array<number>,
            backgroundColor: Array<string>
        }>
    }
}

const BarChart = (props: Props) => {
    const {data} = props
    const {data: userData} = useUserData()
    const currencySymbol = userData ? userData.currencySymbol : ''
    return (
        <div>
            <Bar
                options={{
                    scales: {
                        y: {
                            ticks: {
                                callback: (value, index, ticks) => currencySymbol + value
                            }
                        },
                    },
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            callbacks: {
                                label: (item) => {
                                    return item.raw.toFixed(2) + currencySymbol;
                                }
                            }
                        }
                    },
                }}
                data={data}
                type={"bar"}
            />
        </div>
    )
}

export default BarChart