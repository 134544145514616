//@flow
import React from 'react';
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {PAGE_ORDERS} from "../../locales/pages/namespaces";
import './orderStatus.css'

type Props = {
    status: string,
    finalize?: boolean
};

const OrderStatus = (props: Props) => {
    const {t} = useTranslation(PAGE_ORDERS)
    const {status, finalize} = props

    return (
        <span className={classNames('order-status', {
            ['order-status--' + status]: true
        })}>
            {t('order_status_' + (finalize === true ? 'finalize' : status))}
        </span>
    );
};

export default OrderStatus;