//@flow
import React, {useEffect} from 'react';
import Card from "../card/card";
import Button from "../button/button";
import './banner.css'
import {useBannerAction} from "../../hooks/banner/useBannerAction";
import {useBannerData} from "../../hooks/banner/useBannerData";
import type {Banner as BannerType} from "../../types/banner/type";
import Loading from "../loading/loading";
import {API} from "../../helpers/constants";

const Banner = () => {
    const {getBanners} = useBannerAction()
    const {banners, loading} = useBannerData()
    useEffect(() => {
        getBanners()
    }, [getBanners])
    return (
        <div className={'banner'}>
            {banners &&
            banners.length > 0 ?
                banners.map((item: BannerType) => (
                    <Card
                        key={item.id}
                        // light={}
                        textColor={item.textColor}
                        image={item.image ? API + '/uploads/b2b/banners/' + item.image : null}
                        preText={item.subtitle}
                        title={item.title}
                        text={item.description}
                        action={
                            item.buttonLink &&
                            <Button
                                component={"a"}
                                target={"_blank"}
                                href={item.buttonLink}
                                variant={'action'}
                                outlined
                            >
                                {item.buttonText}
                            </Button>
                        }
                    />
                )) : <Loading show={loading}/>}
        </div>
    )
}

export default Banner;
