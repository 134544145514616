import * as ActionTypes from '../../actions/types'
import {fromJS} from "immutable";
import {mapMultiple, preserveFieldsIfNotPresentInNew} from "../../helpers/map";
import {mapProduct, mapProductCategory} from "../../types/product/map";

const INITIAL_STATE = {
    categoriesById: {},
    subCategoriesById: {},
    searchResultsIndexTable: {
        loading: false,
        items: [],
        count: 0
    },
}

function getProductCategories(state) {
    return state
        .set('loading', true);
}

function getProductCategoriesSucceeded(state, action) {
    const {parameters: {OrderSheetId}, payload: {categories, subCategories}} = action
    const mappedData = mapMultiple(categories, mapProductCategory(OrderSheetId, subCategories))
    const mappedData2 = mapMultiple(subCategories, mapProductCategory(OrderSheetId, null))
    return state
        .set('loading', false)
        .set('categoriesById', state.get('categoriesById')
            .mergeWith(preserveFieldsIfNotPresentInNew(['products']), fromJS(mappedData)))
        .set('subCategoriesById', state.get('subCategoriesById')
            .mergeWith(preserveFieldsIfNotPresentInNew(['products']), fromJS(mappedData2)))
}

function getProductCategoriesFailed(state) {
    return state
        .set('loading', false);
}

function getSearchResults(state) {
    return state
        .setIn(['searchResultsIndexTable', 'loading'], true)
}

function getSearchResultsSucceeded(state, action) {
    const {payload: {count, result}, parameters: {skip}} = action
    const mappedData = mapMultiple(result, mapProduct)
    return state
        .set('loading', false)
        .setIn(['searchResultsIndexTable', 'loading'], false)
        .setIn(['searchResultsIndexTable', 'count'], parseInt(count))
        .updateIn(['searchResultsIndexTable', 'items'], items => skip > 0 ? items.concat(Object.keys(mappedData).reverse()) : fromJS(Object.keys(mappedData).reverse()))
}

function getSearchResultsFailed(state) {
    return state
        .setIn(['searchResultsIndexTable', 'loading'], false)
}

const product = {
    initialState: INITIAL_STATE, handlers: {
        [ActionTypes.GET_PRODUCT_CATEGORIES]: getProductCategories,
        [ActionTypes.GET_PRODUCT_CATEGORIES_SUCCEEDED]: getProductCategoriesSucceeded,
        [ActionTypes.GET_PRODUCT_CATEGORIES_FAILED]: getProductCategoriesFailed,

        [ActionTypes.GET_SEARCH_RESULTS]: getSearchResults,
        [ActionTypes.GET_SEARCH_RESULTS_SUCCEEDED]: getSearchResultsSucceeded,
        [ActionTypes.GET_SEARCH_RESULTS_FAILED]: getSearchResultsFailed,
    }
}

export default product