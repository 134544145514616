//@flow
import {useOrderData} from "./useOrderData";
import useOrderAction from "./useOrderAction";
import {useCallback, useContext, useEffect, useState} from "react";
import {MenuContext} from "../../pages/main/main";
import {useUserData} from "../user/useUserData";
import {useHistory} from "react-router";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_PRODUCT_CATEGORY_PARENT} from "../../routers/routes";

const useOrderCreate = (type) => {
    const menu = useContext(MenuContext)
    const orderType = type ? type : menu.menuCategoryType
    const {data} = useUserData()
    const {activeOrder, loading} = useOrderData()
    const {createOrder} = useOrderAction()
    const history = useHistory()
    const [showActiveOrderModal, setShowActiveOrderModal] = useState(false)
    const [createButtonClicked, setCreateButtonClicked] = useState(false)

    const {getActiveOrder} = useOrderAction()
    const activeOrderOfType = activeOrder.find(o => o.type === orderType)

    const onCreateButtonClick = useCallback((orderType) => {
        setCreateButtonClicked(true)
        menu.setMenuCategoryType(orderType)
    }, [menu])

    const openCreateMenu = useCallback(() => {
        // menu.setOpenMenu(true)
        history.push(getRouteUrl(ROUTE_PAGE_PRODUCT_CATEGORY_PARENT, {
            id: 'all',
        }))
        setShowActiveOrderModal(false)
    }, [history])

    useEffect(() => {
        getActiveOrder()
    }, [getActiveOrder])

    useEffect(() => {
        if (createButtonClicked && !loading) {
            if (activeOrderOfType) {
                setShowActiveOrderModal(true)
            } else {
                createOrder(orderType)
                openCreateMenu()
            }
            setCreateButtonClicked(false)
        }
    }, [activeOrderOfType, createButtonClicked, createOrder, loading, openCreateMenu, orderType])

    const orderTypes = data && data.orderTypes ? data.orderTypes : null

    return {
        activeOrderOfType,
        showActiveOrderModal,
        setShowActiveOrderModal,
        openCreateMenu,
        orderType,
        orderTypes,
        onCreateButtonClick
    }
}

export default useOrderCreate;