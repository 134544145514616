//@flow
import React, {useCallback} from 'react';
import Util from "../../components/util/util";
import Title from "../../components/title/title";
import SearchInput from "../../components/searchInput/searchInput";
import "./searchResults.css"
import useProductAction from "../../hooks/product/useProductAction";
import useProductData from "../../hooks/product/useProductData";
import useSearchQuery from "../../hooks/useSearchQuery";
import Images from "../../assets/images";
import ProductsList from "../../components/productsList/productsList";
import {useTranslation} from "react-i18next";
import {SEARCH_COMPONENT} from "../../locales/components/namespaces";
import withInfiniteScroll from "../../components/table/withInfiniteScroll";
import UnstructuredTableView from "../../components/table/partials/views/unstructuredTableView";

const ViewInfiniteScroll = withInfiniteScroll(UnstructuredTableView)

const SearchResults = () => {
    const {t} = useTranslation(SEARCH_COMPONENT)
    const {query, setQuery} = useSearchQuery()
    const {getSearchResults} = useProductAction()
    const {searchResultsIndexTable} = useProductData()
    const dataManager = useCallback((pageIndex, pageSize) => {
        getSearchResults(pageIndex, pageSize, query)
    }, [getSearchResults, query])
    return (
        <div className={'bg-container searchResults-page'}>
            <div>
                <img src={Images.pages.search.background} alt="background"/>
            </div>
            <div className={'contained-page'}>
                <Util>
                    <Title variant={"h2"} sup={t('results_2')}>
                        {t('results_1')}
                    </Title>
                </Util>
                <SearchInput
                    query={query}
                    onChange={setQuery}
                />
                <div className={'searchResults-page__meta'}>
                    {searchResultsIndexTable.count} {t('results')}
                </div>
                <ProductsList
                    dataManager={dataManager}
                    data={searchResultsIndexTable}
                    query={query}
                    showCategoryTitle
                    showGoToTop
                    view={ViewInfiniteScroll}
                    noPagination
                />
            </div>
        </div>
    )
}

export default SearchResults;