// @flow
import React, {Fragment} from 'react';
import Button from "../button/button";
import "./createOrder.css"
import useOrderCreate from "../../hooks/order/useOrderCreate";
import ExistingOrderModal from "../existingOrderModal/existingOrderModal";

type Props = {
    showOnlyFirst?: boolean
}

const CreateOrder = (props: Props) => {
    const {showOnlyFirst} = props
    const {
        onCreateButtonClick,
        activeOrderOfType,
        orderTypes,
        showActiveOrderModal,
        setShowActiveOrderModal
    } = useOrderCreate()
    return (
        <Fragment>
            {Boolean(activeOrderOfType) && (
                <div className={'create-order'}>
                    <ExistingOrderModal
                        onClose={() => setShowActiveOrderModal(false)}
                        show={showActiveOrderModal}
                    />
                </div>
            )}
            {orderTypes && orderTypes.length > 0 && (
                showOnlyFirst ? [orderTypes[0]] : orderTypes
            ).map((orderType) => {
                if (!orderType) return null
                return (
                    <Button
                        key={orderType.id}
                        variant={"action"}
                        onClick={() => onCreateButtonClick(orderType.id)}
                    >
                        {orderType.title}
                    </Button>
                )
            })}
        </Fragment>
    )
}

export default CreateOrder;