//@flow
import useOrderAction from "./useOrderAction";
import {updateProductQuantity} from "../../helpers/helpers";
import {useCallback, useEffect} from "react";
import type {Order} from "../../types/order/types";
import type {Product} from "../../types/product/types";
import {useOrderData} from "./useOrderData";

const useActiveOrderUpdate = (skipFetch = false) => {
    const {getActiveOrder} = useOrderAction()
    const {updateOrder, createOrder} = useOrderAction()
    const {creatingOrder} = useOrderData()

    useEffect(() => {
        if (!skipFetch){
            getActiveOrder()
        }
    }, [getActiveOrder, skipFetch])

    const onProductQuantitySelect = useCallback((
        product: Product,
        quantity: number,
        order?: Order,
        orderType?: number
    ) => {
        if (order) {
            const updatedProducts = updateProductQuantity(order.products, product, quantity)
            updateOrder(order.id, {
                ...order,
                products: updatedProducts
            })
        } else if (!creatingOrder) {
            const newProducts = updateProductQuantity([], product, quantity)
            createOrder(orderType, newProducts)
        }
    }, [createOrder, creatingOrder, updateOrder])

    return {onProductQuantitySelect}
}

export default useActiveOrderUpdate