//@flow
import * as ActionTypes from '../../actions/types'
import {fromJS} from "immutable";
import {mapMultiple} from "../../helpers/map";
import _ from "lodash"
import {mapCurrentPayment, mapPayment} from "../../types/payment/map";

const INITIAL_STATE = {
    loading: false,
    byId: {},
    indexTable: {
        loading: false,
        items: [],
        count: 0
    },
    currentPayment: {}
}

function getPayments(state) {
    return state
        .setIn(['indexTable', 'loading'], true)
}

function getPaymentsSucceeded(state, action) {
    const {payload: {count, result}} = action
    const mappedData = mapMultiple(result, mapPayment)
    const sortedKeys = _.orderBy(mappedData, 'date', 'desc').map(i => i.id)
    return state
        .set('byId', state.get('byId').merge(fromJS(mappedData)))
        .setIn(['indexTable', 'count'], parseInt(Number(count)))
        .setIn(['indexTable', 'items'], fromJS(sortedKeys))
        .setIn(['indexTable', 'loading'], false)
}

function getPaymentsFailed(state) {
    return state
        .setIn(['indexTable', 'loading'], false)
}

function getPaymentTicketingSOAP(state) {
    return state
        .set('loading', true)
}

function getPaymentTicketingSOAPSucceeded(state, action) {
    const {payload} = action
    return state
        .set('currentPayment', mapCurrentPayment(payload))
        .set('loading', false)
}

function getPaymentTicketingSOAPFailed(state) {
    return state
        .set('loading', false)
}

const payment = {
    initialState: INITIAL_STATE,
    handlers: {
        [ActionTypes.GET_PAYMENTS]: getPayments,
        [ActionTypes.GET_PAYMENTS_SUCCEEDED]: getPaymentsSucceeded,
        [ActionTypes.GET_PAYMENTS_FAILED]: getPaymentsFailed,
        [ActionTypes.GET_PAYMENT_TICKETING_SOAP]: getPaymentTicketingSOAP,
        [ActionTypes.GET_PAYMENT_TICKETING_SOAP_SUCCEEDED]: getPaymentTicketingSOAPSucceeded,
        [ActionTypes.GET_PAYMENT_TICKETING_SOAP_FAILED]: getPaymentTicketingSOAPFailed,
    }
};

export default payment