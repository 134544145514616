//@flow
import React from 'react';
import classNames from "classnames";
import "./dot.css";

type Props = {
    type?: Number
};

const Dot = (props: Props) => {
    const {type} = props
    return (
        <span className={classNames("dot", {["dot--" + type]: Boolean(type)})}/>
    )
}

export default Dot;