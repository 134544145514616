//@flow
import React, {useEffect, useState} from 'react';
import {colors} from "../colors";
import Title from "../../title/title";
import BarChart from "../components/barChart/barChart";
import Util from "../../util/util";
import Divider from "../../divider/divider";
import moment from "moment";
import useUserAction from "../../../hooks/user/useUserAction";
import {useUserData} from "../../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import {PAGE_REPORTS} from "../../../locales/pages/namespaces";
import {categorizeProducts} from "../../../helpers/helpers";

const defaultDate1 = moment().endOf('quarter').toDate()
const defaultDate2 = moment().startOf('quarter').toDate()
const defaultDate3 = moment().subtract(1, 'quarter').endOf('quarter').toDate()
const defaultDate4 = moment().subtract(1, 'quarter').startOf('quarter').toDate()

const ComparableElements = ({selectedStore}) => {
    const {t} = useTranslation(PAGE_REPORTS)
    const {getReport3Info} = useUserAction()
    const {report3IndexTable} = useUserData()
    const [ranges,] = useState({
        range1: [defaultDate4, defaultDate3],
        range2: [defaultDate2, defaultDate1],
    })
    useEffect(() => {
        getReport3Info(0, 100, ranges, selectedStore)
    }, [getReport3Info, ranges, selectedStore])
    const categorizedProducts = categorizeProducts(report3IndexTable.items)
    const dataSet = Object.keys(categorizedProducts).map(category => {
        const data = categorizedProducts[category]
        return ({
            label: category !== "null" ? category : t('NO CATEGORY'),
            period1: data.reduce((p, n) => p + n.period1.price, 0),
            period2: data.reduce((p, n) => p + n.period2.price, 0)
        })
    })
    const data = {
        labels: dataSet.map(i => i.label),
        datasets: [
            {
                label: t('1st Period'),
                data: dataSet.map(i => i.period1),
                backgroundColor: colors[2],
            },
            {
                label: t('2nd Period'),
                data: dataSet.map(i => i.period2),
                backgroundColor: colors[0],
            },
        ],
    }
    return (
        <div>
            <Util>
                <Title>
                    {t('comparable_1')}
                </Title>
                <Title defaultFont variant={'body'}>
                    {t('comparable_2')}
                </Title>
            </Util>
            <Divider/>
            <BarChart
                data={data}
            />
        </div>
    )
}

export default ComparableElements