//@flow
import React, {Fragment, useCallback} from 'react';
import Util from "../../components/util/util";
import Title from "../../components/title/title";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_PRODUCT_CATEGORY, ROUTE_PAGE_PRODUCT_CATEGORY_PARENT} from "../../routers/routes";
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import {CLONE_ORDER_COMPONENT} from "../../locales/components/namespaces";
import useProductCategoryDetailsData from "../../hooks/product/useProductCategoryDetailsData";
import {useScrollSpy} from "./useScrollSpy";

const Breadcrumb = () => {
    const {t} = useTranslation(CLONE_ORDER_COMPONENT)
    const history = useHistory()
    const {all} = useProductCategoryDetailsData()
    useScrollSpy(240)
    const goToTop = useCallback(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [])
    const navigate = (to) => {
        history.push(to)
        goToTop()
    }
    return (
        <Util align={'flex-start'}>
            <Util align={'flex-start'}>
                <Title
                    variant={"h3"}
                    disabled
                    onClick={() => {
                        navigate(getRouteUrl(ROUTE_PAGE_PRODUCT_CATEGORY_PARENT, {
                            id: 'all',
                        }))
                    }}>
                    {t('view_all_global')}
                </Title>
                <Title variant={'h3'} disabled style={{margin: '0 .5rem'}}>
                    /
                </Title>
                {all
                    .map(p => (
                        <Fragment key={'parent_' + p.id}>
                            <div data-scrollspy-id={'p-' + p.id}>
                                <Title
                                    onClick={() => {
                                        navigate(getRouteUrl(ROUTE_PAGE_PRODUCT_CATEGORY_PARENT, {
                                            id: p.id,
                                        }))
                                    }}
                                    disabled={Boolean(p?.children?.length)}
                                    variant={'h3'}
                                >
                                    {p.title}
                                </Title>
                            </div>
                            {p.children && p.children.length > 0 &&
                                p.children
                                    .map((c) => (
                                        <Fragment key={'child_' + c.id}>
                                            <div data-scrollspy-id={'c-' + c.id}>
                                                <Title variant={'h3'} disabled style={{margin: '0 .5rem'}}>
                                                    /
                                                </Title>
                                                <Title
                                                    onClick={() => {
                                                        navigate(getRouteUrl(ROUTE_PAGE_PRODUCT_CATEGORY, {
                                                            id: p.id,
                                                            subCategoryId: c.id,
                                                        }))
                                                    }}
                                                    variant={'h3'}
                                                >
                                                    {c.title}
                                                </Title>
                                            </div>
                                        </Fragment>
                                    ))}
                        </Fragment>
                    ))}
            </Util>
        </Util>
    )
}

export default Breadcrumb;