import type {Banner} from "./type";

export const parseBanner: Banner = (datum, language) => {
    if (!datum) return;
    return {
        id: datum.get('id'),
        image: datum.get('image'),
        textColor: datum.get('textColor'),
        weight: datum.get('weight'),
        buttonLink: datum.get('buttonLink'),
        description: datum.getIn(['description', language]),
        title: datum.getIn(['title', language]),
        subtitle: datum.getIn(['subtitle', language]),
        buttonText: datum.getIn(['buttonText', language]),
    }
}
