// @flow
import React from 'react'
import InfiniteScroll from "react-infinite-scroll-component";

function withInfiniteScroll(WrappedComponent) {
    return class extends React.Component {
        render() {
            const {
                table: {
                    page,
                    nextPage,
                    canNextPage
                }
            } = this.props
            return (
                <InfiniteScroll
                    dataLength={page.length}
                    next={nextPage}
                    hasMore={canNextPage}
                    loader={null}
                    scrollThreshold={0.75}
                >
                    <WrappedComponent
                        usingInfiniteScroll={true}
                        {...this.props}
                    />
                </InfiniteScroll>
            )
        }
    }
}

export default withInfiniteScroll