import React, {useEffect, useState} from "react";
import {withPermissionHOC} from "../../modules/permission/components/permissionHOC/permissionHOC";
import {Permissions} from "../../helpers/constants";
import Util from "../../components/util/util";
import {useTranslation} from "react-i18next";
import {PAGE_ADMIN_ADDRESSES} from "../../locales/pages/namespaces";
import Title from "../../components/title/title";
import Divider from "../../components/divider/divider";
import {useUserData} from "../../hooks/user/useUserData";
import useUserAction from "../../hooks/user/useUserAction";
import List from "../../components/list/list";
import ListItem from "../../components/list/partials/listItem/listItem";
import ListItemTitle from "../../components/list/partials/listItemTitle/listItemTitle";
import Checkbox from "../../components/form/elements/checkbox/checkbox";
import SearchInput from "../../components/searchInput/searchInput";
import {normalizeGreek} from "../../helpers/helpers";
import {useHasPermission} from "../../modules/permission/hooks/useHasPermission";

const AdminAddresses = () => {
    const {t} = useTranslation(PAGE_ADMIN_ADDRESSES)
    const {sortedStores, selectedStore} = useUserData()
    const {setUserStore} = useUserAction()
    const [query, setQuery] = useState('')
    const [stores, setStores] = useState(sortedStores)
    const isAdmin = useHasPermission([Permissions.AccessAdmin], {shouldView: true})

    useEffect(() => {
        const updateStores = sortedStores.filter(store => {
            const {customerErpCode, erpCode, address, countryCode, ciIdCode} = store
            const search = normalizeGreek(`${customerErpCode}-${erpCode} / ${address} / ${countryCode} / ${ciIdCode}`.toLocaleLowerCase())
            return search.includes(normalizeGreek(query.toLocaleLowerCase()))
        })
        setStores(query.trim() === '' ? [...sortedStores] : updateStores )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    return (
        <div className={'contained-page bg-container'}>
            <Util>
                <Title variant={"h3"}>
                    {t(isAdmin ? 'title_admin' : 'title_salesPerson')}
                </Title>
            </Util>
            <Divider/>
            <br/>
            <div className={"table-wrapper"}>
                <Util className={'filter-wrapper'} style={{marginBottom: '1rem'}}>
                    <SearchInput
                        placeholder={t('search_address')}
                        query={query}
                        onChange={setQuery}
                        filled
                    />
                </Util>
                <List>
                    {stores.map(store => {
                        const {address, id, customerErpCode, erpCode, countryCode, ciIdCode} = store
                        return (
                            <ListItem key={id} style={{padding: '4px 0'}}>
                                <Util className={'util--justify-initial util--align-center'}>
                                    <Checkbox
                                        id={'selectStore'}
                                        name={'selectStore'}
                                        checked={id === selectedStore.id}
                                        onChange={() => setUserStore(id) }
                                    />
                                    <ListItemTitle variant={'body'} style={{marginBottom: '0'}}>
                                        {`${customerErpCode}-${erpCode} / ${address} / ${countryCode} / ${ciIdCode}`.toUpperCase()}
                                    </ListItemTitle>
                                </Util>
                            </ListItem>
                        )
                    })}
                </List>
            </div>
        </div>
    )
}

export default withPermissionHOC(AdminAddresses, [Permissions.AccessAdmin, Permissions.AccessSalesPerson])