import * as ActionTypes from '../../actions/types'
import {mapMultiple, preserveFieldsIfNotPresentInNew} from "../../helpers/map";
import {fromJS} from "immutable";
import {mapSalesPerson} from "../../types/salesPerson/map";

const INITIAL_STATE = {
    loading: false,
    byId: {},
}

function getSalesPersonUnderlings(state) {
    return state
        .set('loading', true)
}

function getSalesPersonUnderlingsSucceeded(state, action) {
    const {payload} = action
    const mappedData = mapMultiple(payload, mapSalesPerson)
    return state
        .set('loading', false)
        .set('byId', fromJS(mappedData))
}

function getSalesPersonUnderlingsFailed(state, action) {
    return state
        .set('loading', false)
}

const salesPerson = {
    initialState: INITIAL_STATE,
    handlers: {
        [ActionTypes.GET_SALES_PERSON_UNDERLINGS]: getSalesPersonUnderlings,
        [ActionTypes.GET_SALES_PERSON_UNDERLINGS_SUCCEEDED]: getSalesPersonUnderlingsSucceeded,
        [ActionTypes.GET_SALES_PERSON_UNDERLINGS_FAILED]: getSalesPersonUnderlingsFailed,
    }
}

export default salesPerson
