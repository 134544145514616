import React from "react";
import "./backgroundLoader.css";

const BackgroundLoader = () => {
    return (
        <div className="spinner-container">
            <div className="loading-spinner">
            </div>
        </div>
    );
}

export default BackgroundLoader