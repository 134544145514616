//@flow
import {useCallback} from 'react'
import {useDispatch} from 'react-redux'
import * as actions from '../../actions/actions'
import {getPaymentTicketingSOAP} from "../../actions/actions";
import {useUserData} from "../user/useUserData";

const usePaymentAction = (range) => {
    const dispatch = useDispatch()
    const {selectedStore, language, data} = useUserData()
    const getPayments = useCallback((pageIndex, pageSize) => {
        dispatch(actions.getPayments({
                requiresCounts: true,
                skip: pageIndex * pageSize,
                take: pageSize,
                ...(range && range[0] && range[1] ? {
                    from: range[0],
                    to: range[1],
                } : {}),
            })
        )
    }, [dispatch, range])

    const getPaymentTicketingSOAP = useCallback((values) => {
        dispatch(actions.getPaymentTicketingSOAP({
            amount: values?.amount,
            erpCode: data.erpCode,
            AddressId: selectedStore.id
        }),[dispatch, values])
    })

    return {
        getPayments,
        getPaymentTicketingSOAP
    }
}

export default usePaymentAction