export const TABLE_COMPONENT = 'cp_table';
export const SEARCH_COMPONENT = 'cp_search';
export const HEADER_COMPONENT = 'cp_header';
export const FOOTER_COMPONENT = 'cp_footer';
export const CREATE_ORDER_COMPONENT = 'cp_create_order';
export const CLONE_ORDER_COMPONENT = 'cp_create_template_order';
export const PRODUCT_COMPONENT = 'cp_product';
export const DELETE_CONFIRMATION = 'cp_delete_confirmation';
export const CUSTOMER_INFO = 'cp_customer_info';
export const BANNER = 'cp_banner';
export const PAYMENT_CONFIRMATION = 'cp_payment_confirmation'