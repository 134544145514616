//@flow
import React from 'react';
import classNames from "classnames";

const Corner = ({fill}) => {
    return (
        <svg
            width="237" height="138" viewBox="0 0 237 138"
            xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M237 137.092V0H0.709473C1.62248 0.326035 2.53483 0.653362 3.44687 0.980578C10.7249 3.59174 17.9832 6.19583 25.3969 8.07979C38.4464 11.3596 51.0296 15.6833 63.7664 20.0597C66.3604 20.9511 68.9608 21.8446 71.5728 22.7319C74.3532 23.6798 77.1589 24.5804 79.9674 25.4819C89.4382 28.5219 98.9414 31.5724 107.618 36.4805C113.208 39.6283 118.871 42.6303 124.531 45.6304C131.161 49.1446 137.785 52.6562 144.281 56.3964C145.616 57.1643 146.972 57.8796 148.337 58.5994C151.056 60.0336 153.81 61.4858 156.495 63.4082C160.063 65.9622 161.683 68.6546 163.745 72.0836C164.014 72.5309 164.291 72.9907 164.581 73.4644C170.303 82.5394 177.417 90.6226 185.652 97.4066C187.992 99.403 190.146 101.608 192.295 103.807C194.416 105.978 196.53 108.143 198.812 110.095C201.874 112.782 205.049 115.349 208.225 117.918C211.595 120.642 214.966 123.368 218.205 126.239C221.37 129.038 228.605 132.894 237 137.092Z"
                  fill={fill}
            />
        </svg>
    )
}

const PreviewLabel = (props: Props) => {
    const {label, onClick, showCorner} = props
    return (
        <div
            onClick={onClick}
            className={classNames('preview__label', {
                'preview__label--corner': showCorner
            })}
        >
            <Corner fill={label.backgroundColor}/>
            <span
                style={{
                    backgroundColor: label.backgroundColor,
                    color: label.color
                }}
            >{label.description}</span>
        </div>
    )
}

export default PreviewLabel;