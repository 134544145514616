//@flow
import {createSelector} from 'reselect'
import {parseMultiple} from "../../helpers/parse";
import {useSelector} from "react-redux";
import type {Banner} from "../../types/banner/type";
import {parseBanner} from "../../types/banner/parse";
import _ from "lodash";

const selectLoading = createSelector(
    (state) => state.banner.get('loading'),
    (item) => item
)

const sortBanners = _.memoize((items) => _.orderBy(items, ['weight'], ['desc']))

const selectItems = createSelector(
    [
        (state) => state.banner.get('byId'),
        (state) => state.currentUser.get('language'),
    ],
    (byId, userLanguage) => sortBanners(
        parseMultiple(byId, (i) => parseBanner(i, userLanguage))
    )
)


export const useBannerData = () => {
    const loading: boolean = useSelector(selectLoading)
    const banners: Array<Banner> = useSelector(selectItems)
    return {
        loading,
        banners
    }
}