import * as Namespaces from './namespaces'

const locales = {
    [Namespaces.HEADER_COMPONENT]: {
        el: {
            loading: "Φόρτωση",
            menu: 'ΜΕΝΟΥ',
            news: 'ΕΝΗΜΕΡΩΣΕΙΣ',
            orders: 'ΠΑΡΑΓΓΕΛΙΕΣ',
            products: 'ΠΡΟΙΟΝΤΑ',
            reports: 'ΑΝΑΦΟΡΕΣ',
            store: 'ΚΑΤΑΣΤΗΜΑ',
            logout: 'ΕΞΟΔΟΣ',
            payments_1: 'ONLINE',
            payments_2: 'ΠΛΗΡΩΜΕΣ',
            admin_addresses: 'ΔΙΕΥΘΥΝΣΕΙΣ'
        },
        en: {
            loading: "Loading",
            menu: 'MENU',
            news: 'NEWS',
            latest: 'LATEST',
            orders: 'ORDERS',
            products: 'PRODUCTS',
            reports: 'REPORTS',
            store: 'STORE',
            logout: 'LOGOUT',
            payments_1: 'ONLINE',
            payments_2: 'PAYMENTS',
            admin_addresses: 'ADDRESSES'
        },
    },
    [Namespaces.FOOTER_COMPONENT]: {
        el: {
            copyright: 'Copyright 2021 Coffee Island Με την επιφύλαξη παντός νόμιμου δικαιώματος.',
            terms: 'ΟΡΟΙ ΧΡΗΣΗΣ',
            'Design by': 'Σχεδιάστηκε από την',
            'Developed by': 'Αναπτύχθηκε από την'
        },
        en: {
            copyright: 'Copyright 2021 Coffee Island All Rights Reserved.',
            terms: 'TERMS OF USE',
        },
    },
    [Namespaces.TABLE_COMPONENT]: {
        el: {
            Show: "Εμφάνιση",
            Showing: "Εμφάνιση",
            results: 'αποτελέσματα',
            Loading: "Φόρτωση",
            'per page': "ανα σελίδα",
            'Go to page': 'Πήγαινε στην σελίδα',
            'Page': 'Σελίδα',
            'of': 'από',
            'no_results': 'Δεν υπάρχουν αποτελέσματα',
            'All': 'Όλα',
            'search': 'Αναζήτηση σε {{count}} εγγραφές',
            'min': 'Ελάχιστη τιμή {{min}}',
            'max': 'Μέγιστη τιμή {{max}}',
            'to': 'έως',
        },
        en: {
            'min': 'Minimum value {{min}}',
            'max': 'Maximum value {{max}}',
            'no_results': 'No Results',
            'search': 'Search {{count}} records',
        }
    },
    [Namespaces.SEARCH_COMPONENT]: {
        el: {
            search: "Αναζήτηση Κωδικού Προϊόντος",
            search_local: "Αναζήτηση προϊόντων στη {{orderType}}",
            results_1: 'ΑΠΟΤΕΛΕΣΜΑΤΑ',
            results_2: 'ΑΝΑΖΗΤΗΣΗΣ',
            results: 'ΑΠΟΤΕΛΕΣΜΑΤΑ',
        },
        en: {
            search: "Search Product Code",
            search_local: "Search products in {{orderType}}",
            results_1: 'SEARCH',
            results_2: 'RESULTS',
            results: 'RESULTS',
        }
    },
    [Namespaces.PRODUCT_COMPONENT]: {
        el: {
            round_error: 'Η ποσότητα στην οποία μπορείτε να παραγγείλετε αυτό το προϊόν πρέπει να είναι πολλαπλάσιο του {{step}}',
            create_error: 'Δημιουργούμε την παραγγελία σας, παρακαλώ περιμένετε, μέχρις ότου το μήνυμα αυτό να εξαφανιστεί.',
            availability_status_0: 'Διαθέσιμο',
            availability_status_1: 'Περιορισμένη Διαθεσιμότητα',
            availability_status_2: 'Μη διαθέσιμο',
            i_want_2: 'TO',
            i_want_1: 'ΘΕΛΩ',
            FAVORITE: 'ΑΓΑΠΗΜΕΝΟ',
            'Retail Price': 'Λιανική Τιμή',
            Package: 'Συσκευασία',
            Quantity: 'Ποσότητα',
            without_tax: 'Χωρίς ΦΠΑ',
            with_tax: 'Με ΦΠΑ',
            total_cost: 'ΣΥΝΟΛΙΚΗ ΑΞΙΑ',
            order: 'ΠΑΡΑΓΓΕΛΙΑ',
            product: 'Προϊόν',
            products: 'Προϊόντα',
            description: 'ΠΕΡΙΓΡΑΦΗ',
            view: 'ΠΡΟΒΟΛΗ',
            similar: 'ΣΧΕΤΙΚΑ',
            loading: 'Φόρτωση',
            'With Discount': 'Με έκπτωση',
            'Added': 'Έχετε προσθέσει',
            'Remove': 'Αφαίρεση',
            'Select An Order': 'Επιλέξτε Παραγγελία',
            'Select An Order Type': 'Επιλέξτε Τύπο Παραγγελίας',
            'Not Allowed': 'Μη επιτρεπόμενη ενέργεια',
            not_allowed_message: 'Λυπούμαστε δεν μπορείτε να παραγγείλετε το συγκεκριμένο είδος καθώς είναι διαθέσιμο μόνο για συγκεκριμένο τύπο παραγγελιών.',
            select_order_message: 'Το προϊόν μπορεί να προστεθεί σε περισσότερες από μία ανοικτές παραγγελίες, παρακαλούμε επιλέξτε σε ποια παραγγελία θέλετε να το προσθέσετε.',
            select_order_type_message: 'Δεν υπάρχει ανοικτή παραγγελία. Παρακαλούμε δημιουργήστε παραγγελία χρησιμοποιώντας ένα από τα παρακάτω κουμπιά.',
            discount: 'ΕΚΠΤΩΣΗ'
        },
        en: {
            write_your_message: 'Write your message...',
            create_error: 'We are creating your order, please wait until this message disappears.',
            round_error: 'The quantity that you can order this product must be a multiple of {{step}}',
            availability_status_0: 'Available',
            availability_status_1: 'Limited stock',
            availability_status_2: 'Out of stock',
            description: 'DESCRIPTION',
            similar: 'SIMILAR',
            view: 'VIEW',
            total_cost: 'TOTAL COST',
            i_want_1: 'I WANT IT',
            i_want_2: '',
            without_tax: 'Without ΤΑΧ',
            with_tax: 'With ΤΑΧ',
            order: 'ORDER',
            product: 'Product',
            products: 'Products',
            loading: 'Loading',
            not_allowed_message: 'Sorry, you can not order this item as it is only available for specific order types.',
            select_order_message: 'You can add this product to more than one active order. Please select the order you wish to add this product into.',
            select_order_type_message: 'There is not an active order. Please create a new order using one of the buttons bellow.',
            discount: 'DISCOUNT'
        }
    },
    [Namespaces.CREATE_ORDER_COMPONENT]: {
        el: {
            or: 'ή',
            pending_order: 'Έχετε εκκρεμή παραγγελία',
            create_order: 'Δημιουργία νέας παραγγελίας',
            create: 'ΔΗΜΙΟΥΡΓΙΑ',
            order: 'ΠΑΡΑΓΓΕΛΙΑΣ',
            week_order: 'ΕΥΔΟΜΑΔΙΑΙΑΣ ΠΑΡΑΓΓΕΛΙΑΣ',
            change: 'ΜΕΤΑΒΟΛΗ',
            view: 'ΠΡΟΒΟΛΗ',
            return: 'ΕΠΙΣΤΡΟΦΗ',
            guarantee_items: 'ΕΙΔΩΝ ΕΓΓΥΟΔΟΣΙΑΣ'
        },
        en: {
            or: 'or',
            pending_order: 'You have a pending order',
            create_order: 'Create new order',
            create: 'CREATE',
            order: 'ORDER',
            week_order: 'WEEKLY ORDER',
            change: 'CHANGE',
            view: 'VIEW',
            return: 'RETURN OF',
            guarantee_items: 'GUARANTEE ITEMS'
        }
    },
    [Namespaces.CLONE_ORDER_COMPONENT]: {
        el: {
            search_results: 'ΑΠΟΤΕΛΕΣΜΑΤΑ ΑΝΑΖΗΤΗΣΗΣ',
            loading: 'Φόρτωση',
            cancel: 'ΑΚΥΡΩΣΗ',
            create: 'ΔΗΜΙΟΥΡΓΙΑ',
            view: 'ΠΡΟΒΟΛΗ',
            view_all: 'ΟΛΑ ΤΑ ΠΡΟΙΟΝΤΑ ΤΗΣ ΚΑΤΗΓΟΡΙΑΣ',
            view_all_global: 'ΟΛΑ ΤΑ ΠΡΟΙΟΝΤΑ',
            required: 'Το πεδίο τίτλος είναι απαραίτητο',
            enter_title_message: 'Παρακαλούμε εισάγετε έναν τίτλο για το template',
            enter_title: 'Τίτλος',
            created_successfully_template: 'Το template σας δημιουργήθηκε με επιτυχία',
            created_successfully: 'Μια νέα παραγγελία από την τρέχουσα δημιουργήθηκε με επιτυχία',
            BACK: 'ΠΙΣΩ'

        },
        en: {
            search_results: 'SEARCH RESULTS',
            loading: 'loading',
            cancel: 'CANCEL',
            create: 'CREATE',
            view: 'VIEW',
            view_all: 'ALL PRODUCTS FOR',
            view_all_global: 'ALL PRODUCTS',
            required: 'Field title is required',
            enter_title_message: 'Please enter a title for this template',
            created_successfully_template: 'Your template was created successfully',
            created_successfully: 'A new order based on this order was created successfully',
            enter_title: 'Title',
            BACK: 'BACK'

        }
    },
    [Namespaces.DELETE_CONFIRMATION]: {
        el: {
            delete: 'ΔΙΑΓΡΑΦΗ',
            cancel: 'ΑΚΥΡΟ',
        },
        en: {
            delete: 'DELETE',
            cancel: 'CANCEL',
        },
    },
    [Namespaces.PAYMENT_CONFIRMATION]: {
        el: {
            submit_payment: 'ΥΠΟΒΟΛΗ ΠΛΗΡΩΜΗΣ',
            cancel: 'ΑΚΥΡΟ',
            required: 'Το πεδίο ποσό είναι απαραίτητο',
            Amount: 'Ποσό Πληρωμής',
            failed_to_process_payment: 'ΑΔΥΝΑΜΙΑ ΕΚΤΕΛΕΣΗΣ ΣΥΝΑΛΛΑΓΗΣ',
            failed_to_process_payment_check_your_card_try_later: 'ΑΔΥΝΑΜΙΑ ΕΚΤΕΛΕΣΗΣ ΣΥΝΑΛΛΑΓΗΣ. ΠΑΡΑΚΑΛΩ ΕΛΕΓΞΕΤΕ ΤΑ ΣΤΟΙΧΕΙΑ ΤΗΣ ΚΑΡΤΑΣ ΣΑΣ ΚΑΙ ΞΑΝΑΠΡΟΣΠΑΘΗΣΤΕ ΑΡΓΟΤΕΡΑ',
            failed_to_process_payment_try_later: 'ΑΔΥΝΑΜΙΑ ΕΚΤΕΛΕΣΗΣ ΣΥΝΑΛΛΑΓΗΣ. ΠΑΡΑΚΑΛΩ ΞΑΝΑΠΡΟΣΠΑΘΗΣΤΕ ΑΡΓΟΤΕΡΑ',
            bank_declined_transaction: 'Η ΣΥΝΑΛΛΑΓΗ ΑΠΟΡΡΙΦθΗΚΕ ΑΠΟ ΤΗΝ ΕΚΔΟΤΡΙΑ ΤΡΑΠΕΖΑ',
            payment_success: 'Η ΠΛΗΡΩΜΗ ΣΑΣ ΟΛΟΚΛΗΡΩΘΗΚΕ ΕΠΙΤΥΧΗΜΕΝΑ',
            'Payment Canceled': 'Η ΣΥΝΑΛΛΑΓΗ ΣΑΣ ΑΚΥΡΩΘΗΚΕ.'
        },
        en: {
            submit_payment: 'SUBMIT PAYMENT',
            cancel: 'CANCEL',
            required: 'Field amount is required',
            Amount: 'Payment Amount',
            failed_to_process_payment: 'FAILED TO PROCESS PAYMENT',
            failed_to_process_payment_check_your_card_try_later: 'FAILED TO PROCESS PAYMENT. PLEASE CHECK YOUR CARD CREDENTIALS TRY AGAIN LATER.',
            failed_to_process_payment_try_later: 'FAILED TO PROCESS PAYMENT. PLEASE TRY AGAIN LATER.',
            bank_declined_transaction: 'BANK DECLINED THE TRANSACTION',
            payment_success: 'YOUR PAYMENT HAS SUCCEEDED',
            'Transaction has failed. Please contact support.': 'TRANSACTION HAS FAILED. PLEASE CONTACT SUPPORT.',
            'Payment Canceled': 'PAYMENT CANCELED.'
        },
    },
    [Namespaces.BANNER]: {
        el: {
            b1_title: "GUATEMALA WAYKAN",
            b1_pre: "ΝΕΟΣ MICROFARM",
            b1_text: "Ο 100% Specialty Arabica καφές Guatemala Waykan, με γεμάτο, σώμα και γλυκιά μακρά επίγευση, δίνει φλιτζάνια γεμάτα αρώματα από σαγκουίνι, ψημένο αμύγδαλο και κρέμα καραμελέ, μια πραγματικά σπάνια εμπειρία καφέ που δε θα ξαναϋπάρξει!",
            b2_pre: " ΠΕΣ ΜΑΣ ΤΗ",
            b2_title: "ΓΝΩΜΗ σου",
            b2_text: "Η Coffee Island ανανέωσε τον τρόπο παραγγελίας σας, με ένα σύγχρονο design που θα διευκολύνει τις αγορές σας, βελτιστοποιημένη πρόσβαση από κινητά, νέες αναφορές και λειτουργίες. Θα θέλαμε να μας δώσετε το σχόλιό σας.",
            b2_cta: "ΕΡΕΥΝΑ ικανοποιησης",
        },
        en: {
            b1_title: "GUATEMALA WAYKAN",
            b1_pre: "NEW MICROFARM",
            b1_text: "The 100% Specialty Arabica coffee Guatemala Waykan, with full, body and sweet long aftertaste, gives cups full of sanguine aromas, roasted almonds and caramel cream, a truly rare coffee experience that will never happen again!",
            b2_pre: "WELCOME TO THE",
            b2_title: "UPDATED ESHOP",
            b2_text: "Coffee Island has renewed the way you order, with a modern design that will facilitate your shopping, optimized mobile access, new reports and features. We would like you to give us your feedback.",
            b2_cta: "your FEEDBACK",
        },
    },
    [Namespaces.CUSTOMER_INFO]: {
        el: {
            'CUSTOMER CARD': 'ΚΑΡΤΕΛΑ ΠΕΛΑΤΗ',
            Loading: 'Φόρτωση',
            name: 'Κωδικός-Επωνυμία',
            vatNumber: 'Α.Φ.Μ.',
            occupation: 'Επάγγελμα',
            contact: 'Υπεύθυνος',
            address: 'Διεύθυνση',
            postalCode: "Τ.Κ.",
            area: 'Περιοχή',
            city: 'Πόλη',
            phone: 'Τηλέφωνο',
            'Transaction Data': 'Στοιχεία Κίνησης',
            'Period Amount': 'Ποσά Περιόδου',
            Progressive: 'Προοδευτικά',
            'PENDING SHIPPING CARDS': 'ΕΚΚΡΕΜΗ ΔΕΛΤΙΑ ΑΠΟΣΤΟΛΗΣ',
            Totals: 'ΓΕΝΙΚΟ ΣΥΝΟΛΟ',
            Reason: 'Αιτιολογία',
            Remain: 'Υπόλοιπο',
            Debit: 'Χρέωση',
            Credit: 'Πίστωση',
            Branch: 'Υποκατάστημα',
            Document: 'Παραστατικό',
            Date: 'Ημ/νία',
            'PRINT': 'ΕΚΤΥΠΩΣΗ',
            'Date Issued': 'Ημ/νία έκδοσης',
            'Pending Quantity': 'Εκκρεμής Ποσότητα',
            'Predicted Pending Value': 'Προβλεπόμενη Εκκρεμής Αξία',
            'Doc. Number': 'Ποσά Περιόδου',
            'Issue Date': 'Ποσά Περιόδου',
            after_message: 'Αγαπητέ συνεργάτη, στην καρτέλα αναφέρονται όλες οι τιμολογήσεις που έχουν γίνει από την εταιρεία μας μέχρι την {{date}} καθώς επίσης οι ποσότητες και οι καθαρές αξίες (χωρίς τον αναλογούντα ΦΠΑ) των εμπορευμάτων που σας έχουν παραδοθεί, αλλά δεν έχουν ακόμα τιμολογηθεί μέχρι την ως άνω (με την επιφύλαξη της μη έγκαιρης αποστολής του συνόλου των σχετικών παραστατικών από τους κατά τόπους συνεργάτες, η αποστολή των οποίων ενδέχεται να μεταβάλλει την παρούσα εικόνα)',
            'Category': 'Κατηγορία',
            'Sub Category': 'Υπο Κατηγορία',
            'Item': 'Είδος',
            'Quantity': 'Ποσότητα',
            'Price': 'Τιμή',
            period_1: '1η ΠΕΡΙΟΔΟΣ',
            period_2: '2η ΠΕΡΙΟΔΟΣ',
            total: 'σύνολο',
        },
        en: {
            period_1: '1st PERIOD',
            period_2: '2nd PERIOD',
            Totals: 'TOTALS',
            name: 'Code-Lastname',
            vatNumber: 'Tax ID',
            occupation: 'Occupation',
            contact: 'Contact',
            address: 'Address',
            postalCode: "Post Code",
            area: 'Area',
            city: 'City',
            phone: 'Telephone',
            after_message: 'Dear partner, this tab lists all the invoices that have been made by our company by {{date}} as well as the quantities and net values (excluding VAT) of the goods that have been delivered to you but haven not yet been invoiced (subject to the untimely sending of all the relevant documents by the local partners, which might affect the current display)'
        },
    }
}

export default locales