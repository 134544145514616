// @flow
import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {TABLE_COMPONENT} from "../../../../../locales/components/namespaces";
import "./numberRangeFilter.css"

export default function NumberRangeFilter({
                                              column: {filterValue = [], preFilteredRows, setFilter, id},
                                          }) {
    const {t} = useTranslation(TABLE_COMPONENT)
    const [min, max] = useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <div className={'ft-range'}>
            <input
                value={filterValue[0] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
                }}
                placeholder={t('min', {min})}
            />
            <input
                value={filterValue[1] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
                }}
                placeholder={t('max', {max})}
            />
        </div>
    )
}
