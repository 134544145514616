//@flow
import React from 'react';
import {useUserData} from "../../hooks/user/useUserData";

const SelectedStoreAdmin = () => {
    const {selectedStore} = useUserData()
    return (
        <div style={{fontSize: '10px'}}>
            <span>{`${selectedStore.customerErpCode}-${selectedStore.erpCode}-${selectedStore.address}`}</span>
        </div>
    )
}

export default SelectedStoreAdmin;