import * as ActionTypes from '../actions/types'
import * as Actions from '../actions/actions'
import {requestCreator} from '../../../helpers/requestCreator'
import {PortalToken} from "../../../helpers/constants";

const getUserPermissions = requestCreator({
    url: '/user/rights',
    method: 'GET',
    onSuccess: Actions.getCurrentUserPermissionsSucceeded,
    onFailure: Actions.getCurrentUserPermissionsFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
        'TokenGuid': PortalToken
    }
    // overrideTask: async () => ({
    //     data: require('./mock/permissions.json')
    // })
})

const requestHandler = {
    [ActionTypes.GET_USER_PERMISSIONS]: getUserPermissions
}

export default requestHandler