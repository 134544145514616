import ProductsOfInterest from "../../pages/productsOfInterest/productsOfInterest";
import { parseLabel, parseProductImage } from "../product/parse";
import type {  } from "./types";
import { parseMultiple } from "../../helpers/parse";

export const parseProductOfInterest: ProductsOfInterest = (datum, language) => {
	if (!datum) return
	const id = datum.get('id')
	return {
		labels: parseMultiple(datum.get('labels'), (i) => parseLabel(i, language)),
		thumbnail: datum.get('thumbnail'),
		weight: datum.getIn(['category', 'weight']),
		description: datum.getIn(['description', language]),
		message: datum.getIn(['message', language]),
		multiplier: datum.get('multiplier'),
		id: id,
		unitOfMeasurement: datum.getIn(['unitOfMeasurement', language]),
		basicUnitOfMeasurement: datum.getIn(['basicUnitOfMeasurement', language]),
		title: datum.getIn(['title', language]),
		images: parseMultiple(datum.get('images'), parseProductImage),
		quantity: datum.get('quantity'),
		comment: datum.get('comment')
	}
}