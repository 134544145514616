// @flow
import React, {Fragment, useEffect, useState} from 'react';
import Button from "../../components/button/button";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_ORDERS} from "../../routers/routes";
import Icon from "../../components/icon/icon";
import Images from "../../assets/images";
import Util from "../../components/util/util";
import Title from "../../components/title/title";
import Divider from "../../components/divider/divider";
import Preview from "../../components/preview/preview";
import BillingAndShippingInfo from "../../components/order/partials/billingAndShippingInfo/billingAndShippingInfo";
import {calculateProductSum, calculateProductTax, categorizeProducts} from "../../helpers/helpers";
import {Trans, useTranslation} from "react-i18next";
import {PAGE_ORDERS} from "../../locales/pages/namespaces";
import useOrderDetailsData from "../../hooks/order/useOrderDetailsData";
import {useParams} from "react-router";
import useOrderAction from "../../hooks/order/useOrderAction";
import {formatDate, formatTypes} from "../../helpers/date";
import OrderStatus from "../../components/orderStatus/orderStatus";
import OrderType from "../../components/orderType/orderType";
import CloneOrderModal from "../../components/cloneOrderModal/cloneOrderModal";
import "./orderPlaced.css"
import {useUserData} from "../../hooks/user/useUserData";
import {useHasPermission} from "../../modules/permission/hooks/useHasPermission";
import {Permissions} from "../../helpers/constants";

const Meta = ({date, statusId, code, type, typeDescription}) => {
    const {t} = useTranslation(PAGE_ORDERS)
    return (
        <div className={'info expand-mobile'}>
            <Util align={'baseline'}>
                <strong>{t('Order Number')}: </strong><span>#{code}</span>
            </Util>
            <Divider thin/>
            <Util align={'baseline'}>
                <strong>{t('Date Placed')}: </strong><span>{formatDate(date, formatTypes.DATE_ONLY)}</span>
            </Util>
            <Divider thin/>
            <Util align={'baseline'}>
                <strong>{t('Status')}: </strong><OrderStatus status={statusId}/>
            </Util>
            <Divider thin/>
            <Util align={'baseline'}>
                <strong>{t('Type')}: </strong>
                <OrderType type={type} typeDescription={typeDescription}/>
            </Util>
        </div>
    )
}
const View = ({order}) => {
    const handlePrint = () => {
        window.print();
    }
    const {t} = useTranslation(PAGE_ORDERS)
    const isAdmin = useHasPermission([Permissions.AccessAdmin], {shouldView: true})
    const isSalesPerson = useHasPermission([Permissions.AccessSalesPerson], {shouldView: true})
    const {products, notes, billing, shipping, id, type, acceptBalance, orderSheetIsInternal} = order
    const categorizedProducts = categorizeProducts(products)
    const sum = calculateProductSum(products).toFixed(2)
    const {data} = useUserData()
    const tax = calculateProductTax(products).toFixed(2)
    const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false)
    return (
        <div id="receipt" className={'order-page bg-container contained-page'}>
            <Button variant={'link'} component={Link} to={getRouteUrl(ROUTE_PAGE_ORDERS)}>
                <Icon icon={Images.icons.arrowLeft}/> {t('back_to_home')}
            </Button>
            <Util align={'flex-start'}>
                <div>
                    <Title>
                        <Trans
                            i18nKey={'thanks'}
                            ns={PAGE_ORDERS}>
                            THANK YOU FOR <br/> YOUR ORDER!
                        </Trans>
                    </Title>
                </div>
                <div className={'hide-mobile'}>
                    <Meta {...order}/>
                </div>
            </Util>
            <div className={'show-mobile'}>
                <br/>
                <Meta {...order}/>
            </div>
            <br/>
            {Object
                .keys(categorizedProducts)
                .map(category => {
                    const products = categorizedProducts[category];
                    return (
                        <Fragment key={category}>
                            <br/>
                            <Title style={{marginBottom: '.5rem'}} variant={"h4"}>
                                {category}
                            </Title>
                            {products.map((product) => (
                                <Preview
                                    key={product.id}
                                    product={product}
                                    disabled
                                    orderType={type}
                                />
                            ))}
                        </Fragment>
                    )
                })}
            <br/>
            <br/>
            <div className="order-page__bottom expand-mobile">
                <div className="order-page__bottom_left hide-mobile">
                    <div className={"order-page__notes"}>
                        <Title variant={"h4"}>
                            {t('notes')}
                        </Title>
                        <div dangerouslySetInnerHTML={{__html: notes}}/>
                    </div>
                    <br/>
                    {billing && shipping && (
                        <BillingAndShippingInfo
                            billing={billing}
                            shipping={shipping}
                        />
                    )}
                </div>
                <div className="order-page__bottom_right expand-mobile">
                    <Util>
                        <Title variant={"h4"}>
                            {t('total_cost')} {t('with_tax')}
                        </Title>
                        <Title variant={"h4"} subtitle={t('tax') + ' (' + tax + data?.currencySymbol + ')'}>
                            {sum}{data?.currencySymbol}
                        </Title>
                    </Util>
                    <Divider spacingTop={1} spacingBottom={1}/>
                    <div className="order-page__bottom_left show-mobile">
                        <div className={"order-page__notes"}>
                            <Title variant={"h4"}>
                                {t('notes')}
                            </Title>
                            <div dangerouslySetInnerHTML={{__html: notes}}/>
                        </div>
                        <br/>
                        {billing && shipping && (
                            <BillingAndShippingInfo
                                billing={billing}
                                shipping={shipping}
                            />
                        )}
                    </div>
                    <div>
                        <Util>
                            {acceptBalance ?
                                <div>
                                    <Icon icon={Images.icons.checked}/>
                                    <span style={{paddingLeft: '.5rem'}}>{t('accept_account_balance')}</span>
                                </div>
                                : null}
                        </Util>
                    </div>
                    <Button.Group>
                        <Button onClick={handlePrint}>
                            {t('print')}
                        </Button>
                        {((orderSheetIsInternal && (isAdmin || isSalesPerson)) || (!orderSheetIsInternal && !isSalesPerson)) && (
                            <Button onClick={() => setShowCreateTemplateModal(true)}>
                                {t('create_template')}
                            </Button>
                        )}
                    </Button.Group>
                </div>
            </div>
            <CloneOrderModal
                createTemplate
                show={showCreateTemplateModal}
                onClose={() => setShowCreateTemplateModal(false)}
                orderId={id}
            />
        </div>
    )
}

const OrderPlaced = () => {
    const {id} = useParams()
    const {order} = useOrderDetailsData(id)
    const {getOrderDetails} = useOrderAction()

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [])

    useEffect(() => {
        getOrderDetails(id)
    }, [getOrderDetails, id])

    if (!order) return null;
    return (
        <View order={order}/>
    )
}

export default OrderPlaced;