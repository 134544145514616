//@flow
import React, {useCallback} from 'react';
import {useUserData} from "../../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import useUserAction from "../../../hooks/user/useUserAction";
import Title from "../../title/title";
import Divider from "../../divider/divider";
import {PAGE_REPORTS} from "../../../locales/pages/namespaces";
import Util from "../../util/util";
import Table from "../../table/table";
import './report4.css'
import {withPermissionHOC} from "../../../modules/permission/components/permissionHOC/permissionHOC";
import {Permissions} from "../../../helpers/constants";

const NumberCell = ({value}) => value && (
    <div style={{textAlign: "right"}}>
        {Number(value).toFixed(2)}
    </div>
)

const OrderItems = ({orderId, onClose}) => {
    const {t} = useTranslation(PAGE_REPORTS)
    const {report4OrderProducts} = useUserData()
    const {getReport4OrderProducts} = useUserAction()
    const dataManager = useCallback((pageIndex, pageSize) => {
        getReport4OrderProducts(pageIndex, pageSize, orderId)
    }, [getReport4OrderProducts, orderId])

    return (
        <div className={'report1-page contained-page bg-container'}>
            <Util>
                <Title variant={"h3"}>
                    {t('ORDER PRODUCTS')}
                </Title>
            </Util>
            <Divider/>
            <div className="table-wrapper">
                <Table
                    disableGroupBy
                    data={report4OrderProducts}
                    noPagination
                    dataManager={dataManager}
                    noMeta
                    customStyle
                    sortBy={[{id: 'title', desc: false}]}
                    columns={[
                        {
                            Header: t('Code'),
                            accessor: 'code',
                            disableGroupBy: true
                        },
                        {
                            Header: t('Title'),
                            accessor: 'title',
                            disableGroupBy: true
                        },
                        {
                            Header: t('Category'),
                            accessor: 'category.title',
                            disableGroupBy: true
                        },
                        {
                            Header: t('Subcategory'),
                            accessor: 'subCategory.title',
                            disableGroupBy: true
                        },
                        {
                            Header: t('Package'),
                            accessor: 'unitOfMeasurement',
                            disableGroupBy: true,
                            Cell: ({value}) => value && (
                                <div>
                                    1 {value}
                                </div>
                            )
                        },
                        {
                            Header: t('Unit Of Measurement'),
                            accessor: 'basicUnitOfMeasurement',
                            disableGroupBy: true,
                            Cell: ({value, row}) => {
                                return value && (
                                    <div>
                                        {row?.original?.multiplier} {value}
                                    </div>
                                )
                            }
                        },
                        {
                            Header: t('Quantity'),
                            accessor: 'quantity',
                            disableGroupBy: true,
                            Cell: NumberCell,
                        },
                        {
                            Header: t('Price Without Tax'),
                            accessor: 'priceWithoutTaxInOrder',
                            disableGroupBy: true,
                            Cell: NumberCell,
                        },
                        {
                            Header: t('Price With Tax'),
                            accessor: 'priceWithTaxInOrder',
                            disableGroupBy: true,
                            Cell: NumberCell,
                        }
                    ]}
                />
            </div>
        </div>
    )
}

export default withPermissionHOC(OrderItems, [Permissions.AccessSalesPerson])
