//@flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {parseMultipleFromReference} from "../../helpers/parse";
import {parsePayment} from "../../types/payment/parse";

const selectLoading = createSelector(
    (state) => state.payment.get('loading'),
    (item) => item
)

const selectIndexTable = createSelector(
    [
        (state) => state.payment.get('indexTable'),
        (state) => state.payment.get('byId'),
    ],
    (indexTable, byId) => ({
        loading: indexTable.get('loading'),
        count: indexTable.get('count'),
        items: parseMultipleFromReference(indexTable.get('items'), byId, parsePayment),
    })
)

const selectCurrentPayment = createSelector(
    (state) => state.payment.get('currentPayment'),
    (item) => item
)

const usePaymentData = () => {
    const loading = useSelector(selectLoading)
    const indexTable = useSelector(selectIndexTable)
    const currentPayment = useSelector(selectCurrentPayment)
    return {
        loading,
        indexTable,
        currentPayment
    }
}

export default usePaymentData