//@flow
import {createSelector} from 'reselect'
import {parseMultiple} from "../../helpers/parse";
import {useSelector} from "react-redux";
import {parseSalesPerson} from "../../types/salesPerson/parse";
import type {SalesPerson} from "../../types/salesPerson/type";
import _ from "lodash";

const selectLoading = createSelector(
    (state) => state.salesPerson.get('loading'),
    (item) => item
)

const selectItems = createSelector(
    [
        (state) => state.salesPerson.get('byId'),
    ],
    (byId) =>
        parseMultiple(byId, (i) => parseSalesPerson(i))
)

export const useSalesPersonData = () => {
    const loading: boolean = useSelector(selectLoading)
    const getSalesPersons: Array<SalesPerson> = useSelector(selectItems)
    const salesPersons = _.sortBy(getSalesPersons, ['name'])

    return {
        loading,
        salesPersons
    }
}