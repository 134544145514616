//@flow
import React, {useContext, useEffect, useRef} from 'react';
import {VirtualizedTableContext} from "./listContainer";

const Row = ({data, index}) => {
    const {page, prepareRow, renderRow, style} = data
    const row = page[index]
    prepareRow(row)
    const {setSize, windowWidth} = useContext(VirtualizedTableContext);
    const root = useRef();
    useEffect(() => {
        if (root.current) {
            setSize(index, root.current.getBoundingClientRect().height);
        }
    }, [root]);
    return (
        <div ref={root} style={style}>
            {renderRow(row, index, page)}
        </div>
    )
}

export default Row;