//@flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import axios from "axios";
import {API} from "../../helpers/constants";
import {useUserData} from "../../hooks/user/useUserData";
import useQueryParams from "../../hooks/useQueryParams";
import Loading from "../../components/loading/loading";
import Modal from "../../components/modal/modal";
import history from "../../store/history";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_ONLINE_PAYMENTS} from "../../routers/routes";
import {useTranslation} from "react-i18next";
import {PAYMENT_CONFIRMATION} from "../../locales/components/namespaces";

const OnlinePaymentsSuccess = () => {
        const [loading, setLoading] = useState(false)
        const {t} = useTranslation(PAYMENT_CONFIRMATION)
        const [open, setOpen] = useState(false)
        const [message, setMessage] = useState({text: '', status: ''})
        const {language, token} = useUserData()
        const queryParams = useQueryParams()
        const params = {}
        for (const [key, value] of queryParams.entries()) {
            if (key !== 'Parameters') params[key] = value
        }
        params['Parameters'] = queryParams.getAll('Parameters')

        useEffect(() => {
            setLoading(true)
            axios({
                method: 'post',
                headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
                url: API + `/${language}/b2b/payments/success`,
                params: params
            }).then((response) => {
                setMessage({text: t('payment_success'), status: 'Success'})
                setLoading(false)
                setOpen(true)
                setTimeout(() => history.push(getRouteUrl(ROUTE_PAGE_ONLINE_PAYMENTS)), 4000)
            }).catch(error => {
                setMessage({text: t('failed_to_process_payment_try_later.'), status: 'Failure'})
                setLoading(false)
                setOpen(true)
                setTimeout(() => history.push(getRouteUrl(ROUTE_PAGE_ONLINE_PAYMENTS)), 4000)
            })
        }, [queryParams])

        return (
            <div className={'bg-container'}>
                <Loading show={loading}/>
                <Modal show={open} onClose={() => setOpen(false)}>
                    <div
                        className={message.status === 'Failure' ? 'error-message' : 'success-message'}
                        style={
                            message.status === 'Failure' ? {
                                padding: '0.5rem 1rem',
                                border: '1px solid #FF0000',
                                margin: '0.5rem 0',
                                color: '#FF0000',
                                fontWeight: '700',
                                fontSize: '16px'
                            } : {
                                padding: '0.5rem 1rem',
                                border: '1px solid #26bc7d',
                                margin: '0.5rem 0',
                                color: '#26bc7d',
                                fontWeight: '700',
                                fontSize: '16px'
                            }
                        }
                    >
                        {message?.text}
                    </div>
                </Modal>
            </div>

        );
    }
;

export default OnlinePaymentsSuccess;