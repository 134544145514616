//@flow
import React, {useEffect} from 'react';
import useUserAction from "../../hooks/user/useUserAction";
import "./languageSelect.css"
import {useUserData} from "../../hooks/user/useUserData";
import {Languages} from "../../helpers/constants";
import {useTranslation} from "react-i18next";

const LanguageSelect = () => {
    const {setUserLanguage} = useUserAction()
    const {language} = useUserData()
    const {i18n} = useTranslation()

    useEffect(() => {
        i18n.changeLanguage(language)
    }, [i18n, language])

    return (
        <div className={'language-select'}>
            {language === Languages.EN && <span onClick={() => setUserLanguage(Languages.EL)}>EL</span>}
            {language === Languages.EL && <span onClick={() => setUserLanguage(Languages.EN)}>EN</span>}
        </div>
    );
};

export default LanguageSelect;