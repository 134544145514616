//@flow
import React, {Fragment} from 'react';
import Modal from "../modal/modal";
import useOrderAction from "../../hooks/order/useOrderAction";
import Button from "../button/button";
import {useTranslation} from "react-i18next";
import TextField from "../form/elements/textfield/textField";
import {CLONE_ORDER_COMPONENT} from "../../locales/components/namespaces";
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import Error from "../error/error";
import {useOrderData} from "../../hooks/order/useOrderData";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_ORDER_EDIT, ROUTE_PAGE_ORDER_TEMPLATE} from "../../routers/routes";
import "./cloneOrderModal.css"

type Props = {
    show: boolean,
    onClose: Function,
    orderId: Function,
    createTemplate: boolean,
    hideActions?: boolean,
};

const CloneOrderModal = (props: Props) => {
    const {t} = useTranslation(CLONE_ORDER_COMPONENT)
    const {createTemplate, orderId, show, onClose, hideActions} = props
    const {cloneOrder} = useOrderAction()
    const {cloneOrder: {error, loading, created}} = useOrderData()

    const onSubmit = (values) => {
        cloneOrder(orderId, values.createTemplate, values.title)
    }

    const validationSchema = yup.object({
        createTemplate: yup.boolean(),
        title: yup
            .string()
            .when("createTemplate", {
                is: true,
                then: (schema) => schema.required(t('required'))
            }),
    })

    const formik = useFormik({
        initialValues: {
            title: '',
            createTemplate: Boolean(createTemplate)
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    })

    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
                <Modal
                    style={{maxWidth: 400}}
                    show={show}
                    onClose={onClose}
                    className={'create-template-modal'}
                >
                    {createTemplate && (
                        <Fragment>
                            <p>
                                {t('enter_title_message')}
                            </p>
                            <TextField
                                name={'title'}
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                placeholder={t('enter_title')}
                            />
                            <ErrorMessage name={'title'} component={Error}/>
                        </Fragment>
                    )}
                    {loading && <div>{t('loading')}...</div>}
                    {error && <Error>{t(error)}...</Error>}
                    {created && createTemplate && (
                        <div className={'success-message'}>
                            {t('created_successfully_template')}
                            <Link
                                to={getRouteUrl(ROUTE_PAGE_ORDER_TEMPLATE, {id: created})}>
                                {t('view')}
                            </Link>
                        </div>
                    )}
                    {created && !createTemplate && (
                        <div className={'success-message'}>
                            {t('created_successfully')}
                            <Link
                                to={getRouteUrl(ROUTE_PAGE_ORDER_EDIT, {id: created})}>
                                {t('view')}
                            </Link>
                        </div>
                    )}
                    {!hideActions && (
                        <Button.Group spacingTop={1}>
                            <Button type={"submit"}>
                                {t('create')}
                            </Button>
                            <Button variant={'link'} onClick={onClose}>
                                {t('cancel')}
                            </Button>
                        </Button.Group>
                    )}
                </Modal>
            </form>
        </FormikProvider>
    )
}

export default CloneOrderModal;