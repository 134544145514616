import {colors} from "../colors";
import {useEffect, useState} from "react";
import moment from "moment";
import {useUserData} from "../../../hooks/user/useUserData";
import axios from "axios";
import {API} from "../../../helpers/constants";
import {formatDate, formatTypes, getMonths} from "../../../helpers/date";
import _ from "lodash";

export const useMySalesData = (selectedStore) => {
    const [data, setData] = useState({
        labels: [],
        datasets: []
    })
    const [range,] = useState([
        moment().subtract(12, 'months').toDate(), new Date()
    ])
    const {stores} = useUserData()
    const {data: userData, language, token} = useUserData()
    useEffect(() => {
        axios({
            method: 'get',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: API + `/${language}/b2b/chart/last-six-orders`,
            params: {
                CodeCust: `'${userData.erpCode}'`,
                ...(selectedStore && selectedStore !== 'all' ? {YpokPelatiCODE: selectedStore.erpCode} : {}),
                From_RegistrationDate: formatDate(range[0], formatTypes.DATE_ONLY_FILTER),
                To_RegistrationDate: formatDate(range[1], formatTypes.DATE_ONLY_FILTER),
            }
        }).then(({data: unmappedData}) => {
            const mappedData = unmappedData.map((datum, index) => {
                const datePart = datum.RegistrationDate.date.split(' ')
                return ({
                    id: index,
                    date: moment(datePart[0], 'YYYY-MM-DD').toDate(),
                    // date: moment.tz(moment(datum.RegistrationDate), datum.RegistrationDate.timezone).toDate(),
                    storeId: datum.YpokPelatiCODE,
                    value: Number(datum.TOTALVALUE1)
                });
            })
            const initialData = getMonths(range[0], range[1])
                .reduce((p, n) => {
                    const name = moment(n).format('MMM, YYYY')
                    return ({
                        ...p,
                        [name]: {
                            name,
                            count: 0
                        }
                    })
                }, {})

            const result = _.map(
                _.groupBy(mappedData, 'storeId'),
                (value, key) => ({
                    storeId: key,
                    // data: _.groupBy(value, i => i.date.getMonth())
                    data: _.values(
                        _.reduce(value, function (result, obj) {
                            const {date, value} = obj
                            const name = moment(date).format('MMM, YYYY')
                            result[name].count += value
                            return result;
                        }, _.cloneDeep(initialData))
                    )
                })
            )
            setData({
                labels: Object.keys(initialData),
                datasets: result.map((storeData, index) => {
                    const store = stores.find(s => s.erpCode === storeData.storeId && s.customerErpCode === userData.erpCode)
                    return ({
                        label: store ? store.address : storeData.storeId,
                        data: storeData.data.map(d => d.count),
                        fill: false,
                        backgroundColor: colors[index],
                        borderColor: colors[index],
                        borderWidth: 1,
                    });
                })
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore])
    return {data}
}