import type {FileType, Message} from "./type";
import {parseMultiple} from "../../helpers/parse";

export const parseFile: FileType = (datum) => {
    if (!datum) return;
    return {
        id: datum.get('id'),
        name: datum.get('name'),
        url: datum.get('url'),
    }
}
export const parseMessage: Message = (datum) => {
    if (!datum) return;
    return {
        id: datum.get('id'),
        date: datum.get('date'),
        text: datum.get('text'),
        title: datum.get('title'),
        category: datum.get('category'),
        files: parseMultiple(datum.get('files'), parseFile),
        actionId: datum.get('actionId'),
        actionTypeId: datum.get('actionTypeId'),
        lastModifiedAt: datum.get('lastModifiedAt'),
        lastModifiedBy: datum.get('lastModifiedBy'),
        needReply: datum.get('needReply'),
        parentId: datum.get('parentId'),
        replyUntilDate: datum.get('replyUntilDate'),
        replyUntilTime: datum.get('replyUntilTime'),
        viewedDate: datum.get('viewedDate'),
        senderId: datum.get('senderId'),
        senderUsername: datum.get('senderUsername'),
        type: datum.get('type'),
        typeId: datum.get('typeId'),
    }
}