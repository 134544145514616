//@flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";

const selectOrders = createSelector(
    [
        (state) => state.order.get('activeOrder'),
    ],
    (i) => i ? i.size : 0,
)

const selectMessages = createSelector(
    [
        (state) => state.message.get('unread'),
    ],
    (i) => i ? i.size : 0,
)

const useMenuCounts = () => {
    const orders = useSelector(selectOrders)
    const messages = useSelector(selectMessages)
    return {
        orders,
        messages,
    }
}

export default useMenuCounts