// @flow
import {useDispatch} from 'react-redux'
import * as actions from '../../actions/actions'
import {useCallback} from "react";
import {useUserData} from "./useUserData";
import {formatDate, formatTypes} from "../../helpers/date";
import {getCurrentUserPermissions} from "../../modules/permission/actions/actions";

const useUserAction = () => {
    const dispatch = useDispatch()
    const {selectedStore, data} = useUserData()

    const userLogin = useCallback((values) => {
        const bodyFormData = new FormData();
        bodyFormData.append('username', values.username);
        bodyFormData.append('password', values.password);
        dispatch(actions.userLogin(bodyFormData))
    }, [dispatch])

    const userLogout = useCallback(() => {
        dispatch(actions.userLogout())
    }, [dispatch])

    const getUserDetails = useCallback((storeId) => {
        dispatch(actions.getUserDetails({
            AddressId: storeId
        }))
    }, [dispatch])

    const getUserStores = useCallback((isLogin = 0) => {
        dispatch(actions.getUserStores())
    }, [dispatch])

    const setUserStore = useCallback((storeId) => {
        dispatch(actions.setUserStore(storeId))
    }, [dispatch])

    const setUserLanguage = useCallback((language) => {
        dispatch(actions.setUserLanguage(language))
    }, [dispatch])

    const getCustomerTabInfo = useCallback((range) => {
        dispatch(actions.getCustomerTabInfo({
            AddressId: selectedStore.id,
            fromDate: formatDate(range[0], formatTypes.DATE_ONLY_FILTER),
            toDate: formatDate(range[1], formatTypes.DATE_ONLY_FILTER),
        }))
    }, [dispatch, selectedStore?.id])

    const getReport1Info = useCallback((pageIndex, pageSize, range, store) => {
        dispatch(actions.getReport1Info({
            CodeCust: `'${data.erpCode}'`,
            ...(store && store !== 'all' ? {YpokPelatiCODE: store.erpCode} : {}),
            From_RegistrationDate: formatDate(range[0], formatTypes.DATE_ONLY_FILTER),
            To_RegistrationDate: formatDate(range[1], formatTypes.DATE_ONLY_FILTER),
        }))
    }, [dispatch, data?.erpCode])

    const getReport2Info = useCallback((pageIndex, pageSize, range, store) => {
        dispatch(actions.getReport2Info({
            CodeCust: `'${data.erpCode}'`,
            ...(store && store !== 'all' ? {YpokPelatiCODE: store.erpCode} : {}),
            From_RegistrationDate: formatDate(range[0], formatTypes.DATE_ONLY_FILTER),
            To_RegistrationDate: formatDate(range[1], formatTypes.DATE_ONLY_FILTER)
        }))
    }, [dispatch, data?.erpCode])

    const getReport3Info = useCallback((pageIndex, pageSize, ranges, store) => {
        const {range1, range2} = ranges
        dispatch(actions.getReport3Info({
            CodeCust: `'${data.erpCode}'`,
            ...(store && store !== 'all' ? {YpokPelatiCODE: store.erpCode} : {}),
            From_RegistrationDate: formatDate(range1[0], formatTypes.DATE_ONLY_FILTER),
            To_RegistrationDate: formatDate(range1[1], formatTypes.DATE_ONLY_FILTER),

            From_RegistrationDate2: formatDate(range2[0], formatTypes.DATE_ONLY_FILTER),
            To_RegistrationDate2: formatDate(range2[1], formatTypes.DATE_ONLY_FILTER),
        }))
    }, [dispatch, data?.erpCode])

    const getReport4Info = useCallback((pageIndex, pageSize, range, salesPersonsIds, sortBy) => {
        dispatch(actions.getReport4Info({
            fromDate: formatDate(range[0], formatTypes.DATE_ONLY_FILTER),
            toDate: formatDate(range[1], formatTypes.DATE_ONLY_FILTER),
            salesPersonsIds: salesPersonsIds,
            skip: pageIndex * pageSize,
            take: pageSize,
            requiresCounts: true,
            // sortBy: sortBy
        }))
    }, [dispatch])

    const getReport4OrderProducts = useCallback((pageIndex, pageSize, orderId) => {
        dispatch(actions.getReport4OrderProducts({
            skip: pageIndex * pageSize,
            take: pageSize,
            requiresCounts: true,
            OrderId: orderId
        }))
    }, [dispatch])

    const getReport5Info = useCallback((pageIndex, pageSize, range, salesPersonsIds, sortBy) => {
        dispatch(actions.getReport5Info({
            fromDate: formatDate(range[0], formatTypes.DATE_ONLY_FILTER),
            toDate: formatDate(range[1], formatTypes.DATE_ONLY_FILTER),
            salesPersonsIds: salesPersonsIds,
            skip: pageIndex * pageSize,
            take: pageSize,
            requiresCounts: true,
        }))
    }, [dispatch])

    const getReport6Info = useCallback((pageIndex, pageSize, range, salesPersonsIds, sortBy) => {
        dispatch(actions.getReport6Info({
            fromDate: formatDate(range[0], formatTypes.DATE_ONLY_FILTER),
            toDate: formatDate(range[1], formatTypes.DATE_ONLY_FILTER),
            salesPersonsIds: salesPersonsIds,
            skip: pageIndex * pageSize,
            take: pageSize,
            requiresCounts: true,
            // sortBy: sortBy
        }))
    }, [dispatch])

    const getReport7Info = useCallback((pageIndex, pageSize, range, salesPersonsIds, sortBy) => {
        dispatch(actions.getReport7Info({
            fromDate: formatDate(range[0], formatTypes.DATE_ONLY_FILTER),
            toDate: formatDate(range[1], formatTypes.DATE_ONLY_FILTER),
            salesPersonsIds: salesPersonsIds,
            skip: pageIndex * pageSize,
            take: pageSize,
            requiresCounts: true,
            // sortBy: sortBy
        }))
    }, [dispatch])

    const getUserPermissions = useCallback((storeId) => {
        dispatch(getCurrentUserPermissions({
            AddressId: storeId
        }))
    }, [dispatch])

    const clearReport4OrderProducts = useCallback(() => {
        dispatch(actions.clearReport4OrderProducts())
    }, [dispatch])

    return {
        userLogin,
        userLogout,
        getUserDetails,
        getUserPermissions,
        getUserStores,
        setUserStore,
        setUserLanguage,
        getCustomerTabInfo,
        getReport1Info,
        getReport2Info,
        getReport3Info,
        getReport4Info,
        getReport4OrderProducts,
        getReport5Info,
        getReport6Info,
        getReport7Info,
        clearReport4OrderProducts
    }
}

export default useUserAction