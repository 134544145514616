//@flow
import * as React from 'react';
import './fab.css'
import Icon from "../icon/icon";
import classNames from "classnames";

type Props = {
    variant: 1 | 2 | 3 | 4 | 5,
    icon: React.Node,
    onClick: Function
};

const Fab = (props: Props) => {
    const {icon, variant, onClick} = props
    return (
        <div
            onClick={onClick}
            className={classNames(
                'fab', {
                    ['fab--variant-' + variant]: Boolean(variant)
                }
            )}>
            <Icon icon={icon}/>
        </div>
    );
};

export default Fab;