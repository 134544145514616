//@flow
import React, {useRef} from 'react';
import './number.css'
import Button from "../../../button/button";

type Props = {
    value?: number,
    min?: number,
    max?: number,
    onChange?: Function,
    onBlur?: Function,
    name?: string,
    id?: string,
};

const Number = (props: Props) => {
    const ref = useRef(null)
    const onButtonClick = (up) => () => {
        if (up) {
            ref.current.stepUp()
        } else {
            ref.current.stepDown()
        }
        const event = new Event('input', {bubbles: true})
        ref.current.dispatchEvent(event)
    }
    return (
        <div className={'number-input'}>
            <Button aria-label={"decrease-input"} onClick={onButtonClick(false)}>-</Button>
            <input
                aria-label={'number-input'}
                ref={ref}
                type="number"
                {...props}
                value={props.value?.toString()}
            />
            <Button aria-label={"increase-input"} onClick={onButtonClick(true)}>+</Button>
        </div>
    );
};

export default Number;