//@flow
import React from 'react';
import Util from "../../components/util/util";
import Select from "react-select";
import useProductCategoryDetailsData from "../../hooks/product/useProductCategoryDetailsData";
import {useTranslation} from "react-i18next";
import {CUSTOMER_INFO} from "../../locales/components/namespaces";
import "./categoryFilter.css"

const customStyles = {
    option: (styles, {isFocused, isSelected}) => {
        return {
            ...styles,
            color: 'black',
            backgroundColor: isSelected ? '#FED33C' : isFocused ? '#FED33C' : styles.backgroundColor,
            ':active': {
                ...styles[':active'],
                backgroundColor: '#FED33C',
            },
        }
    },
    control: (_, {selectProps: {isFocused}}) => ({
        ..._,
        borderColor: 'black',
        '&:hover': {borderColor: 'black'}, // border style on hover
        boxShadow: 'none',
        borderRadius: '0px',
    }),
    indicatorSeparator: (_) => {
        return {
            ..._,
            backgroundColor: 'black'
        }
    },
    dropdownIndicator: (_) => {
        return {
            ..._,
            color: 'black'
        }
    }
}

const CategoryFilter = ({categoryFilter, onChangeCategory, onChangeSubCategory}) => {
    const {t} = useTranslation(CUSTOMER_INFO)
    const {all, loading} = useProductCategoryDetailsData()
    const categoryOptions = all.map(p => ({value: p.id, label: p.title}))
    const subCategoryOptions = all
        .filter(p => p.children && p.children.length > 0)
        .reduce((p, n) => [
                ...p,
                ...n.children.map(c => ({
                    parent: n.id,
                    value: c.id,
                    label: c.title
                }))
            ], []
        )
    const selectedCategory = categoryOptions.find(c => c.value === categoryFilter.categoryId)
    const selectedSubCategory = subCategoryOptions.find(s => (s.value === categoryFilter.subCategoryId))
    return (
        <Util className={'category-dropdown-filter'}>
            <div>
                <Select
                    value={selectedCategory ?? null}
                    onChange={(selectedOption) => {
                        if (!selectedOption){
                            onChangeCategory(null)
                            return
                        }
                        onChangeCategory(selectedOption.value)
                    }}
                    isLoading={loading}
                    isClearable={true}
                    isSearchable={true}
                    styles={customStyles}
                    name="selectCategory"
                    placeholder={t("Category")}
                    options={categoryOptions}
                />
            </div>
            <div>
                <Select
                    value={selectedSubCategory ?? null}
                    onChange={(selectedOption) => {
                        if (!selectedOption){
                            onChangeSubCategory(null, null)
                            return
                        }
                        onChangeSubCategory(selectedOption.parent, selectedOption.value)
                    }}
                    isLoading={loading}
                    isClearable={true}
                    isSearchable={true}
                    styles={customStyles}
                    placeholder={t("Sub Category")}
                    name="selectSubcategory"
                    options={subCategoryOptions}
                />
            </div>
        </Util>
    )
}

export default CategoryFilter;