import React, {Fragment} from 'react';
import {Line} from 'react-chartjs-2';
import {useUserData} from "../../../../hooks/user/useUserData";

type Props = {
    data: {
        labels: Array<string>,
        datasets: Array<{
            label: string,
            data: Array<number>,
            backgroundColor: Array<string>
        }>
    }
}

const LineChart = (props: Props) => {
    const {data} = props
    const {data: userData} = useUserData()
    const currencySymbol = userData ? userData.currencySymbol : ''
    return (
        <Fragment>
            <Line
                options={{
                    plugins: {
                        legend: {
                            labels: {
                                usePointStyle: true,
                                padding: 8
                            },
                            position: 'top',
                        },
                        tooltip: {
                            callbacks: {
                                label: (item) => {
                                    return item.raw.toFixed(2) + currencySymbol;
                                }
                            }
                        }
                    },
                    scales: {
                        y: {
                            ticks: {
                                callback: (value, index, ticks) => currencySymbol + value
                            },
                            beginAtZero: true
                        }
                    }
                }}
                data={data}
                type={'line'}
            />
        </Fragment>
    );
};

export default LineChart;