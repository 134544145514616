// @flow
import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Images from "../../assets/images";
import BackgroundLoader from "../../components/backgroundLoader/backgroundLoader";
import Divider from "../../components/divider/divider";
import Icon from "../../components/icon/icon";
import UnstructuredTableView from "../../components/table/partials/views/unstructuredTableView";
import Table from "../../components/table/table";
import Title from "../../components/title/title";
import Util from "../../components/util/util";
import useProductsOfInterestAction from "../../hooks/productsOfInterest/useProductsOfInterestAction";
import useProductsOfInterestData from "../../hooks/productsOfInterest/useProductsOfInterestData";
import { PAGE_PRODUCTS_OF_INTEREST } from "../../locales/pages/namespaces";
import '../../pages/productCategory/productCategory.css'
import PreviewProduct from "./partials/previewProduct";

type Props = {};

const ProductsOfInterest = (props: Props) => {
	const { t } = useTranslation(PAGE_PRODUCTS_OF_INTEREST)
	const { getProductsOfInterest, storeProductsOfInterest, setMessage } = useProductsOfInterestAction()
	const { loading, message, productsOfInterest } = useProductsOfInterestData()
	useEffect(() => {
		if (message) {
			setTimeout(() => {
				setMessage(null)
			}, 2000)
		}
	}, [message, setMessage])
	return (
		<div className={'bg-container product-category'}>
			<div className={'contained-page'}>
				<div className={'product-category-title'}>
					<Title>
						{t('products_of_interest')}
					</Title>
					<Divider spacingBottom={2}/>
					{message && (
						<div className={'preview__added'} style={{
							marginBottom: '0.4rem',
							color: message?.variant === 'error' ? '#FF0000' : '#26BC7D'
						}}>
							<Icon icon={message?.variant === 'error' ? Images.icons.close : Images.icons.tick}/>
							<Util justifyContent={'initial'} style={{ width: '100%', paddingRight: '.5rem' }}>
								<div>
									<strong>{t(message?.text)}</strong>
								</div>
							</Util>
						</div>
					)}
				</div>
				<Table
					virtualized
					data={productsOfInterest}
					dataManager={getProductsOfInterest}
					columns={[{
						header: '',
						accessor: 'id',
					}]}
					view={UnstructuredTableView}
					noMeta
					noPagination
					renderRow={(row) => {
						return (
							<div key={row.id}>
								<PreviewProduct
									product={row.original}
									storeProductsOfInterest={storeProductsOfInterest}
								/>
							</div>
						)
					}}
				/>
			</div>
			{loading && <BackgroundLoader/>}
		</div>
	);
};

export default ProductsOfInterest