//@flow
import * as React from 'react';
import './icon.css'

type Props = {
    icon: React.Node
};

const Icon = (props: Props) => {
    const {icon: Component} = props
    return (
        <Component/>
    );
};

export default Icon;