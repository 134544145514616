//@flow
import React from 'react';
import './badge.css'

type Props = {
    badgeContent: number
};

const Badge = (props: Props) => {
    const {badgeContent} = props
    return (
        <div className={'badge'}>
            {badgeContent}
        </div>
    );
};

export default Badge;