//@flow
import * as React from 'react';
import classNames from "classnames";
import Button from "../button/button";
import Icon from "../icon/icon";
import Images from "../../assets/images";
import './quickView.css'
import ReactDOM from "react-dom";

type Props = {
    show?: boolean,
    children: React.Node,
    onClose?: Function
};

const QuickView = (props: Props) => {
    const {show, onClose, children} = props
    if (!show) return null;
    return ReactDOM.createPortal(
        <div
            onClick={onClose}
            className={classNames('quickView', {
                'quickView--visible': show
            })}
        >
            <section
                className={'quickView__content'}
                onClick={(e) => {
                    e.stopPropagation()
                }}
            >
                <div className={'quickView__container'}>
                    <div className={'quickView__corner'}>
                        <img src={Images.quickView.corner} alt="background corner"/>
                        <Button variant={"icon"} onClick={onClose}>
                            <Icon icon={Images.icons.close}/>
                        </Button>
                    </div>
                    {children}
                </div>
            </section>
        </div>
    , document.body)
}

export default QuickView;