//@flow
import React from 'react';
import classNames from "classnames";
import './availabilityStatus.css'
import {PRODUCT_COMPONENT} from "../../locales/components/namespaces";
import {useTranslation} from "react-i18next";

type Props = {
    status: string
};

const AvailabilityStatus = (props: Props) => {
    const {t} = useTranslation(PRODUCT_COMPONENT)
    const {status} = props
    return (
        <span className={classNames('availability-status', {
            ['availability-status--' + status]: true
        })}>
            {t('availability_status_'+status)}
        </span>
    );
};

export default AvailabilityStatus;