//@flow
import React from 'react';
import Modal from "../modal/modal";
import Title from "../title/title";
import Button from "../button/button";
import {useTranslation} from "react-i18next";
import {PRODUCT_COMPONENT} from "../../locales/components/namespaces";
import "./selectCreateOrderModal.css"

type Props = {
    types?: Array<{
        id: string,
        title: string,
    }>,
    show?: boolean,
    onClose: Function,
    onSubmit: Function
}

const SelectCreateOrderModal = (props: Props) => {
    const {types, show, onClose, onSubmit} = props
    const {t} = useTranslation(PRODUCT_COMPONENT)

    return (
        <Modal
            show={show}
            onClose={onClose}
            className={'existing-order-modal'}
        >
            {types.length > 0 ? (
                <div>
                    <Title defaultFont variant={'h4'}>
                        {t('Select An Order Type')}
                    </Title>
                    <br/>
                    <p>
                        {t('select_order_type_message')}
                    </p>
                    <br/>
                    <Button.Group>
                        {types.map((orderType) => {
                            if (!orderType) return null
                            return (
                                <Button
                                    key={orderType.id}
                                    variant={"action"}
                                    onClick={() => {
                                        onSubmit(orderType.id)
                                        onClose()
                                    }}
                                >
                                    {orderType.title}
                                </Button>
                            )
                        })}
                    </Button.Group>
                </div>
            ) : (
                <div>
                    <Title defaultFont variant={'h4'}>
                        {t('Not Allowed')}
                    </Title>
                    <p>
                        {t('not_allowed_message')}
                    </p>
                </div>
            )}
        </Modal>
    )
}

export default SelectCreateOrderModal