//@flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import useQueryParams from "../../hooks/useQueryParams";
import {useUserData} from "../../hooks/user/useUserData";
import axios from "axios";
import {API} from "../../helpers/constants";
import Loading from "../../components/loading/loading";
import Modal from "../../components/modal/modal";
import history from "../../store/history";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_ONLINE_PAYMENTS} from "../../routers/routes";
import {useTranslation} from "react-i18next";
import {PAYMENT_CONFIRMATION} from "../../locales/components/namespaces";

const OnlinePaymentsFailure = () => {
    const [loading, setLoading] = useState(false)
    const {t} = useTranslation(PAYMENT_CONFIRMATION)
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')
    const {language, token} = useUserData()
    const queryParams = useQueryParams()
    const params = {}
    for (const [key, value] of queryParams.entries()) {
        if (key !== 'Parameters') params[key] = value
    }
    params['Parameters'] = queryParams.getAll('Parameters')

    useEffect(() => {
        setLoading(true)
        axios({
            method: 'post',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: API + `/${language}/b2b/payments/failure`,
            params: params
        }).then((response) => {
            const bankResultCode = response.data.bankResultCode

            switch (bankResultCode) {
                case '1048':
                    setMessage(t('failed_to_process_payment'))
                    break
                case '981':
                    setMessage(t('failed_to_process_payment_check_your_card_try_later'))
                    break
                case '500':
                case '1045':
                case '1072':
                case '1':
                    setMessage(t('failed_to_process_payment_try_later'))
                    break
                default:
                    setMessage(t('bank_declined_transaction'))
                    break
            }
            setLoading(false)
            setOpen(true)
            setTimeout(() => history.push(getRouteUrl(ROUTE_PAGE_ONLINE_PAYMENTS)), 4000)
        })
    }, [queryParams])

    return (
        <div className={'bg-container'}>
            <Loading show={loading}/>
            <Modal show={open} onClose={() => setOpen(false)}>
                <div className={'error-message'} style={{
                    padding: '0.5rem 1rem',
                    border: '1px solid #FF0000',
                    margin: '0.5rem 0',
                    color: '#FF0000',
                    fontWeight: '700',
                    fontSize: '16px'
                }}>
                    {message}
                </div>
            </Modal>
        </div>
    );
};

export default OnlinePaymentsFailure;