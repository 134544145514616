//@flow
import React, {Fragment, useState} from 'react';
import Util from "../../components/util/util";
import Title from "../../components/title/title";
import Divider from "../../components/divider/divider";
import Button from "../../components/button/button";
import Icon from "../../components/icon/icon";
import Images from "../../assets/images";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_ORDER_TEMPLATE, ROUTE_PAGE_ORDERS} from "../../routers/routes";
import {Link} from "react-router-dom";
import Table from "../../components/table/table";
import {Trans, useTranslation} from "react-i18next";
import {PAGE_ORDERS} from "../../locales/pages/namespaces";
import {useOrderData} from "../../hooks/order/useOrderData";
import useOrderAction from "../../hooks/order/useOrderAction";
import _ from 'lodash'
import DeleteConfirmation from "../../components/deleteConfirmation/deleteConfirmation";

const Templates = () => {
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
    const {t} = useTranslation(PAGE_ORDERS)
    const {getOrderTemplates} = useOrderAction()
    const {deleteOrder} = useOrderAction()
    const {templatesIndexTable} = useOrderData()

    const handleDelete = (id) => {
        deleteOrder(id)
    }

    return (
        <div className={'bg-container bg-container--transparent contained-page'}>
            <Button variant={'link'} component={Link} to={getRouteUrl(ROUTE_PAGE_ORDERS)}>
                <Icon icon={Images.icons.arrowLeft}/> {t('back_to_orders')}
            </Button>
            <Util>
                <Title variant={"h2"} sup={t('templates_2')}>
                    {t('templates_1')}
                </Title>
            </Util>
            <Divider/>
            <br/>
            <Table
                data={templatesIndexTable}
                pageSize={10}
                dataManager={getOrderTemplates}
                noMeta
                noHeader
                columns={[
                    {
                        accessor: 'title',
                        Cell: (props) => {
                            const {row: {original: {title, products}}} = props
                            return (
                                <div>
                                    <strong>{title}</strong> ({_.uniq(products.map(p => p.category.title?.toLowerCase())).join(', ')})
                                </div>
                            );
                        }
                    },
                    {
                        accessor: 'products',
                        Cell: ({value}) => (
                            <div>
                                {value.length} {t('Products')}
                            </div>
                        )
                    },
                    {
                        accessor: (row) => row.id,
                        id: 'view_template',
                        Cell: ({value}) => (
                            <Button
                                variant={'link'}
                                component={Link}
                                to={getRouteUrl(ROUTE_PAGE_ORDER_TEMPLATE, {id: value})}>
                                {t('show')}
                            </Button>
                        )
                    },
                    {
                        accessor: (row) => row.id,
                        id: 'delete_template',
                        Cell: ({value}) => (
                            <Fragment>
                                <Button variant={'link'} onClick={() => setOpenDeleteConfirmation(value)}>
                                    {t('delete')}
                                </Button>
                                <DeleteConfirmation
                                    show={openDeleteConfirmation === value}
                                    onSubmit={() => {
                                        setOpenDeleteConfirmation(null)
                                        handleDelete(value);
                                    }}
                                    onClose={() => setOpenDeleteConfirmation(null)}
                                >
                                    <Trans
                                        i18nKey={'delete_warning_template'}
                                        ns={PAGE_ORDERS}>
                                        You are about to delete this template.<br/>
                                        This action cannot be undone.<br/>
                                        Are you sure you want to delete this template?<br/>
                                    </Trans>
                                </DeleteConfirmation>
                            </Fragment>
                        )
                    },
                ]}
            />
        </div>
    );
};

export default Templates;