//@flow
import * as React from 'react';
import './listItem.css'
import classNames from "classnames";

type Props = {
    children: React.Node,
    style?: Object,
    contained?: boolean,
    className?: string,
    noBorders?: boolean,
};

const ListItem = (props: Props) => {
    const {className, children, style, contained, noBorders} = props
    return (
        <li
            className={classNames('list__item', className, {
                'list__item--contained': contained,
                'list__item--no-borders': noBorders
            })}
            style={style}
        >
            {children}
        </li>
    );
};

export default ListItem