import * as ActionTypes from '../../actions/types'
import {fromJS} from "immutable";
import {mapMultiple, preserveFieldsIfNotPresentInNew} from "../../helpers/map";
import {mapFile, mapMessage} from "../../types/message/map";
import _ from "lodash";

const INITIAL_STATE = {
    loading: false,
    byId: {},
    unread: [],
    indexTable: {
        loading: false,
        items: [],
        count: 0
    },
};

function getMessages(state) {
    return state
        .setIn(['indexTable', 'loading'], true)
}

function getMessagesSucceeded(state, action) {
    const {payload: {count, result}} = action
    const mappedData = mapMultiple(result, mapMessage)
    return state
        .set('loading', false)
        .set('unread', fromJS(Object.keys(_.pickBy(mappedData, (i) => !i.viewedDate))))
        .set('byId', state.get('byId')
            .mergeWith(preserveFieldsIfNotPresentInNew(['files']), fromJS(mappedData)))
        .setIn(['indexTable', 'count'], parseInt(count))
        .setIn(['indexTable', 'items'], fromJS(Object.keys(mappedData).reverse()))
        .setIn(['indexTable', 'loading'], false)
}

function getMessagesFailed(state) {
    return state
        .setIn(['indexTable', 'loading'], false)
}

function setMessageViewed(state) {
    return state
        .set('loading', true)
}

function setMessageViewedSucceeded(state, action) {
    const {parameters: {data: id}} = action
    const now = new Date()
    return state
        .set('loading', false)
        .setIn(['byId', id, 'viewedDate'], now)
}

function setMessageViewedFailed(state, action) {
    return state
        .set('loading', false)
}

function getMessageAttachments(state) {
    return state
        .set('loading', false)
}

function getMessageAttachmentsSucceeded(state, action) {
    const {parameters: {id}, payload: {files}} = action
    return state
        .set('loading', false)
        .setIn(['byId', id, 'files'], fromJS(mapMultiple(files, mapFile)))
}

function getMessageAttachmentsFailed(state, action) {
    return state
        .set('loading', false)
}

const message = {
    initialState: INITIAL_STATE,
    handlers: {
        [ActionTypes.GET_MESSAGES]: getMessages,
        [ActionTypes.GET_MESSAGES_SUCCEEDED]: getMessagesSucceeded,
        [ActionTypes.GET_MESSAGES_FAILED]: getMessagesFailed,
        [ActionTypes.SET_MESSAGE_VIEWED]: setMessageViewed,
        [ActionTypes.SET_MESSAGE_VIEWED_SUCCEEDED]: setMessageViewedSucceeded,
        [ActionTypes.SET_MESSAGE_VIEWED_FAILED]: setMessageViewedFailed,
        [ActionTypes.GET_MESSAGE_ATTACHMENTS]: getMessageAttachments,
        [ActionTypes.GET_MESSAGE_ATTACHMENTS_SUCCEEDED]: getMessageAttachmentsSucceeded,
        [ActionTypes.GET_MESSAGE_ATTACHMENTS_FAILED]: getMessageAttachmentsFailed,

    }
}

export default message