//@flow
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import Button from "../../../components/button/button";
import useMessageAction from "../../../hooks/message/useMessageAction";
import type {FileType} from "../../../types/message/type";
import {PAGE_ANNOUNCEMENTS} from "../../../locales/pages/namespaces";
import {AuthenticatedLink} from "../../../components/authenticatedLink/authenticatedLink";
import Util from "../../../components/util/util";
import Images from "../../../assets/images";
import Icon from "../../../components/icon/icon";

type Props = {
    id: string,
    files: Array<FileType>,
}

const Attachments = (props: Props) => {
    const {t} = useTranslation(PAGE_ANNOUNCEMENTS)
    const {getMessageAttachments} = useMessageAction();
    const {id, files} = props

    useEffect(() => {
        getMessageAttachments(id)
    }, [getMessageAttachments, id])

    return files && files.length > 0 ? (
        <div>
            <div className={'message-attachments-info'}>
                {files.length} {t('attachments')}
            </div>
            <Button.Group size={'xs'}>
                {files.map(f => (
                    <Button
                        key={f.id}
                        url={f.url}
                        filename={f.name}
                        size={'sm'}
                        variant={'link'}
                        component={AuthenticatedLink}
                    >
                        <Util>
                            <Icon icon={Images.icons.paperclip}/>
                            {f.name}
                        </Util>
                    </Button>
                ))}
            </Button.Group>
        </div>
    ) : null
}

export default Attachments