//@flow
import React, {useEffect, useState} from 'react'
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import {useUserData} from "../../hooks/user/useUserData";
import Login from "../login/login";
import moment from "moment";
import 'moment/locale/el'
import useUserAction from "../../hooks/user/useUserAction";
import ContentRouter from "../../routers/contentRouter";
import {useOrderData} from "../../hooks/order/useOrderData";

export const MenuContext = React.createContext(null);

const Main = () => {
    const {getUserDetails, getUserStores, getUserPermissions} = useUserAction()
    const {isLoggedIn, language, selectedStore, data} = useUserData()
    const [openMenu, setOpenMenu] = useState(false)
    const [menuCategoryType, setMenuCategoryType] = useState(null)
    const {activeOrder} = useOrderData()
    const orderTypes = data && data.orderTypes ? data.orderTypes : null
    useEffect(() => {
        moment.locale(language);
    }, [language])

    useEffect(() => {
        if (isLoggedIn) {
            getUserStores()
        }
    }, [getUserStores, isLoggedIn])

    useEffect(() => {
        if (isLoggedIn && selectedStore) {
            getUserDetails(selectedStore)
            getUserPermissions()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUserDetails, isLoggedIn, selectedStore?.id])

    useEffect(() => {
        if (activeOrder && activeOrder.length === 0) {
            if (orderTypes && orderTypes.length > 0) {
                setMenuCategoryType(orderTypes[0].id)
            }
        } else if (activeOrder && activeOrder.length === 1) {
            setMenuCategoryType(activeOrder[0].type)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeOrder?.length, orderTypes])

    if (!(isLoggedIn && selectedStore)) return <Login/>
    return (
        <MenuContext.Provider value={{
            openMenu: openMenu,
            setOpenMenu: setOpenMenu,
            menuCategoryType: menuCategoryType,
            setMenuCategoryType: setMenuCategoryType
        }}>
            <Header/>
            <Header.Mobile/>
            <ContentRouter/>
            <Footer/>
        </MenuContext.Provider>
    )
}

export default Main