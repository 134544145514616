//@flow
import {useSelector} from "react-redux";
import {parseOrder} from "../../types/order/parse";
import {createSelector} from "reselect";

const selectById = createSelector(
    [
        (state) => state.order.get('byId'),
        (state) => state.currentUser.get('language'),
        (state) => state.productCached.get('byId'),
        (state, id) => id
    ],
    (byId, language, productById, id) => {
        return parseOrder(byId.get(id), language, productById)
    }
)

const useOrderDetailsData = (id) => {
    const order = useSelector(state => selectById(state, id))
    return {
        order
    }
}

export default useOrderDetailsData