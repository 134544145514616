// @flow
import React from 'react';
import PieChart from "../components/pieChart/pieChart";
import Title from "../../title/title";
import "./topProducts.css"
import Divider from "../../divider/divider";
import {useTranslation} from "react-i18next";
import {PAGE_REPORTS} from "../../../locales/pages/namespaces";
import {useTopProductData} from "./useTopProductData";
import Util from "../../util/util";

const TopProducts = ({selectedStore}) => {
    const {t} = useTranslation(PAGE_REPORTS)
    const {data} = useTopProductData(selectedStore)
    return (
        <div className={'top-products'}>
            <Util>
                <Title>
                    {t('top_10_products')}
                </Title>
                <Title defaultFont variant={'body'}>
                    {t('last_month')}
                </Title>
            </Util>
            <Divider/>
            <div className={'chart-content'}>
                <PieChart
                    data={data}
                />
            </div>
        </div>
    )
}

export default TopProducts;