import './assets/scss/base.css'
import history from './store/history'
import {ConnectedRouter} from 'connected-react-router'
import {loadModules} from "./store/loadModule";
import Main from "./pages/main/main";
import {PermissionsModule} from "./modules/permission";

export const ENABLED_MODULES = loadModules([PermissionsModule])

export const ROUTES = ENABLED_MODULES.loadRoutes()

function App() {
    return (
        <ConnectedRouter history={history}>
            <Main/>
        </ConnectedRouter>
    )
}

export default App
