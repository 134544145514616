//@flow
import React, {Fragment} from 'react';
import type {Product} from "../../types/product/types";
import useActiveOrderUpdate from "../../hooks/order/useActiveOrderUpdate";
import Table from "../table/table";
import Preview from "../preview/preview";
import Title from "../title/title";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_PRODUCT_CATEGORY, ROUTE_PAGE_PRODUCT_CATEGORY_PARENT} from "../../routers/routes";
import {useHistory} from "react-router";
import ScrollToTop from "react-scroll-to-top";
import "./productList.css"
import Images from "../../assets/images";

type Props = {
    dataManager: Function,
    view: any,
    data: {
        items: Array<Product>,
        loading: boolean,
        count: number,
    },
    showCategoryTitle?: boolean,
    noMeta?: boolean,
    noPagination?: boolean,
    query?: string,
    displayAsTile?: boolean,
    showGoToTop?: boolean
}

const ProductsList = (props: Props) => {
    const history = useHistory()
    const {onProductQuantitySelect} = useActiveOrderUpdate()
    const {virtualized, view, showGoToTop, data, noMeta, noPagination, query, showCategoryTitle, displayAsTile} = props
    return (
        <div className={displayAsTile ? 'display-tiles' : null}>
            {showGoToTop && (
                <ScrollToTop
                    smooth
                    style={{
                        background: "transparent",
                        right: '0rem',
                        bottom: '.5rem',
                        width: '4rem',
                        height: '4rem',
                        boxShadow: "none",
                    }}
                    top={200}
                    component={<Images.GoToTop/>}
                />
            )}
            <Table
                virtualized={virtualized}
                data={data}
                columns={[
                    {
                        header: '',
                        accessor: 'id',
                    }
                ]}
                dataManager={props.dataManager}
                view={view}
                noMeta={noMeta}
                noPagination={noPagination}
                renderRow={(row, index, array) => {
                    const {subCategory, subCategoryId, categoryId, category} = row.original
                    const sameAsPreviousSubCategoryId = index > 0 ?
                        array[index - 1].original.subCategoryId === subCategoryId : false
                    return (
                        <div
                            key={row.id}
                            id={categoryId + "-" + subCategoryId}
                            data-scrollspy={true}
                        >
                            {showCategoryTitle && (
                                <Fragment>
                                    {subCategoryId ? (
                                        <Title
                                            variant={"h4"}
                                            style={{
                                                marginBottom: '.5rem',
                                                marginTop: '1rem',
                                                display: sameAsPreviousSubCategoryId ? 'none' : 'flex',
                                            }}
                                            onClick={() => {
                                                history.push(getRouteUrl(ROUTE_PAGE_PRODUCT_CATEGORY, {
                                                    id: categoryId,
                                                    subCategoryId: subCategoryId
                                                }))
                                            }}
                                        >
                                            {subCategory.title}
                                        </Title>
                                    ) : (
                                        <Title
                                            variant={"h4"}
                                            style={{
                                                marginBottom: '.5rem',
                                                marginTop: '1rem',
                                                display: sameAsPreviousSubCategoryId ? 'none' : 'flex',
                                            }}
                                            onClick={() => {
                                                history.push(getRouteUrl(ROUTE_PAGE_PRODUCT_CATEGORY_PARENT, {
                                                    id: categoryId,
                                                }))
                                            }}
                                        >
                                            {category.title}
                                        </Title>
                                    )}
                                </Fragment>
                            )}
                            <Preview
                                key={row.original.id}
                                product={row.original}
                                query={query}
                                onAdd={onProductQuantitySelect}
                                displayAsTile={displayAsTile}
                            />
                        </div>
                    )
                }}
            />
        </div>
    )
}

export default ProductsList;