//@flow
import * as React from 'react';
import "./error.css"

type Props = {
    children: React.Node
};

const Error = (props: Props) => {
    const {children} = props
    return (
        <span className={'error-message'}>
            {children}
        </span>
    );
};

export default Error;