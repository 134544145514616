// @flow
import classNames from "classnames";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from 'react';
import './preview.css'
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";
import Button from "../../../components/button/button";
import Number from "../../../components/form/elements/number/number";
import Textarea from "../../../components/form/elements/textarea/textarea";
import PreviewLabel from "../../../components/preview/previewLabel";
import PreviewThumbnail from "../../../components/preview/previewThumbnail";
import QuickView from "../../../components/quickview/quickView";
import Util from "../../../components/util/util";
import { PAGE_PRODUCTS_OF_INTEREST } from "../../../locales/pages/namespaces";
import ProductOfInterest from "./productOfInterest";

type Props = {};

const PreviewProduct = (props: Props) => {
	const { t } = useTranslation(PAGE_PRODUCTS_OF_INTEREST)
	const {
		product: {
			basicUnitOfMeasurement,
			comment,
			description,
			id,
			images,
			labels,
			message,
			multiplier,
			quantity,
			thumbnail,
			title,
			unitOfMeasurement
		},
		displayAsTile = false,
		displayMini = false,
		disabled,
		disabledFilled,
		storeProductsOfInterest,
		step = 1
	} = props
	const [showProduct, setShowProduct] = useState(false)
	const [showSummary, setShowSummary] = useState(false)
	const [newQuantity, setNewQuantity] = useState(quantity)
	const [debouncedQuantity, setDebouncedQuantity] = useDebounce(newQuantity, 1000)
	const [quantityChangedByUser, setQuantityChangedByUser] = useState(false)
	const [newComment, setNewComment] = useState(comment)
	
	useEffect(() => {
		if (quantityChangedByUser) {
			let newQuantity = debouncedQuantity
			if (isNaN(newQuantity)) {
				newQuantity = 0
			}
			if (newQuantity === debouncedQuantity) {
				storeProductsOfInterest([{
					ItemId: id,
					Quantity: debouncedQuantity,
					Comments: newComment
				}])
			} else {
				setNewQuantity(newQuantity)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedQuantity])
	
	const label = labels && labels.length > 0 ?
		_.orderBy(labels, 'weight')[0] : null
	return (
		<div className={classNames('previewInterest__wrapper', {
			'previewInterest__wrapper--disabled': disabled,
			'previewInterest__wrapper--disabledFilled': disabledFilled,
			'previewInterest__wrapper--displayAsTile': displayAsTile,
			'previewInterest__wrapper--displayMini': displayMini,
		})}>
			<PreviewThumbnail
				label={label}
				displayAsTile={displayAsTile}
				displayMini={displayMini}
				disabled={disabled}
				thumbnail={thumbnail}
				title={title}
				message={message}
				onClick={() => setShowProduct(true)}
			/>
			<div className={'preview__content'}>
				<div className={classNames('previewInterest', {
					'previewInterest--displayAsTile': displayAsTile
				})}>
					<div>
						<div>
							{label && (
								<PreviewLabel
									label={label}
									showColor={false}
								/>
							)}
							<Fragment>
								<strong
									className="previewInterest__info"
									onClick={() => {
										setShowProduct(true);
									}}>
								<span>
									{title}
								</span>
								</strong>
							</Fragment>
						</div>
						{!displayAsTile && (
							<Fragment>
								<div className={'previewInterest__details'}>
									<span>{t('Package')}: 1 {unitOfMeasurement}</span>
									<span>|</span>
									<span>{t('Quantity')}: {multiplier} {basicUnitOfMeasurement}</span>
								</div>
							</Fragment>
						)}
					</div>
					<div className={'previewInterest__notes'}>
						<Textarea
							placeholder={t('write_your_message')}
							value={newComment}
							name={'notes'}
							rows={5}
							onChange={(evt) => {
								setNewComment(evt.target.value)
							}}
							onBlur={(evt) => {
								storeProductsOfInterest([{
									ItemId: id,
									Quantity: debouncedQuantity,
									Comments: newComment
								}])
							}}
						/>
					</div>
					<div className={'previewInterest__quantity-select'}>
						<div
							className={'previewInterest__input'}
						>
							<Number
								value={newQuantity}
								min={0}
								step={step}
								onChange={(evt) => {
									setQuantityChangedByUser(true)
									let newQuantity = evt.target.valueAsNumber
									setNewQuantity(newQuantity);
								}}
							/>
						</div>
					</div>
					<QuickView
						show={showProduct}
						onClose={() => setShowProduct(false)}
					>
						<ProductOfInterest
							product={props.product}
							setNewComment={setNewComment}
							onSubmit={() => {
								storeProductsOfInterest([{
									ItemId: id,
									Quantity: debouncedQuantity,
									Comments: newComment
								}])
							}}
							newComment={newComment}
							newQuantity={newQuantity}
							setQuantityChangedByUser={setQuantityChangedByUser}
							setNewQuantity={setNewQuantity}
							step={step}
						/>
					</QuickView>
				</div>
				{!displayAsTile && (
					<div>
						<Util align={"flex-start"} direction={'column'}>
							{!displayAsTile && (
								<Button
									variant={"link"}
									onClick={() => setShowSummary(!showSummary)}
								>
									{showSummary ? (
										<>
											{t('Read Less')}
										</>
									
									) : (
										<>
											{t('Read More...')}
										</>
									)}
								</Button>
							)}
							{(showSummary) && (
								<div
									style={{ overflow: "auto", maxHeight: '200px' }}
								>
									{description ? (<div dangerouslySetInnerHTML={{ __html: description }}/>) : '-'}
								</div>
							)}
						</Util>
					</div>
				)}
			</div>
			{/*{loading && <BackgroundLoader/>}*/}
		</div>
	);
};

export default PreviewProduct