// @flow
import React, {Fragment, useEffect, useState} from 'react';
import * as yup from 'yup'
import './login.css'
import Title from "../../components/title/title";
import Button from "../../components/button/button";
import Images from "../../assets/images";
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import {useTranslation} from "react-i18next";
import TextField from "../../components/form/elements/textfield/textField";
import Password from "../../components/form/elements/password/password";
import FormGroup from "../../components/form/formGroup/formGroup";
import {PAGE_LOGIN} from "../../locales/pages/namespaces";
import useUserAction from "../../hooks/user/useUserAction";
import {useUserData} from "../../hooks/user/useUserData";
import Modal from "../../components/modal/modal";
import SelectStore from "../../components/selectStore/selectStore";
import Error from "../../components/error/error";
import LanguageSelect from "../../components/languageSelect/languageSelect";
import Util from "../../components/util/util";
import {Redirect} from "react-router";
import Social from "../../components/social/social";
import YouTube from 'react-youtube';

const Login = () => {
    const {t} = useTranslation(PAGE_LOGIN)
    const [openStoreModal, setOpenStoreModal] = useState(false)
    const [forgotPassword, setForgotPassword] = useState(false)

    const {stores, loading, errorStores, loadingStores, errorLogin, isLoggedIn} = useUserData()
    const {userLogin} = useUserAction()

    useEffect(() => {
        setOpenStoreModal(isLoggedIn)
    }, [isLoggedIn])

    const onSubmit = (values) => {
        userLogin(values)
    }

    const validationSchema = yup.object({
        username: yup
            .string()
            .required(t('required')),
        password: yup
            .string()
            .required(t('required')),
    })

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            rememberMe: false,
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    })

    return (
        <div className={'login'}>
            <Redirect to="/"/>
            <div className={'login__background'}>
                {/*<img src={Images.pages.login.background} alt={'background right'} className={'background-right'}/>*/}
                <img src={Images.pages.login.top} alt={'background top'} className={'background-top'}/>
            </div>
            <div className={'login__top'}>
                <LanguageSelect/>
                <img className={'login__logo'} src={Images.logo.white} alt={'logo'}/>
            </div>
            <div className="login__form">
                <div className="login__box">
                    <YouTube
                        className={'video-background'}
                        videoId={'5VODsCCXjUE'}
                        opts={{
                            height: '390',
                            width: '640',
                            playerVars: {
                                // https://developers.google.com/youtube/player_parameters
                                autoplay: 1,
                                controls: 0,
                                loop: 1,
                                start: 0,
                                mute: 1,
                                playlist: '5VODsCCXjUE'
                            }
                        }}
                    />
                    {/*<Title variant={'h2'} sup={t('retail_2')} invertSup/>*/}
                    {/*<Title variant={'h2'}>*/}
                    {/*    {t('retail_1')}*/}
                    {/*</Title>*/}
                    {/*<p>*/}
                    {/*    {t('retail_message')}*/}
                    {/*</p>*/}
                    {/*<Button*/}
                    {/*    component={'a'}*/}
                    {/*    href={'https://www.coffeeisland.gr/'}*/}
                    {/*    target={"_blank"}*/}
                    {/*    rel={'noopener noreferrer'}>*/}
                    {/*    {t('company_website')}*/}
                    {/*</Button>*/}
                </div>
                <div className="login__box">
                    {/*<Title variant={'h2'} sup={t('wholesale_2')} invertSup/>*/}
                    <Title variant={'h2'} style={{marginBottom: '1rem', marginTop: 'auto'}}>
                        {t('wholesale_1')}
                    </Title>
                    <FormikProvider value={formik}>
                        <form onSubmit={formik.handleSubmit}>
                            {!forgotPassword && (
                                <Fragment>
                                    <FormGroup
                                        id={'username'}
                                        label={t('User')}
                                    >
                                        <TextField
                                            id={'username'}
                                            name={'username'}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.username}
                                        />
                                        <ErrorMessage name={'username'} component={Error}/>
                                    </FormGroup>
                                    <FormGroup
                                        label={t('Password')}
                                        id={'password'}
                                    >
                                        <Password
                                            id={'password'}
                                            name={'password'}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.password}
                                        />
                                        <ErrorMessage name={'password'} component={Error}/>
                                    </FormGroup>
                                    <Util>
                                        {/*<span>*/}
                                        {/*    <Checkbox*/}
                                        {/*        id={'rememberMe'}*/}
                                        {/*        name={'rememberMe'}*/}
                                        {/*        checked={formik.values.rememberMe}*/}
                                        {/*        onChange={formik.handleChange}*/}
                                        {/*        onBlur={formik.handleBlur}*/}
                                        {/*        label={'Remember Me'}*/}
                                        {/*    />*/}
                                        {/*</span>*/}
                                        <span/>
                                        {/*<span>*/}
                                        {/*    <span>{t('Forgot your password?')}</span>*/}
                                        {/*    {' '}*/}
                                        {/*    <Button variant={"link"} onClick={() => setForgotPassword(true)}>*/}
                                        {/*        {t('Press here')}*/}
                                        {/*    </Button>*/}
                                        {/*</span>*/}
                                    </Util>
                                    {/*<br/>*/}
                                    <div style={{textAlign: 'right'}}>
                                        <Button type="submit">
                                            {t('login')}
                                        </Button>
                                    </div>
                                    <br/>
                                    <br/>
                                    <div className={'login__after'}>
                                        <div className={'after-1'}>
                                            <span>{t('OR VISIT')}</span>
                                        </div>
                                        <br/>
                                        <br/>
                                        <div className={'after-2'}>
                                            <a href={'https://www.coffeeisland.gr'}>
                                                COFFEE ISLAND
                                            </a>
                                            <a href={'https://www.coffeecampus.gr/'}>
                                                COFFEE CAMPUS
                                            </a>
                                        </div>
                                        <br/>
                                    </div>
                                    {errorLogin && <Error><strong>{t(errorLogin)}</strong></Error>}
                                    {(loading || loadingStores) && <div>{t('loading')}...</div>}
                                </Fragment>
                            )}
                            {forgotPassword && (
                                <Fragment>
                                    <FormGroup
                                        id={'email'}
                                        label={t('Email')}
                                    >
                                        <TextField
                                            type={'email'}
                                            id={'email'}
                                            name={'email'}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                        />
                                    </FormGroup>
                                    <p>
                                        {t('reset_password_message')}
                                    </p>
                                    <Button.Group>
                                        <Button type="submit">
                                            {t('reset')}
                                        </Button>
                                        <Button variant={"link"} onClick={() => setForgotPassword(false)}>
                                            {t('CANCEL')}
                                        </Button>
                                    </Button.Group>
                                </Fragment>
                            )}
                        </form>
                    </FormikProvider>
                    <div className={'login__social'}>
                        <Social/>
                    </div>
                </div>
            </div>
            <Modal
                wrapperStyle={{
                    display: (
                        !loadingStores
                        && stores
                        && stores.length > 1
                    ) ? 'block' : 'none'
                }}
                style={{
                    maxWidth: '20rem',
                }}
                show={openStoreModal}
                onClose={() => setOpenStoreModal(false)}
            >
                <Title defaultFont variant={'h4'}>
                    {t('select_store')}
                </Title>
                <br/>
                {loadingStores && <span>{t('loading')}...</span>}
                {errorStores && (
                    <Error>
                        <strong>{t(errorStores)}</strong>
                        {t('cannot_get_addresses')}
                    </Error>
                )}
                {!loadingStores && !errorStores && (
                    <div className={'login__store-select'}>
                        <SelectStore
                            requireConfirmation
                        />
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default Login