//@flow
import React from 'react'
import './loading.css'

type Props = {
    show: boolean
}

const Loading = (props: Props) => {
    const {show} = props
    if (!show) return null
    return (
        <progress className={'pure-material-progress-linear'}/>
    )
}

export default Loading