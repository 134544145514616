import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {parseMultipleFromReference} from "../../helpers/parse";
import {parseMessage} from "../../types/message/parse";

const selectLoading = createSelector(
    (state) => state.message.get('loading'),
    (item) => item
)

const selectIndexTable = createSelector(
    [
        (state) => state.message.get('indexTable'),
        (state) => state.message.get('byId'),
        (state) => state.currentUser.get('language'),
    ],
    (indexTable, byId, language) => ({
        loading: indexTable.get('loading'),
        count: indexTable.get('count'),
        items: parseMultipleFromReference(indexTable.get('items'), byId, (i) => parseMessage(i, language)),
    })
)

const useMessageData = () => {
    const loading = useSelector(selectLoading)
    const indexTable = useSelector(selectIndexTable)
    return {
        loading,
        indexTable,
    }
}

export default useMessageData