// @flow
import React, {useState} from 'react';
import classNames from "classnames";
import type {Label} from "../../types/product/types";
import PreviewLabel from "./previewLabel";
import {LazyLoadImage} from 'react-lazy-load-image-component';

type Props = {
    displayMini: boolean,
    displayAsTile: boolean,
    disabled: boolean,
    thumbnail: string,
    title: string,
    message: string,
    onClick: Function,
    label?: Label
}

const PreviewThumbnail = (props: Props) => {
    const [hideImage, setHideImage] = useState(false)
    const {displayMini, displayAsTile, label, disabled, thumbnail, title, message, onClick} = props
    return (
        <React.Fragment>
            {!disabled
                && !displayMini
                && displayAsTile
                && thumbnail
                && label
                && (
                    <PreviewLabel
                        label={label}
                        onClick={onClick}
                        showCorner
                    />
                )}
            {!disabled && (
                <div
                    onClick={onClick}
                    className={classNames("preview__image", {
                        'preview__image--hidden': hideImage || !thumbnail
                    })}
                >
                    {thumbnail && (
                        <LazyLoadImage
                            onError={() => setHideImage(true)}
                            src={thumbnail}
                            alt={title}
                        />
                    )}
                </div>
            )}
            {!disabled && !displayMini && (
                <div className={'preview__message hide-mobile'}>
                    <div dangerouslySetInnerHTML={{__html: message}}/>
                </div>
            )}
        </React.Fragment>
    )
}

export default PreviewThumbnail;