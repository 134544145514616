//@flow
import * as React from 'react';
import Title from "../title/title";
import Images from "../../assets/images";
import './card.css'
import classNames from "classnames";

type Props = {
    image?: string,
    preText?: string,
    title?: string,
    text?: string,
    action?: React.Node,
    light?: boolean
};

const Card = (props: Props) => {
    const {title, preText, text, action, light, image, textColor} = props
    return (
        <div
            style={{
                backgroundImage: image ? `url(${image})` : null,
                color: textColor ? `#${textColor}` : null
            }}
            className={
                classNames('card', {
                    'card--light': light
                })}
        >
            <img className={'card__corner--1'} src={Images.card.corner1} alt="card corner 1"/>
            <img className={'card__corner--2'} src={Images.card.corner2} alt="card corner 2"/>
            <div className={'card__content'}>
                {preText && <div className={'card__pre-text'}>{preText}</div>}
                <Title variant={"h3"}>{title}</Title>
                <div className={'card__description'}>
                    {text}
                </div>
                {action && (
                    <div className={'card__action'}>
                        {action}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Card;
