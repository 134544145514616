import {useCallback, useEffect, useState} from "react";

const addActiveClass = (entry) => {
    if (!(entry && entry.id)) return
    // const boundingClientRect = entry.getBoundingClientRect();
    const id = entry.id.split('-')
    const pMenuItem = document.querySelector(`[data-scrollspy-id="p-${id[0]}"]`);
    if (pMenuItem) {
        // if (boundingClientRect.y <= 0) {
        pMenuItem.classList.add("active");
        // }
    }
    const cMenuItem = document.querySelector(`[data-scrollspy-id="c-${id[1]}"]`);
    if (cMenuItem) {
        // if (boundingClientRect.y <= 0) {
        cMenuItem.classList.add("active");
        // }
    }
}

const removeActiveClass = () => {
    const elements = document.querySelectorAll(`[data-scrollspy-id]`);
    // debugger
    if (elements) {
        elements.forEach(el => {
            if (el.classList.contains("active")) {
                el.classList.remove("active");
            }
        })
    }
}

export const useScrollSpy = (offset) => {
    const [activeIndex, setActiveIndex] = useState(null)

    const handleScroll = useCallback(() => {
        const elements = [].slice.call(document.querySelectorAll('[data-scrollspy]'))
        let scrollPosition = window.scrollY;
        if (elements && elements.length > 0) {
            let newActiveIndex = elements.findIndex((element) => element.offsetTop > scrollPosition + offset);
            const lastElementIndex = elements[elements.length - 1];
            if (newActiveIndex > 0) {
                newActiveIndex = newActiveIndex - 1;
            } else if (newActiveIndex === -1) {
                newActiveIndex = elements.length - 1;
            }
            if (window.scrollY + 60 > lastElementIndex.offsetTop) {
                newActiveIndex = elements.length - 1;
            }
            if (newActiveIndex !== activeIndex) {
                const newActiveElement = elements[newActiveIndex]
                removeActiveClass()
                // debugger
                addActiveClass(newActiveElement)
                setActiveIndex(newActiveIndex)
            }
        }
    }, [activeIndex, offset]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll])
}
