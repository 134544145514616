// @flow
import * as React from 'react';
import {Fragment, useContext, useEffect, useState} from 'react';
import './preview.css'
import ProductQuantitySelect from "../productQuantitySelect/productQuantitySelect";
import AvailabilityStatus from "../availabilityStatus/availabilityStatus";
import classNames from "classnames";
import type {Product as ProductProps} from "../../types/product/types";
import QuickView from "../quickview/quickView";
import Product from "../product/product";
import useProductQuantity from "../../hooks/product/useProductQuantity";
import {useTranslation} from "react-i18next";
import {PRODUCT_COMPONENT} from "../../locales/components/namespaces";
import {AvailabilityStatus as ProductAvailabilityStatus} from "../../helpers/constants";
import HighlightedString from "../highlightedString/highlightedString";
import Util from "../util/util";
import {useOrderData} from "../../hooks/order/useOrderData";
import {MenuContext} from "../../pages/main/main";
import SelectOrderModal from "../selectOrderModal/selectOrderModal";
import SelectCreateOrderModal from "../selectCreateOrderModal/selectCreateOrderModal";
import {useUserData} from "../../hooks/user/useUserData";
import PreviewAdded from "./previewAdded";
import Dot from "../dot/dot";
import {getDiscountQuantity} from "../../helpers/helpers";
import {useDebounce} from 'use-debounce';
import PreviewThumbnail from "./previewThumbnail";
import useFavoriteProduct from "../../hooks/product/useFavoriteProduct";
import _ from "lodash";
import PreviewLabel from "./previewLabel";
import Button from "../button/button";
import BackgroundLoader from "../backgroundLoader/backgroundLoader";

type Props = {
    onAdd?: Function,
    title?: string,
    orderType?: number,
    code?: string,
    product?: ProductProps,
    disabled?: boolean,
    disabledFilled?: boolean,
    query?: string,
    displayAsTile?: boolean,
    displayMini?: boolean,
    orderId?: string
}

const Preview = (props: Props) => {
    const {menuCategoryType: menuCategoryOrderType} = useContext(MenuContext)
    const {t} = useTranslation(PRODUCT_COMPONENT)
    const {
        orderId,
        product: {
            retailPrice,
            step,
            labels,
            discountWeb,
            thumbnail,
            title,
            invoicePolicy,
            message,
            code,
            unitOfMeasurement,
            multiplier,
            basicUnitOfMeasurement,
            availabilityStatus,
            exclusiveToOrderTypes
        },
        displayAsTile = false,
        displayMini = false,
        query,
        onAdd,
        disabled,
        disabledFilled,
        orderType
    } = props
    const {favorite, toggleFavorite} = useFavoriteProduct(props.product)
    const [showProduct, setShowProduct] = useState(false)
    const [showRoundError, setShowRoundError] = useState(false)
    // const [showCreatingError, setShowCreatingOrderError] = useState(false)
    const [showCreatingError, setShowCreatingOrderError] = useState(false)
    const {
        quantity,
        setQuantity
    } = useProductQuantity(props.product, orderType ? orderType : menuCategoryOrderType, orderId)
    const [debouncedQuantity] = useDebounce(quantity, 1000);
    const [quantityChangedByUser, setQuantityChangedByUser] = useState(false)

    const discountQuantity = getDiscountQuantity(props.product, quantity)
    const {data} = useUserData()
    const orderTypes = data && data.orderTypes ? data.orderTypes.filter(t => exclusiveToOrderTypes && exclusiveToOrderTypes.indexOf(t.id) > -1) : []

    const [showSelectOrderModal, setShowSelectOrderModal] = useState({
        visible: false,
        callback: null
    })
    const [showCreateOrderModal, setShowCreateOrderModal] = useState({
        visible: false,
        callback: null
    })

    const {activeOrder, creatingOrder, loading} = useOrderData()

    const onPlusButtonClick = (quantity) => {
        if (availabilityStatus === ProductAvailabilityStatus.OutOfStock) return

        const onSubmit = (order, orderType) => {
            onAdd(props.product, quantity, order, orderType)
        }

        let activeOrderOfType = activeOrder.filter(o => orderType ?
            o.type === orderType :
            (exclusiveToOrderTypes && exclusiveToOrderTypes.indexOf(o.type) > -1)
        )

        if (activeOrderOfType && activeOrderOfType.length === 1) {
            onSubmit(activeOrderOfType[0])
        } else if (activeOrderOfType && activeOrderOfType.length > 1) {
            setShowSelectOrderModal({
                visible: true,
                callback: (order) => onSubmit(order)
            })
        } else if (orderTypes.length === 1) {
            onSubmit(null, orderTypes[0].id)
        } else {
            setShowCreateOrderModal({
                visible: true,
                callback: (orderType) => onSubmit(null, orderType)
            })
        }
    }

    const handleRemove = (order, orderType) => {
        setQuantity(0)
        onAdd(props.product, 0, order, orderType)
    }

    useEffect(() => {
        if (showRoundError) {
            setTimeout(() => {
                setShowRoundError(false)
            }, 4000)
        }
    }, [showRoundError])


    useEffect(() => {
        if (quantityChangedByUser && !creatingOrder) {
            setShowCreatingOrderError(false)
        }
    }, [creatingOrder])

    useEffect(() => {
        if (quantityChangedByUser) {
            let newQuantity = debouncedQuantity
            if (isNaN(newQuantity)) {
                newQuantity = 0
            }
            if (newQuantity && step) {
                const temp = newQuantity
                newQuantity = Math.floor(newQuantity / step) * step
                if (newQuantity !== temp) {
                    setShowRoundError(true)
                }
                if (newQuantity === 0) {
                    newQuantity = step
                }
            }
            if (newQuantity === debouncedQuantity) {
                onPlusButtonClick(debouncedQuantity)
            } else {
                setQuantity(newQuantity)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedQuantity])

    const onQuantityChange = (e) => {
        setQuantityChangedByUser(true)
        if (creatingOrder) {
            return setShowCreatingOrderError(true)
        }
        let newQuantity = e.target.valueAsNumber
        setQuantity(newQuantity);
    }

    const label = labels && labels.length > 0 ?
        _.orderBy(labels, 'weight')[0] : null

    return (
        <div className={classNames('preview__wrapper', {
            'preview__wrapper--disabled': disabled,
            'preview__wrapper--disabledFilled': disabledFilled,
            'preview__wrapper--displayAsTile': displayAsTile,
            'preview__wrapper--displayMini': displayMini,
        })}>
            <PreviewThumbnail
                label={label}
                displayAsTile={displayAsTile}
                displayMini={displayMini}
                disabled={disabled}
                thumbnail={thumbnail}
                title={title}
                message={message}
                onClick={() => setShowProduct(true)}
            />
            <div className={'preview__content'}>
                <div className={classNames('preview', {
                    'preview--disabled': disabled,
                    'preview--disabledFilled': disabledFilled,
                    'preview--displayAsTile': displayAsTile,
                    'preview--displayMini': displayMini
                })}>
                    <div
                        style={!onAdd ? {cursor: "initial"} : null}
                    >
                        <div>
                            {!displayMini && !displayAsTile && label && (
                                <PreviewLabel
                                    label={label}
                                    showColor={false}
                                />
                            )}
                            {!displayMini && (
                                <Fragment>
                                        <strong
                                            className="preview__info"
                                            onClick={() => {
                                            if (onAdd) {
                                                setShowProduct(true);
                                            }
                                        }}>
                                            {(query && code) ? (
                                                <HighlightedString
                                                    text={code}
                                                    query={query}
                                                />
                                            ) : code}
                                        </strong>
                                    -
                                    <span>
                                        {(query && title) ? (
                                            <HighlightedString
                                                text={title}
                                                query={query}
                                            />
                                        ) : title}
                                    </span>
                                </Fragment>
                            )}
                            {displayMini && (
                                <Fragment>
                                    <div
                                        className="preview__info"
                                        onClick={() => {
                                        if (onAdd) {
                                            setShowProduct(true);
                                        }
                                    }}>{title}</div>
                                    <Button variant={"link"}>{t('VIEW MORE')}</Button>
                                </Fragment>
                            )}
                        </div>
                        {!displayMini && (
                            <Fragment>
                                <div className={'preview__details'}>
                                    <span>{t('Package')}: 1 {unitOfMeasurement}</span>
                                    <span>|</span>
                                    <span>{t('Quantity')}: {multiplier} {basicUnitOfMeasurement}</span>
                                    <span>|</span>
                                    <span>{t('Retail Price')}: <strong>{parseInt(retailPrice) === 0 ? '-' : `${retailPrice}${data?.currencySymbol}/${t('Package')}`}</strong></span>
                                </div>
                                <Util justifyContent={"initial"} align={"center"}>
                                    <AvailabilityStatus status={availabilityStatus}/>
                                    {!disabled && (
                                        <div className={'preview__message show-mobile'}>
                                            <div dangerouslySetInnerHTML={{__html: message}}/>
                                        </div>
                                    )}
                                </Util>
                            </Fragment>
                        )}
                    </div>
                    {!displayMini && (
                        <ProductQuantitySelect
                            step={step}
                            quantity={quantity}
                            disabled={disabled}
                            product={props.product}
                            showRoundError={showRoundError}
                            showCreatingError={showCreatingError}
                            onQuantityChange={onQuantityChange}
                        />
                    )}
                    <QuickView
                        show={showProduct}
                        onClose={() => setShowProduct(false)}
                    >
                        <Product
                            product={props.product}
                            onClose={() => setShowProduct(false)}
                            onSubmit={onPlusButtonClick}
                            onAdd={onAdd}
                            orderType={orderType}
                        />
                    </QuickView>
                </div>
                {!displayMini && (
                    <Fragment>
                        {Boolean(invoicePolicy) && (
                            <div className={'preview preview--discount'} aria-label={'discount-policy-line'}>
                                <div className="preview__info">
                                    <Dot type={4}/>
                                    <span
                                        style={{marginLeft: '.5rem'}}><strong>{t('With Discount')}</strong> - {invoicePolicy.discountPercentage?.toFixed(2)}%</span>
                                </div>
                                <ProductQuantitySelect
                                    disabled
                                    useDiscount
                                    product={props.product}
                                    quantity={discountQuantity.toFixed(0)}
                                />
                            </div>
                        )}
                        {Boolean(discountWeb) && (
                            <div className={'preview preview--discount'}>
                                <div className="preview__info">
                                    <Dot type={4}/>
                                    <span
                                        style={{marginLeft: '.5rem'}}><strong>{t('With Discount')}</strong> - {discountWeb?.toFixed(2)}%</span>
                                </div>
                            </div>
                        )}
                        {!disabled && exclusiveToOrderTypes && exclusiveToOrderTypes.map(type => (
                            <PreviewAdded
                                key={type}
                                type={type}
                                product={props.product}
                                onRemove={handleRemove}
                            />
                        ))}
                    </Fragment>
                )}
            </div>
            <SelectOrderModal
                show={showSelectOrderModal.visible}
                onClose={() => setShowSelectOrderModal({visible: false, callback: null})}
                onSubmit={showSelectOrderModal.callback}
                types={exclusiveToOrderTypes}
            />
            <SelectCreateOrderModal
                show={showCreateOrderModal.visible}
                onClose={() => setShowCreateOrderModal({visible: false, callback: null})}
                onSubmit={showCreateOrderModal.callback}
                types={orderTypes}
            />
            {!displayMini && (
                <button
                    onClick={toggleFavorite}
                    className={classNames('make-favorite', {
                        'make-favorite--true': favorite
                    })}>
                    <span>{t('FAVORITE')}</span><span>❤</span>
                </button>
            )}
            {loading && <BackgroundLoader/>}
        </div>
    )
}

export default Preview