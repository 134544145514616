//@flow
import React from 'react';

type Props = {
    value?: string,
    onChange?: Function,
    onBlur?: Function,
    name?: string,
    id?: string,
};

const Password = (props: Props) => {
    return (
        <input
            type="password"
            placeholder={'password'}
            {...props}
        />
    );
};

export default Password;