import React, {Fragment, useEffect, useState} from "react";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {CUSTOMER_INFO} from "../../../locales/components/namespaces";
import {useUserData} from "../../../hooks/user/useUserData";
import useUserAction from "../../../hooks/user/useUserAction";
import Title from "../../title/title";
import Divider from "../../divider/divider";
import RangeSelect from "../../rangeSelect/rangeSelect";
import {formatDate, formatTypes} from "../../../helpers/date";
import Button from "../../button/button";
import {customerTabDisabledBeforeDate} from "../../../helpers/constants";

const InnerTab = React.forwardRef((props, ref) => {
    const {handlePrint} = props
    const [range, setRange] = useState([
        moment().subtract(31, 'days').toDate(), new Date()
    ])

    const {t} = useTranslation(CUSTOMER_INFO)
    const {customerTabInfo, loading} = useUserData()
    const {getCustomerTabInfo} = useUserAction()


    useEffect(() => {
        getCustomerTabInfo(range)
    }, [range, getCustomerTabInfo])

    const onSubmit = (values) => {
        const {range} = values
        setRange(range)
    }

    return (
        <div ref={ref} className={'customer-tab-page contained-page bg-container'}>
            <Title>
                {t('CUSTOMER CARD')}
            </Title>
            <Divider/>
            <RangeSelect
                minDate={customerTabDisabledBeforeDate}
                initialRange={range}
                onSubmit={onSubmit}
            />
            {loading && <span>{t('Loading')}...</span>}
            <br/>
            {customerTabInfo && (
                <div className='customer-tab'>
                    <div className={'table-wrapper'}>
                        <table className={'customer-tab__info'}>
                            <tbody>
                            {customerTabInfo.info && (
                                <Fragment>
                                    <tr>
                                        <td><strong>{t('name')}</strong></td>
                                        <td><strong>{customerTabInfo.info.name}</strong></td>
                                        <td>{t('address')}</td>
                                        <td>
                                            <strong>{customerTabInfo.info.address} {t('postalCode')}: {customerTabInfo.info.postalCode}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('vatNumber')}</td>
                                        <td><strong>{customerTabInfo.info.vatNumber}</strong></td>
                                        <td>{t('area')}</td>
                                        <td><strong>{customerTabInfo.info.area}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>{t('occupation')}</td>
                                        <td><strong>{customerTabInfo.info.occupation}</strong></td>
                                        <td>{t('city')}</td>
                                        <td><strong>{customerTabInfo.info.city}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>{t('contact')}</td>
                                        <td><strong>{customerTabInfo.info.contact}</strong></td>
                                        <td>{t('phone')}</td>
                                        <td><strong>{customerTabInfo.info.phone}</strong></td>
                                    </tr>
                                </Fragment>
                            )}
                            </tbody>
                        </table>
                    </div>
                    <div className={'table-wrapper'}>
                        <table>
                            <thead>
                            <tr>
                                <td colSpan="4">{t('Transaction Data')}</td>
                                <td colSpan="2">{t('Period Amount')}</td>
                                <td>{t('Progressive')}</td>
                            </tr>
                            <tr/>
                            <tr>
                                <td>{t('Date')}</td>
                                <td>{t('Document')}</td>
                                <td>{t('Branch')}</td>
                                <td>{t('Reason')}</td>
                                <td style={{textAlign: "right"}}>{t('Debit')}</td>
                                <td style={{textAlign: "right"}}>{t('Credit')}</td>
                                <td style={{textAlign: "right"}}>{t('Remain')}</td>
                            </tr>
                            </thead>
                            <tbody>
                            {customerTabInfo.transactionData.length > 0 &&
                                customerTabInfo.transactionData.map((i, index) => (
                                    <tr key={index}>
                                        <td>{i.date}</td>
                                        <td>{i.documentNumber}</td>
                                        <td>{i.branch}</td>
                                        <td>{i.reason}</td>
                                        <td style={{textAlign: "right"}}>{i.debitAmount ? i.debitAmount.toFixed(2) : '-'}</td>
                                        <td style={{textAlign: "right"}}>{i.creditAmount ? i.creditAmount.toFixed(2) : '-'}</td>
                                        <td style={{textAlign: "right"}}>{i.remainder ? i.remainder.toFixed(2) : '-'}</td>
                                    </tr>
                                ))}
                            </tbody>
                            {customerTabInfo.transactionDataTotal && (
                                <tfoot>
                                <tr>
                                    <td colSpan="4">{t('Totals')}</td>
                                    <td className="number"
                                        style={{textAlign: "right"}}>{customerTabInfo.transactionDataTotal.debitAmount}</td>
                                    <td className="number"
                                        style={{textAlign: "right"}}>{customerTabInfo.transactionDataTotal.creditAmount}</td>
                                    <td className="number"
                                        style={{textAlign: "right"}}>{customerTabInfo.transactionDataTotal.remainder}</td>
                                </tr>
                                </tfoot>
                            )}
                        </table>
                    </div>
                    <div className={'table-wrapper'}>
                        <table>
                            <thead>
                            <tr>
                                <td colSpan="6">{t('PENDING SHIPPING CARDS')}</td>
                            </tr>
                            <tr>
                                <td>{t('Date')}</td>
                                <td>{t('Document')}</td>
                                <td>{t('Branch')}</td>
                                <td>{t('Reason')}</td>
                                <td style={{textAlign: "right"}}>{t('Pending Quantity')}</td>
                                <td style={{textAlign: "right"}}>{t('Predicted Pending Value')}</td>
                            </tr>
                            </thead>
                            <tbody>
                            {customerTabInfo.pending &&
                                customerTabInfo.pending.length > 0 &&
                                customerTabInfo.pending.map((i, index) => (
                                    <tr key={index}>
                                        <td>{i.date}</td>
                                        <td>{i.documentNumber}</td>
                                        <td>{i.branch}</td>
                                        <td>{i.reason}</td>
                                        <td style={{textAlign: "right"}}>{i.debitAmount ? i.debitAmount.toFixed(2) : '-'}</td>
                                        <td style={{textAlign: "right"}}>{i.creditAmount ? i.creditAmount.toFixed(2) : '-'}</td>
                                    </tr>
                                ))}
                            </tbody>
                            {customerTabInfo.pendingTotal && (
                                <tfoot>
                                <tr>
                                    <td colSpan="4">{t('Totals')}</td>
                                    <td className="number"
                                        style={{textAlign: "right"}}>{customerTabInfo.pendingTotal.pendingQuantity}</td>
                                    <td className="number"
                                        style={{textAlign: "right"}}>{customerTabInfo.pendingTotal.predictedPendingValue}</td>
                                </tr>
                                </tfoot>
                            )}
                        </table>
                    </div>
                    {t('after_message', {date: formatDate(new Date(), formatTypes.DATE_ONLY)})}
                    <Button.Group spacingTop={1}>
                        <Button onClick={handlePrint}>
                            {t('PRINT')}
                        </Button>
                    </Button.Group>
                </div>
            )}
        </div>
    )
})

export default InnerTab