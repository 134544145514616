// @flow
import React from 'react';
import {Fragment} from "react";
import type {SalesPerson} from "../../types/salesPerson/type";
import './selectSalesPerson.css'
import {useTranslation} from "react-i18next";
import {PAGE_REPORTS} from "../../locales/pages/namespaces";

type Props = {
    value: string,
    onChange: Function,
    salesPersons: Array<SalesPerson>
};

const SelectSalesPerson = ({value, onChange, salesPersons}: Props) => {
    const {t} = useTranslation([PAGE_REPORTS])
    return salesPersons.length > 1 && (
        <Fragment>
            <select
                className={'select-store'}
                value={value}
                onChange={onChange}
            >
                <option value={'all'}>
                    {t('ALL')}
                </option>
                {salesPersons.map(salesPerson => (
                    <option key={salesPerson.id} value={salesPerson.id}>
                        {salesPerson.name}
                    </option>
                ))}
            </select>
        </Fragment>
    );
};

export default SelectSalesPerson