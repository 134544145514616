//@flow
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Util from "../../components/util/util";
import Title from "../../components/title/title";
import Button from "../../components/button/button";
import Icon from "../../components/icon/icon";
import Images from "../../assets/images";
import Divider from "../../components/divider/divider";
import List from "../../components/list/list";
import ListItem from "../../components/list/partials/listItem/listItem";
import ListItemSubtitle from "../../components/list/partials/listItemSubtitle/listItemSubtitle";
import ListItemTitle from "../../components/list/partials/listItemTitle/listItemTitle";
import ListItemText from "../../components/list/partials/listItemText/listItemText";
import './home.css'
import Banner from "../../components/banner/banner";
import { Link } from "react-router-dom";
import { getRouteUrl } from "../../helpers/getRouteUrl";
import useProductsOfInterestAction from "../../hooks/productsOfInterest/useProductsOfInterestAction";
import useProductsOfInterestData from "../../hooks/productsOfInterest/useProductsOfInterestData";
import {
	ROUTE_PAGE_ANNOUNCEMENT,
	ROUTE_PAGE_ANNOUNCEMENTS,
	ROUTE_PAGE_ORDER_EDIT,
	ROUTE_PAGE_ORDERS, ROUTE_PAGE_PRODUCTS_OF_INTEREST
} from "../../routers/routes";
import useOrderAction from "../../hooks/order/useOrderAction";
import { useOrderData } from "../../hooks/order/useOrderData";
import { formatDate, formatTypes } from "../../helpers/date";
import useMessageAction from "../../hooks/message/useMessageAction";
import {
	AnnouncementTypes,
	MESSAGE_ACTION_TYPE_B2B,
	Permissions,
	ReadStatus
} from "../../helpers/constants";
import useMessageData from "../../hooks/message/useMessageData";
import { useTranslation } from "react-i18next";
import { PAGE_ANNOUNCEMENTS, PAGE_HOME } from "../../locales/pages/namespaces";
import CreateOrder from "../../components/createOrder/createOrder";
import OrderStatus from "../../components/orderStatus/orderStatus";
import useProductAction from "../../hooks/product/useProductAction";
import useProductData from "../../hooks/product/useProductData";
import ProductsList from "../../components/productsList/productsList";
import { useUserData } from "../../hooks/user/useUserData";
import UnstructuredTableView from "../../components/table/partials/views/unstructuredTableView";
import withInfiniteScroll from "../../components/table/withInfiniteScroll";
import Modal from "../../components/modal/modal";
import Table from "../../components/table/table";
import { useHistory } from "react-router";
import { useHasPermission } from "../../modules/permission/hooks/useHasPermission";

const ViewInfiniteScroll = withInfiniteScroll(UnstructuredTableView)

export const Tab = {
	popular: 0,
	new: 1,
	offers: 2,
	favorites: 3,
	nearUs: 4
}

const Home = () => {
	const { t } = useTranslation(PAGE_HOME)
	const [productsTab, setProductsTab] = useState(Tab.offers)
	const { getPopularAndNewProducts } = useProductAction()
	const { getProductsOfInterest } = useProductsOfInterestAction()
	const { loading, productsOfInterest } = useProductsOfInterestData()
	const { banner } = useProductData()
	let bannerItems
	switch (productsTab) {
		case Tab.offers:
			bannerItems = banner.offers
			break
		case Tab.favorites:
			bannerItems = banner.favorites
			break
		case Tab.new:
			bannerItems = banner.new
			break
		case Tab.nearUs:
			bannerItems = banner.nearUs
			break
		default:
		case Tab.popular:
			bannerItems = banner.popular
			break
	}
	const { data } = useUserData()
	const { getOrders, getOrdersFromSalesPersons, toggleOrdersFromSalesPersons } = useOrderAction()
	const { indexTable: ordersIndexTable, ordersFromSalesPersons, ordersFromSalesOpen } = useOrderData()
	const { getMessages } = useMessageAction(MESSAGE_ACTION_TYPE_B2B, AnnouncementTypes.All, ReadStatus.Any)
	const { indexTable: announcementsIndexTable } = useMessageData()
	const history = useHistory()
	const isAdmin = useHasPermission([Permissions.AccessAdmin], { shouldView: true })
	const isSalesPerson = useHasPermission([Permissions.AccessSalesPerson], { shouldView: true })
	useEffect(() => {
		getOrders(0, 3)
	}, [getOrders])
	useEffect(() => {
		getMessages(0, 10)
	}, [getMessages])
	
	useEffect(() => {
		if (!isAdmin && !isSalesPerson) {
			getOrdersFromSalesPersons(0, 10)
		}
	}, [getOrdersFromSalesPersons, isAdmin, isSalesPerson])
	
	useEffect(() => {
		getProductsOfInterest(0, 100)
	}, [])
	const dataManager = useCallback((pageIndex, pageSize) => {
		getPopularAndNewProducts(pageIndex, pageSize, productsTab)
	}, [getPopularAndNewProducts, productsTab])
	
	return (
		<div className={'home container'}>
			<div className="home__top">
				<img src={Images.pages.home.background_1} alt={'background 1'}/>
				<img src={Images.pages.home.background_2} alt={'background 2'}/>
				<div>
					<div>
						<Util>
							<Title invertSup variant={"h2"} sup={t('latest')}>
								{t('news')}
							</Title>
							<div className={'hide-mobile'}>
								<Button variant={'link'} component={Link} to={getRouteUrl(ROUTE_PAGE_ANNOUNCEMENTS)}>
									{t('all')} {t('news')} <Icon icon={Images.icons.arrowRight}/>
								</Button>
							</div>
						</Util>
						<Divider/>
						<div className={'show-mobile'} style={{ textAlign: 'right' }}>
							<Button variant={'link'} component={Link} to={getRouteUrl(ROUTE_PAGE_ANNOUNCEMENTS)}>
								{t('all')} {t('news')} <Icon icon={Images.icons.arrowRight}/>
							</Button>
						</div>
						<List dense>
							{announcementsIndexTable.items.slice(0, 10).map(item => {
								const { typeId, date, title, id, text } = item
								return (
									<ListItem noBorders key={id}>
										<Util>
											<Link
												// variant={'link'}
												// component={Link}
												style={{ width: "79%" }}
												to={getRouteUrl(ROUTE_PAGE_ANNOUNCEMENT, { id: id })}
											>
												<ListItemTitle variant={"h5"}>
													{title}
												</ListItemTitle>
											</Link>
											<ListItemSubtitle>
												<div>
													<ListItemSubtitle.Primary>
														{t(PAGE_ANNOUNCEMENTS + ':announcement_type_' + typeId)}
													</ListItemSubtitle.Primary>
													<span style={{ marginRight: 8 }}>&nbsp;</span>
													<ListItemSubtitle.Secondary>
														{formatDate(date, formatTypes.DATE_ONLY_LONG).toUpperCase()}
													</ListItemSubtitle.Secondary>
												</div>
											</ListItemSubtitle>
										</Util>
										{/*<ListItemText>*/}
										{/*    <Dotdotdot clamp={2} useNativeClamp>*/}
										{/*        <div dangerouslySetInnerHTML={{__html: text}}/>*/}
										{/*    </Dotdotdot>*/}
										{/*    <Button variant={'link'} component={Link}*/}
										{/*            to={getRouteUrl(ROUTE_PAGE_ANNOUNCEMENT, {id: id})}>*/}
										{/*        {t('read_more')} <Icon icon={Images.icons.arrowRight}/>*/}
										{/*    </Button>*/}
										{/*</ListItemText>*/}
									</ListItem>
								)
							})}
						</List>
						<div className="hide-mobile">
							<Divider/>
						</div>
					</div>
					<div>
						<Button.Group className={'wrap-when-more-than-2'}>
							<CreateOrder/>
						</Button.Group>
						<div className="hide-mobile">
							<Divider/>
						</div>
						<Util>
							<Title invertSup variant={"h2"} sup={t('latest')}>
								{t('orders')}
							</Title>
							<div className="hide-mobile">
								<Button variant={'link'} component={Link} to={getRouteUrl(ROUTE_PAGE_ORDERS)}>
									{t('all')} {t('orders')} <Icon icon={Images.icons.arrowRight}/>
								</Button>
							</div>
						</Util>
						{!isAdmin && !isSalesPerson && ordersFromSalesPersons.items.length > 0 && !ordersFromSalesPersons.loading && (
							<Button variant={'link'}
									onClick={() => toggleOrdersFromSalesPersons(true)}>
								{t('pending')} {t('orderSuggestions')} <Icon icon={Images.icons.arrowRight}/>
							</Button>
						)}
						<div className={'show-mobile'} style={{ textAlign: 'right' }}>
							<Divider/>
							<Button variant={'link'} component={Link} to={getRouteUrl(ROUTE_PAGE_ORDERS)}>
								{t('all')} {t('orders')} <Icon icon={Images.icons.arrowRight}/>
							</Button>
						</div>
						<List dense>
							{ordersIndexTable.items.slice(0, 3).map(order => {
								const { id, statusId, date, price } = order
								return (
									<ListItem key={id}>
										<ListItemTitle variant={"body"}>
											{/*{store?.address?.toUpperCase()}*/}
											<OrderStatus status={statusId}/>
										</ListItemTitle>
										<ListItemText>
											<Util>
												<div>
													<strong>{formatDate(date, formatTypes.TITLE_HALF)} </strong>
													<span>({formatDate(date, formatTypes.TIME_ONLY).toUpperCase()})</span>
												</div>
												<strong>{price}{data?.currencySymbol}</strong>
											</Util>
										</ListItemText>
									</ListItem>
								);
							})}
						</List>
						<Divider spacingBottom={1}/>
						{!loading && productsOfInterest?.items?.length > 0 && (
							<div style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
								width: '100%',
								marginBottom: '1rem'
							}}>
								<Button
									variant={'action'}
									component={Link}
									to={getRouteUrl(ROUTE_PAGE_PRODUCTS_OF_INTEREST)}
								>
									{t('PRODUCTS OF INTEREST')}
								</Button>
							</div>
						)}
						<Banner/>
					</div>
				</div>
			</div>
			<div className="home__middle">
				<Util justifyContent={'initial'}>
					<Title variant={"h3"} disabled={productsTab !== Tab.offers}
						   onClick={() => setProductsTab(Tab.offers)}>
						{t('offers')}
					</Title>
					<Title variant={"h3"} sup={t('products')} disabled={productsTab !== Tab.popular}
						   onClick={() => setProductsTab(Tab.popular)}>
						{t('popular')}
					</Title>
					<Title variant={"h3"} sup={t('products')} disabled={productsTab !== Tab.favorites}
						   onClick={() => setProductsTab(Tab.favorites)}>
						{t('favorites')}
					</Title>
					<Title variant={"h3"} sup={t('products')} disabled={productsTab !== Tab.new}
						   onClick={() => setProductsTab(Tab.new)}>
						{t('new')}
					</Title>
					<Title variant={"h3"} sup={t('products')} disabled={productsTab !== Tab.nearUs}
						   onClick={() => setProductsTab(Tab.nearUs)}>
						{t('nearUs')}
					</Title>
				</Util>
				<br/>
				<ProductsList
					dataManager={dataManager}
					data={{
						loading: banner.loading,
						items: bannerItems.slice(0, 10),
						count: bannerItems.length
					}}
					noMeta
					noPagination
					displayAsTile={true}
					view={ViewInfiniteScroll}
				/>
			</div>
			{!isAdmin && !isSalesPerson && ordersFromSalesPersons.items.length > 0 && !ordersFromSalesPersons.loading && (
				<Modal
					show={ordersFromSalesOpen}
					onClose={() => toggleOrdersFromSalesPersons(false)}
					className={'create-template-modal'}
				>
					<Title invertSup variant={"h3"} sup={t('pending')}>
						{t('orderSuggestions')}
					</Title>
					<div className="table-wrapper" style={{ marginBottom: 24 }}>
						<Table
							data={ordersFromSalesPersons}
							dataManager={getOrdersFromSalesPersons}
							noHeader
							noPagination
							noMeta
							columns={[
								{
									accessor: 'code',
									Cell: ({ value }) => (
										<strong>#{value}</strong>
									)
								},
								{
									accessor: 'date',
									Cell: ({ value }) => (
										<div>
											<strong>{formatDate(value, formatTypes.TITLE_HALF)} </strong>
											<span>({formatDate(value, formatTypes.TIME_ONLY)})</span>
										</div>
									)
								},
								{
									accessor: 'price',
									Cell: ({ value }) => <strong>{value}{data?.currencySymbol}</strong>
								},
								{
									accessor: 'statusId',
									Cell: ({ value, row }) => {
										const { transferredDate, id } = row.original
										return (
											<div
												style={{
													cursor: 'pointer',
													textAlign: "center"
												}}
												className={'order-status order-status--0'}
												onClick={() => {
													toggleOrdersFromSalesPersons(false)
													history.push(getRouteUrl(ROUTE_PAGE_ORDER_EDIT, { id: id }) + '/finalize', { order: row.original })
												}}
											>
												<div style={{ textAlign: "center" }}>
													{t('show')}
												</div>
											</div>
										)
									}
								}
							]}
						/>
					</div>
				</Modal>
			)}
		</div>
	)
}

export default Home;