//@flow
import * as React from 'react';

type Props = {
    children: React.Node
};

const ListItemText = (props: Props) => {
    const {children} = props
    return (
        <div className={'list__item-text'}>
            {children}
        </div>
    );
};

export default ListItemText