//@flow
import React from 'react';
import "./customerTab.css";
import {withPermissionHOC} from "../../../modules/permission/components/permissionHOC/permissionHOC";
import {Permissions} from "../../../helpers/constants";
import InnerTab from "./innerTab";

const CustomerTab = () => {
    const handlePrint = () => {
        window.print();
    }
    return (
        <InnerTab
            handlePrint={handlePrint}
        />
    )
}

export default withPermissionHOC(CustomerTab, [Permissions.AccessReports])