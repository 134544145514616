import {useDispatch} from "react-redux";
import * as actions from "../../actions/actions";
import {useCallback} from "react";
import {useUserData} from "../user/useUserData";


export const useBannerAction = () => {
    const dispatch = useDispatch()
    const {selectedStore} = useUserData()

    const getBanners = useCallback(() => {
        dispatch(actions.getBanners({
            AddressId: selectedStore.id,
        }))
    }, [selectedStore?.id, dispatch])

    return {
        getBanners
    }
}
