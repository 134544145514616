// @flow
import React from 'react';
import Title from "../title/title";
import {Trans} from "react-i18next";
import {PAGE_PAYMENTS} from "../../locales/pages/namespaces";
import DateRange from "../form/elements/dateRange/dateRange";
import Button from "../button/button";
import {FormikProvider, useFormik} from "formik";
import "./rangeSelect.css"

type Props = {
    minDate?: Date,
    initialRange: Array<Date>,
    onSubmit: Function
}
const RangeSelect = (props: Props) => {
    const {onSubmit, initialRange,minDate} = props
    const formik = useFormik({
        initialValues: {
            range: initialRange,
        },
        onSubmit: onSubmit,
    })
    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
                <div className={'select-range'}>
                    <Title variant={"h4"}>
                        <Trans
                            i18nKey={'search_title'}
                            ns={PAGE_PAYMENTS}>
                            SEARCH<br/>
                            RANGE
                        </Trans>
                    </Title>
                    <DateRange
                        minDate={minDate}
                        range={formik.values.range}
                        onChange={(range) => {
                            formik.setFieldValue('range', range)
                        }}
                    />
                    <Button type={"submit"}>
                        ΑΝΑΖΗΤΗΣΗ
                    </Button>
                </div>
            </form>
        </FormikProvider>
    )
}

export default RangeSelect;