//@flow
import React from 'react';
import classNames from "classnames";
import './orderType.css'

type Props = {
    type: string,
    typeDescription: string,
};

const OrderType = (props: Props) => {
    const {type, typeDescription} = props
    return (
        <span className={classNames('order-type', {
            ['order-type--' + type]: true
        })}>
            {typeDescription}
        </span>
    );
};

export default OrderType;