import { fromJS } from "immutable";
import * as ActionTypes from '../../actions/types'
import { mapMultiple } from "../../helpers/map";
import { mapProductOfInterest } from "../../types/productsOfInterest/map";

const INITIAL_STATE = {
	loading: false,
	productsOfInterest: {
		loading: false,
		items: [],
		count: 0
	},
	message: null,
}

function setProductMessage(state, action) {
	const message = action.payload
	return state
	.set('message', fromJS(message ? {
		text: message?.text, variant: message?.variant
	} : null))
}

function getProductsOfInterest(state) {
	return state
	.setIn(['productsOfInterest', 'loading'], true)
	.setIn(['productsOfInterest', 'count'], 0)
	.setIn(['productsOfInterest', 'items'], fromJS([]))
	.set('message', null)
}

function getProductsOfInterestSucceeded(state, action) {
	const { items } = action.payload
	const mappedData = mapMultiple(items, mapProductOfInterest)
	return state
	.setIn(['productsOfInterest', 'loading'], false)
	.setIn(['productsOfInterest', 'count'], parseInt(mappedData?.length))
	.setIn(['productsOfInterest', 'items'], fromJS(mappedData))
	.set('message', null)
}

function getProductsOfInterestFailed(state, action) {
	const { message } = action.payload
	return state
	.setIn(['productsOfInterest', 'loading'], false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function storeProductsOfInterest(state) {
	return state
	.set('loading', true)
	.set('message', null)
}

function storeProductsOfInterestSucceeded(state, action) {
	const { parameters: { AddressId, Items } } = action
	const id = Items.length > 0 ? Items[0].ItemId : null
	const updateComment = Items.length > 0 ? Items[0].Comments : null
	const updateQuantity = Items.length > 0 ? Items[0].Quantity : null
	if (Items.length === 0) {
		return state
		.set('loading', false)
		.set('message', fromJS({
			text: 'Your interest was submitted successfully!', variant: 'success'
		}))
	}
	
	return state
	.set('loading', false)
	.setIn(['productsOfInterest', 'items', id, 'quantity'], updateQuantity)
	.setIn(['productsOfInterest', 'items', id, 'comment'], updateComment)
	.set('message', fromJS({
		text: 'Your interest was submitted successfully!', variant: 'success'
	}))
}

function storeProductsOfInterestFailed(state, action) {
	const { message } = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

const productsOfInterest = {
	initialState: INITIAL_STATE,
	handlers: {
		[ActionTypes.GET_PRODUCTS_OF_INTEREST]: getProductsOfInterest,
		[ActionTypes.GET_PRODUCTS_OF_INTEREST_SUCCEEDED]: getProductsOfInterestSucceeded,
		[ActionTypes.GET_PRODUCTS_OF_INTEREST_FAILED]: getProductsOfInterestFailed,
		[ActionTypes.STORE_PRODUCTS_OF_INTEREST]: storeProductsOfInterest,
		[ActionTypes.STORE_PRODUCTS_OF_INTEREST_SUCCEEDED]: storeProductsOfInterestSucceeded,
		[ActionTypes.STORE_PRODUCTS_OF_INTEREST_FAILED]: storeProductsOfInterestFailed,
		[ActionTypes.SET_MESSAGE_PRODUCT_OF_INTEREST]: setProductMessage,
	}
}

export default productsOfInterest
