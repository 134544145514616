// @flow
import React from 'react'
import {useTranslation} from "react-i18next";
import {TABLE_COMPONENT} from "../../../../../locales/components/namespaces";

export default function TextFilter({column: {filterValue, preFilteredRows, setFilter},}) {
    const count = preFilteredRows.length
    const {t} = useTranslation(TABLE_COMPONENT)
    return (
        <input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={t('search', {count})}
        />
    )
}
