//@flow
import React from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import './dateRange.css'
import {useUserData} from "../../../../hooks/user/useUserData";

type Props = {
    minDate?: Date,
    range: Array<Date>,
    onChange: Function,
};

const DateRange = (props: Props) => {
    const {minDate, range, onChange} = props
    const {language} = useUserData()

    return (
        <div>
            <DateRangePicker
                value={range}
                onChange={onChange}
                locale={language}
                minDate={minDate}
            />
        </div>
    );
};

export default DateRange;