import {actionCreator} from '../helpers/actionCreator'
import * as ActionTypes from './types'
import {TOGGLE_ORDERS_FROM_SALES_PERSONS, GET_REPORT_1_INFO, GET_REPORT_4_ORDER_PRODUCTS} from "./types";

export const userLogin = actionCreator(ActionTypes.USER_LOGIN);
export const userLoginSucceeded = actionCreator(ActionTypes.USER_LOGIN_SUCCEEDED);
export const userLoginFailed = actionCreator(ActionTypes.USER_LOGIN_FAILED);

export const userLogout = actionCreator(ActionTypes.USER_LOGOUT);
export const userLogoutSucceeded = actionCreator(ActionTypes.USER_LOGOUT_SUCCEEDED);
export const userLogoutFailed = actionCreator(ActionTypes.USER_LOGOUT_FAILED);

export const getUserStores = actionCreator(ActionTypes.GET_USER_STORES);
export const getUserStoresSucceeded = actionCreator(ActionTypes.GET_USER_STORES_SUCCEEDED);
export const getUserStoresFailed = actionCreator(ActionTypes.GET_USER_STORES_FAILED);

export const setUserStore = actionCreator(ActionTypes.SET_USER_STORE);
export const setUserLanguage = actionCreator(ActionTypes.SET_USER_LANGUAGE);

export const getUserDetails = actionCreator(ActionTypes.GET_USER_DETAILS);
export const getUserDetailsSucceeded = actionCreator(ActionTypes.GET_USER_DETAILS_SUCCEEDED);
export const getUserDetailsFailed = actionCreator(ActionTypes.GET_USER_DETAILS_FAILED);

export const getProductCategories = actionCreator(ActionTypes.GET_PRODUCT_CATEGORIES);
export const getProductCategoriesSucceeded = actionCreator(ActionTypes.GET_PRODUCT_CATEGORIES_SUCCEEDED);
export const getProductCategoriesFailed = actionCreator(ActionTypes.GET_PRODUCT_CATEGORIES_FAILED);

export const getProducts = actionCreator(ActionTypes.GET_PRODUCTS);
export const getProductsSucceeded = actionCreator(ActionTypes.GET_PRODUCTS_SUCCEEDED);
export const getProductsFailed = actionCreator(ActionTypes.GET_PRODUCTS_FAILED);

export const getPopularAndNewProducts = actionCreator(ActionTypes.GET_POPULAR_AND_NEW_PRODUCTS);
export const getPopularAndNewProductsSucceeded = actionCreator(ActionTypes.GET_POPULAR_AND_NEW_PRODUCTS_SUCCEEDED);
export const getPopularAndNewProductsFailed = actionCreator(ActionTypes.GET_POPULAR_AND_NEW_PRODUCTS_FAILED);

export const getProduct = actionCreator(ActionTypes.GET_PRODUCT);
export const getProductSucceeded = actionCreator(ActionTypes.GET_PRODUCT_SUCCEEDED);
export const getProductFailed = actionCreator(ActionTypes.GET_PRODUCT_FAILED);

export const getOrders = actionCreator(ActionTypes.GET_ORDERS);
export const getOrdersSucceeded = actionCreator(ActionTypes.GET_ORDERS_SUCCEEDED);
export const getOrdersFailed = actionCreator(ActionTypes.GET_ORDERS_FAILED);

export const getOrderDetails = actionCreator(ActionTypes.GET_ORDER_DETAILS);
export const getOrderDetailsSucceeded = actionCreator(ActionTypes.GET_ORDER_DETAILS_SUCCEEDED);
export const getOrderDetailsFailed = actionCreator(ActionTypes.GET_ORDER_DETAILS_FAILED);

export const getActiveOrder = actionCreator(ActionTypes.GET_ACTIVE_ORDER);
export const getActiveOrderSucceeded = actionCreator(ActionTypes.GET_ACTIVE_ORDER_SUCCEEDED);
export const getActiveOrderFailed = actionCreator(ActionTypes.GET_ACTIVE_ORDER_FAILED);

export const createOrder = actionCreator(ActionTypes.CREATE_ORDER);
export const createOrderSucceeded = actionCreator(ActionTypes.CREATE_ORDER_SUCCEEDED);
export const createOrderFailed = actionCreator(ActionTypes.CREATE_ORDER_FAILED);

export const updateOrder = actionCreator(ActionTypes.UPDATE_ORDER);
export const updateOrderSucceeded = actionCreator(ActionTypes.UPDATE_ORDER_SUCCEEDED);
export const updateOrderFailed = actionCreator(ActionTypes.UPDATE_ORDER_FAILED);

export const deleteOrder = actionCreator(ActionTypes.DELETE_ORDER);
export const deleteOrderSucceeded = actionCreator(ActionTypes.DELETE_ORDER_SUCCEEDED);
export const deleteOrderFailed = actionCreator(ActionTypes.DELETE_ORDER_FAILED);

export const getMessages = actionCreator(ActionTypes.GET_MESSAGES);
export const getMessagesSucceeded = actionCreator(ActionTypes.GET_MESSAGES_SUCCEEDED);
export const getMessagesFailed = actionCreator(ActionTypes.GET_MESSAGES_FAILED);

export const setMessageViewed = actionCreator(ActionTypes.SET_MESSAGE_VIEWED);
export const setMessageViewedSucceeded = actionCreator(ActionTypes.SET_MESSAGE_VIEWED_SUCCEEDED);
export const setMessageViewedFailed = actionCreator(ActionTypes.SET_MESSAGE_VIEWED_FAILED);

export const getMessageAttachments = actionCreator(ActionTypes.GET_MESSAGE_ATTACHMENTS);
export const getMessageAttachmentsSucceeded = actionCreator(ActionTypes.GET_MESSAGE_ATTACHMENTS_SUCCEEDED);
export const getMessageAttachmentsFailed = actionCreator(ActionTypes.GET_MESSAGE_ATTACHMENTS_FAILED);

export const getMessageDetails = actionCreator(ActionTypes.GET_MESSAGE_DETAILS);
export const getMessageDetailsSucceeded = actionCreator(ActionTypes.GET_MESSAGE_DETAILS_SUCCEEDED);
export const getMessageDetailsFailed = actionCreator(ActionTypes.GET_MESSAGE_DETAILS_FAILED);

export const getSearchResults = actionCreator(ActionTypes.GET_SEARCH_RESULTS);
export const getSearchResultsSucceeded = actionCreator(ActionTypes.GET_SEARCH_RESULTS_SUCCEEDED);
export const getSearchResultsFailed = actionCreator(ActionTypes.GET_SEARCH_RESULTS_FAILED);

export const getOrderTemplates = actionCreator(ActionTypes.GET_ORDER_TEMPLATES);
export const getOrderTemplatesSucceeded = actionCreator(ActionTypes.GET_ORDER_TEMPLATES_SUCCEEDED);
export const getOrderTemplatesFailed = actionCreator(ActionTypes.GET_ORDER_TEMPLATES_FAILED);

export const cloneOrder = actionCreator(ActionTypes.CLONE_ORDER);
export const cloneOrderSucceeded = actionCreator(ActionTypes.CLONE_ORDER_SUCCEEDED);
export const cloneOrderFailed = actionCreator(ActionTypes.CLONE_ORDER_FAILED);

export const getCustomerTabInfo = actionCreator(ActionTypes.GET_CUSTOMER_TAB_INFO);
export const getCustomerTabInfoSucceeded = actionCreator(ActionTypes.GET_CUSTOMER_TAB_INFO_SUCCEEDED);
export const getCustomerTabInfoFailed = actionCreator(ActionTypes.GET_CUSTOMER_TAB_INFO_FAILED);

export const getReport1Info = actionCreator(ActionTypes.GET_REPORT_1_INFO);
export const getReport1InfoSucceeded = actionCreator(ActionTypes.GET_REPORT_1_INFO_SUCCEEDED);
export const getReport1InfoFailed = actionCreator(ActionTypes.GET_REPORT_1_INFO_FAILED);

export const getReport2Info = actionCreator(ActionTypes.GET_REPORT_2_INFO);
export const getReport2InfoSucceeded = actionCreator(ActionTypes.GET_REPORT_2_INFO_SUCCEEDED);
export const getReport2InfoFailed = actionCreator(ActionTypes.GET_REPORT_2_INFO_FAILED);

export const getReport3Info = actionCreator(ActionTypes.GET_REPORT_3_INFO);
export const getReport3InfoSucceeded = actionCreator(ActionTypes.GET_REPORT_3_INFO_SUCCEEDED);
export const getReport3InfoFailed = actionCreator(ActionTypes.GET_REPORT_3_INFO_FAILED);

export const getReport4Info = actionCreator(ActionTypes.GET_REPORT_4_INFO);
export const getReport4InfoSucceeded = actionCreator(ActionTypes.GET_REPORT_4_INFO_SUCCEEDED);
export const getReport4InfoFailed = actionCreator(ActionTypes.GET_REPORT_4_INFO_FAILED);

export const getReport4OrderProducts = actionCreator(ActionTypes.GET_REPORT_4_ORDER_PRODUCTS);

export const getReport4OrderProductsSucceeded = actionCreator(ActionTypes.GET_REPORT_4_ORDER_PRODUCTS_SUCCEEDED);
export const getReport4OrderProductsFailed = actionCreator(ActionTypes.GET_REPORT_4_ORDER_PRODUCTS_FAILED);
export const clearReport4OrderProducts = actionCreator(ActionTypes.CLEAR_REPORT_4_ORDER_PRODUCTS);

export const getReport5Info = actionCreator(ActionTypes.GET_REPORT_5_INFO)
export const getReport5InfoSucceeded = actionCreator(ActionTypes.GET_REPORT_5_INFO_SUCCEEDED)
export const getReport5InfoFailed = actionCreator(ActionTypes.GET_REPORT_5_INFO_FAILED)

export const getReport6Info = actionCreator(ActionTypes.GET_REPORT_6_INFO)
export const getReport6InfoSucceeded = actionCreator(ActionTypes.GET_REPORT_6_INFO_SUCCEEDED)
export const getReport6InfoFailed = actionCreator(ActionTypes.GET_REPORT_6_INFO_FAILED)

export const getReport7Info = actionCreator(ActionTypes.GET_REPORT_7_INFO)
export const getReport7InfoSucceeded = actionCreator(ActionTypes.GET_REPORT_7_INFO_SUCCEEDED)
export const getReport7InfoFailed = actionCreator(ActionTypes.GET_REPORT_7_INFO_FAILED)

export const favoriteProduct = actionCreator(ActionTypes.FAVORITE_PRODUCT);
export const favoriteProductSucceeded = actionCreator(ActionTypes.FAVORITE_PRODUCT_SUCCEEDED);
export const favoriteProductFailed = actionCreator(ActionTypes.FAVORITE_PRODUCT_FAILED);

export const getPopularPerCategory = actionCreator(ActionTypes.GET_POPULAR_PER_CATEGORY);
export const getPopularPerCategorySucceeded = actionCreator(ActionTypes.GET_POPULAR_PER_CATEGORY_SUCCEEDED);
export const getPopularPerCategoryFailed = actionCreator(ActionTypes.GET_POPULAR_PER_CATEGORY_FAILED);

export const getOrderBalance = actionCreator(ActionTypes.GET_ORDER_BALANCE);
export const getOrderBalanceSucceeded = actionCreator(ActionTypes.GET_ORDER_BALANCE_SUCCEEDED);
export const getOrderBalanceFailed = actionCreator(ActionTypes.GET_ORDER_BALANCE_FAILED);

export const getBanners = actionCreator(ActionTypes.GET_BANNERS);
export const getBannersSucceeded = actionCreator(ActionTypes.GET_BANNERS_SUCCEEDED);
export const getBannersFailed = actionCreator(ActionTypes.GET_BANNERS_FAILED);

export const getSalesPersonUnderlings = actionCreator(ActionTypes.GET_SALES_PERSON_UNDERLINGS);
export const getSalesPersonUnderlingsSucceeded = actionCreator(ActionTypes.GET_SALES_PERSON_UNDERLINGS_SUCCEEDED);
export const getSalesPersonUnderlingsFailed = actionCreator(ActionTypes.GET_SALES_PERSON_UNDERLINGS_FAILED);

export const getPayments = actionCreator(ActionTypes.GET_PAYMENTS);
export const getPaymentsSucceeded = actionCreator(ActionTypes.GET_PAYMENTS_SUCCEEDED);
export const getPaymentsFailed = actionCreator(ActionTypes.GET_PAYMENTS_FAILED);

export const getPaymentTicketingSOAP = actionCreator(ActionTypes.GET_PAYMENT_TICKETING_SOAP);
export const getPaymentTicketingSOAPSucceeded = actionCreator(ActionTypes.GET_PAYMENT_TICKETING_SOAP_SUCCEEDED);
export const getPaymentTicketingSOAPFailed = actionCreator(ActionTypes.GET_PAYMENT_TICKETING_SOAP_FAILED);

export const getOrdersFromSalesPersons = actionCreator(ActionTypes.GET_ORDERS_FROM_SALES_PERSONS)
export const getOrdersFromSalesPersonsSucceeded = actionCreator(ActionTypes.GET_ORDERS_FROM_SALES_PERSONS_SUCCEEDED)
export const getOrdersFromSalesPersonsFailed = actionCreator(ActionTypes.GET_ORDERS_FROM_SALES_PERSONS_FAILED)
export const toggleOrdersFromSalesPersons = actionCreator(ActionTypes.TOGGLE_ORDERS_FROM_SALES_PERSONS)

export const getProductsOfInterest = actionCreator(ActionTypes.GET_PRODUCTS_OF_INTEREST)
export const getProductsOfInterestSucceeded = actionCreator(ActionTypes.GET_PRODUCTS_OF_INTEREST_SUCCEEDED)
export const getProductsOfInterestFailed = actionCreator(ActionTypes.GET_PRODUCTS_OF_INTEREST_FAILED)

export const storeProductsOfInterest = actionCreator(ActionTypes.STORE_PRODUCTS_OF_INTEREST)
export const storeProductsOfInterestSucceeded = actionCreator(ActionTypes.STORE_PRODUCTS_OF_INTEREST_SUCCEEDED)
export const storeProductsOfInterestFailed = actionCreator(ActionTypes.STORE_PRODUCTS_OF_INTEREST_FAILED)

export const setMessageProdOfInterest = actionCreator(ActionTypes.SET_MESSAGE_PRODUCT_OF_INTEREST)