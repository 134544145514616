//@flow
import * as React from 'react';
import classNames from "classnames";
import "./util.css"

type Props = {
    children: React.Node,
    direction?: 'column' | 'row',
    align?: 'center' | 'flex-start' | 'baseline',
    justifyContent?: 'space-between' | 'center' | 'initial',
    spacedItems?: boolean,
    className?: string,
    style?: Object,
};

const Util = (props: Props) => {
    const {style, className, direction = 'row', align = 'center', justifyContent = 'space-between', spacedItems} = props
    return (
        <div
            style={style}
            className={classNames('util', className, {
                'util--spacedItems': spacedItems,
                ['util--justify-' + justifyContent]: true,
                ['util--align-' + align]: true,
                ['util--direction-' + direction]: true,
            })}
        >
            {props.children}
        </div>
    );
};

export default Util;