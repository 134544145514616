//@flow
import * as React from 'react';
import './listItemSubtitle.css'

type Props = {
    children: React.Node,
};

const ListItemSubtitle = (props: Props) => {
    const {children} = props
    return (
        <div className={'list__item-subtitle'}>
            {children}
        </div>
    );
};

const Primary = (props: Props) => <span className={'list__item-subtitle-primary'}>{props.children}</span>
const Secondary = (props: Props) => <span className={'list__item-subtitle-secondary'}>{props.children}</span>
const Separator = () => <span className={'list__item-subtitle-separator'}>|</span>

ListItemSubtitle.Primary = Primary;
ListItemSubtitle.Secondary = Secondary;
ListItemSubtitle.Separator = Separator;

export default ListItemSubtitle