//@flow
import * as React from 'react';
import {useEffect, useRef} from 'react';
const OnlinePaymentRedirectForm = ({currentPayment, language}) => {
    const submitRef = useRef(null)
    useEffect(() => {
        if (currentPayment) {
            submitRef.current.click()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPayment.transactionTicket])
    return (
        <form action="https://paycenter.piraeusbank.gr/redirection/pay.aspx" method="POST">
            <input name="AcquirerId" type="hidden" value={currentPayment.acquirerId}/>
            <input name="MerchantId" type="hidden" value={currentPayment.merchantId}/>
            <input name="MerchantReference" type="hidden" value={currentPayment.merchantReference}/>
            <input name="PosId" type="hidden" value={currentPayment.posId}/>
            <input name="User" type="hidden" value={currentPayment.username}/>
            <input name="LanguageCode" type="hidden" value={language === 'el' ? 'el-GR' : 'en-US'}/>
            <input name="ParamBackLink" type="hidden" value={'paymentId=' + currentPayment.paymentId + ''}/>
            <input ref={submitRef} type="submit" value="Check out" style={{display: "none"}} />
        </form>
    );
};

export default OnlinePaymentRedirectForm;