// @flow
import React from 'react';
import Title from "../title/title";
import {Trans, useTranslation} from "react-i18next";
import {PAGE_PAYMENTS} from "../../locales/pages/namespaces";
import DateRange from "../form/elements/dateRange/dateRange";
import Button from "../button/button";
import {FormikProvider, useFormik} from "formik";
import "./periodSelect.css"

type Props = {
    initialRange1: Array<Date>,
    initialRange2: Array<Date>,
    onSubmit: Function
}
const PeriodSelect = (props: Props) => {
    const {onSubmit, initialRange1, initialRange2} = props
    const {t} = useTranslation(PAGE_PAYMENTS)
    const formik = useFormik({
        initialValues: {
            range1: initialRange1,
            range2: initialRange2,
        },
        onSubmit: onSubmit,
    })
    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
                <div className={'select-period'}>
                    <div>
                        <Title variant={"h4"}>
                            {t('period_1')}
                        </Title>
                        <DateRange
                            range={formik.values.range1}
                            onChange={(range) => {
                                formik.setFieldValue('range1', range)
                            }}
                        />
                    </div>
                    <div>
                        <Title variant={"h4"}>
                            {t('period_2')}
                        </Title>
                        <DateRange
                            range={formik.values.range2}
                            onChange={(range) => {
                                formik.setFieldValue('range2', range)
                            }}
                        />
                    </div>
                    <Button type={"submit"}>
                        ΑΝΑΖΗΤΗΣΗ
                    </Button>
                </div>
            </form>
        </FormikProvider>
    )
}

export default PeriodSelect;