//@flow
import * as React from 'react';
import Modal from "../modal/modal";
import {useTranslation} from "react-i18next";
import Button from "../button/button";
import {PAYMENT_CONFIRMATION} from "../../locales/components/namespaces";
import {ErrorMessage, FormikProvider} from "formik";
import FormGroup from "../form/formGroup/formGroup";
import TextField from "../form/elements/textfield/textField";
import Error from "../error/error";

type Props = {
    children: React.Node,
    show: boolean,
    onClose: Function,
    onSubmit: Function,
};

const OnlinePaymentConfirm = (props: Props) => {
    const {t} = useTranslation(PAYMENT_CONFIRMATION)
    const {formik, show, onClose, onSubmit, setProceedToPayment} = props
    return (
        <Modal show={show} onClose={onClose}>
            <div>
                <FormikProvider value={formik}>
                    <form onSubmit={onSubmit}>
                        <FormGroup
                            id={'amount'}
                            label={t('Amount')}
                        >
                            <TextField
                                style={{width: '100%', textAlign: 'right', minHeight: '50px'}}
                                type={'number'}
                                id={'amount'}
                                name={'amount'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.amount}
                            />
                            <ErrorMessage name={'amount'} component={Error}/>
                        </FormGroup>
                        <Button.Group spacingTop={2}>
                            <Button type={"submit"} onClick={() => setProceedToPayment(true)}>
                                {t('submit_payment')}
                            </Button>
                            <Button variant={'link'} onClick={onClose}>
                                {t('cancel')}
                            </Button>
                        </Button.Group>
                    </form>
                </FormikProvider>
            </div>
        </Modal>
    );
};

export default OnlinePaymentConfirm;