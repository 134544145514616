import moment from "moment";

export const formatTypes = {
    DEFAULT: 0,
    TITLE: 1,
    DATE_ONLY: 2,
    DATE_ONLY_LONG: 3,
    TITLE_HALF: 4,
    TIME_ONLY: 5,
    DATE_ONLY_FILTER: 6,
    TIME_ONLY_NO_SEC: 7,
}

export const formatDate = (date: Date, format: number = formatTypes.DEFAULT) => {
    const momentDate = moment(date)
    switch (format) {
        case formatTypes.DATE_ONLY_LONG:
            return momentDate.format('D MMM YYYY')
        case formatTypes.DATE_ONLY:
            return momentDate.format('DD/MM/YYYY')
        case formatTypes.DATE_ONLY_FILTER:
            return momentDate.format('YYYY-MM-DD')
        case formatTypes.TITLE:
            return momentDate.format('dddd, D MMMM yyyy HH:mm:ss')
        case formatTypes.TIME_ONLY:
            return momentDate.format('HH:mm:ss')
        case formatTypes.TIME_ONLY_NO_SEC:
            return momentDate.format('HH:mm')
        case formatTypes.TITLE_HALF:
            return momentDate.format('dddd, D MMMM yyyy')
        case formatTypes.DEFAULT:
        default:
            return momentDate.format('MM.DD.YYYY HH:mm:ss')
    }
}

export const getMonths = (startDate, endDate) => {
    const start = moment(startDate)
    const end = moment(endDate)
    return Array
        .from({
            length: end.diff(start, 'month') + 1
        })
        .map((_, index) =>
            moment(start).add(index, 'month')
        )
}