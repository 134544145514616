//@flow
import React, {useEffect, useRef, useState} from 'react';
import Util from "../../components/util/util";
import Title from "../../components/title/title";
import "./announcements.css"
import Divider from "../../components/divider/divider";
import useMessageAction from "../../hooks/message/useMessageAction";
import {AnnouncementTypes, MESSAGE_ACTION_TYPE_B2B, ReadStatus} from "../../helpers/constants";
import Table from "../../components/table/table";
import useMessageData from "../../hooks/message/useMessageData";
import UnstructuredTableView from "../../components/table/partials/views/unstructuredTableView";
import List from "../../components/list/list";
import AnnouncementItem from "./announcementItem";
import {useTranslation} from "react-i18next";
import {PAGE_ANNOUNCEMENTS} from "../../locales/pages/namespaces";
import Images from "../../assets/images";
import ListItem from "../../components/list/partials/listItem/listItem";
import {useParams} from "react-router";
import Dot from "../../components/dot/dot";
import classNames from "classnames";

const Announcements = () => {
    const {t} = useTranslation(PAGE_ANNOUNCEMENTS)
    const [readStatus, setReadStatus] = useState(ReadStatus.Any)
    const [tab, setTab] = useState(AnnouncementTypes.All)
    const [active, setActive] = useState(null)

    const {getMessages, setMessageViewed} = useMessageAction(MESSAGE_ACTION_TYPE_B2B, tab, readStatus)
    const {indexTable} = useMessageData()

    const ref = useRef(null);
    const {id} = useParams()

    const selectReadStatus = (status) => () => {
        const newValue = readStatus === status ? ReadStatus.Any : status
        setReadStatus(newValue)
    }

    useEffect(() => {
        if (tab === AnnouncementTypes.All && indexTable.items.find(i => i.id === id)) {
            const element = document.getElementById(id)
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                })
            }
        }
    }, [id, tab, indexTable.items])

    return (
        <div className={'announcements-page'}>
            <div className="announcements-page__before hide-mobile">
                <img src={Images.pages.announcements.background_2} alt={'background 2'}/>
            </div>
            <div className={"announcements-page__top"}>
                <img src={Images.pages.announcements.background_1} alt={'background 1'}/>
                <div>
                    <Title>
                        {t('announcements')}
                    </Title>
                    <Divider spacingBottom={2}/>
                    <Util justifyContent={'initial'} spacedItems style={{'flexWrap': 'wrap'}}>
                        <Title variant={"h4"} disabled={tab !== AnnouncementTypes.All}
                               onClick={() => setTab(AnnouncementTypes.All)}>
                            {t('announcement_type_' + AnnouncementTypes.All)}
                        </Title>
                        <Title variant={"h4"} disabled={tab !== AnnouncementTypes.Eshop}
                               onClick={() => setTab(AnnouncementTypes.Eshop)}>
                            {t('announcement_type_' + AnnouncementTypes.Eshop)}
                        </Title>
                        <Title variant={"h4"} disabled={tab !== AnnouncementTypes.Company}
                               onClick={() => setTab(AnnouncementTypes.Company)}>
                            {t('announcement_type_' + AnnouncementTypes.Company)}
                        </Title>
                        <Title variant={"h4"} disabled={tab !== AnnouncementTypes.Newsletter}
                               onClick={() => setTab(AnnouncementTypes.Newsletter)}>
                            {t('announcement_type_' + AnnouncementTypes.Newsletter)}
                        </Title>
                        <Title variant={"h4"} disabled={tab !== AnnouncementTypes.CISundaysNews}
                               onClick={() => setTab(AnnouncementTypes.CISundaysNews)}>
                            {t('announcement_type_' + AnnouncementTypes.CISundaysNews)}
                        </Title>
                    </Util>
                    <Divider spacingTop={2}/>
                </div>
            </div>
            <section className={'container'} ref={ref}>
                <List fullHeight>
                    <ListItem contained>
                        <div className="announcements-page__legend">
                            <div
                                className={classNames('item-filter', {
                                    'item-filter--active': readStatus === ReadStatus.Read
                                })}
                                onClick={selectReadStatus(ReadStatus.Read)}
                            >
                                <Title defaultFont variant={'body'}>{t('read')}</Title> <Dot/>
                            </div>
                            <div
                                className={classNames('item-filter', {
                                    'item-filter--active': readStatus === ReadStatus.Unread
                                })}
                                onClick={selectReadStatus(ReadStatus.Unread)}
                            >
                                <Title defaultFont variant={'body'}>{t('unread')}</Title> <Dot type={2}/>
                            </div>
                        </div>
                    </ListItem>
                    <Table
                        dataManager={getMessages}
                        columns={[{
                            header: '',
                            accessor: 'id',
                        }]}
                        data={indexTable}
                        view={UnstructuredTableView}
                        noMeta
                        renderRow={(row) => {
                            const id = row.original.id
                            const isRead = row.original.viewedDate;
                            return (
                                <AnnouncementItem
                                    key={id}
                                    announcement={row.original}
                                    isActive={id === active}
                                    isRead={isRead}
                                    onExpandButtonClick={() => {
                                        setActive(id);
                                        if (!isRead) {
                                            setMessageViewed(id)
                                        }
                                    }}
                                    onCloseButtonClick={() => setActive(null)}
                                />
                            )
                        }}
                    />
                </List>
            </section>
        </div>
    );
};

export default Announcements;