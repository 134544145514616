//@flow
import React from 'react';
import Images from "../../assets/images";
import Icon from "../icon/icon";
import './footer.css'
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_TERMS} from "../../routers/routes";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FOOTER_COMPONENT} from "../../locales/components/namespaces";
import Social from "../social/social";

const Footer = () => {
    const {t} = useTranslation(FOOTER_COMPONENT)
    return (
        <div className={'footer'}>
            <img src={Images.footer.background} alt="background"/>
            <div className="footer__left">
                <Social/>
                <p>{t('copyright')} | <Link to={getRouteUrl(ROUTE_PAGE_TERMS)}>{t('terms')}</Link></p>
            </div>
            <div className="footer__right">
                <div>
                    <button><Icon icon={Images.icons.payments.visaSecure}/></button>
                    <button><Icon icon={Images.icons.payments.visa}/></button>
                    <button><Icon icon={Images.icons.payments.maestro}/></button>
                    <button><Icon icon={Images.icons.payments.mastercard}/></button>
                    <button><Icon icon={Images.icons.payments.mastercardIdentityCheck}/></button>
                </div>
                <p>{t('Developed by')} Ergologic</p>
            </div>
        </div>
    );
};

export default Footer;