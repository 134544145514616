// @flow
import React from 'react'

type Props = {
    price: number,
    currency: string
}

const CrossedPrice = (props: Props) => {
    const { price, currency } = props
    return <span className="title--crossed">{price && price.toFixed ? price.toFixed(2) : price}{currency}</span>
}

export default CrossedPrice;