// @flow
import React from 'react';
import PieChart from "../components/pieChart/pieChart";
import Title from "../../title/title";
import "./topOrders.css"
import {useTranslation} from "react-i18next";
import {PAGE_REPORTS} from "../../../locales/pages/namespaces";
import Divider from "../../divider/divider";
import {useTopOrders} from "./userTopOrders";
import Util from "../../util/util";

const TopOrders = ({selectedStore}) => {
    const {t} = useTranslation(PAGE_REPORTS)
    const {data} = useTopOrders(selectedStore)
    return (
        <div className={'top-orders'}>
            <Divider/>
            <Util>
                <Title>
                    {t('top_purchases')}
                </Title>
                <Title defaultFont variant={'body'}>
                    {t('last_month')}
                </Title>
            </Util>
            <div>
                <PieChart
                    data={data}
                />
            </div>
        </div>
    )
}

export default TopOrders;