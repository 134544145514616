// @flow
import React from 'react';
import Util from "../../util/util";
import Title from "../../title/title";
import Divider from "../../divider/divider";
import LineChart from "../components/lineChart/lineChart";
import {useTranslation} from "react-i18next";
import {PAGE_REPORTS} from "../../../locales/pages/namespaces";
import {useMySalesData} from "./useMySalesData";

const MySales = ({selectedStore}) => {
    const {t} = useTranslation(PAGE_REPORTS)
    const {data} = useMySalesData(selectedStore)
    return (
        <div>
            <Divider/>
            <Util>
                <Title variant={"h2"}>
                    {t('your_purchases')}
                </Title>
                <Title defaultFont variant={'body'}>
                    {t('last_12_months')}
                </Title>
            </Util>
            <Divider/>
            <LineChart
                data={data}
            />
        </div>
    )
}

export default MySales;