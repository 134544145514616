import React, {Fragment} from 'react';
import {Pie} from 'react-chartjs-2';
import {useUserData} from "../../../../hooks/user/useUserData";

type Props = {
    data: {
        labels: Array<string>,
        datasets: Array<{
            label: string,
            data: Array<number>,
            backgroundColor: Array<string>
        }>
    }
}

const PieChart = (props: Props) => {
    const {data} = props
    const {data: userData} = useUserData()
    const currencySymbol = userData ? userData.currencySymbol : ''
    return (
        <Fragment>
            <Pie
                options={{
                    aspectRatio: 16.0 / 9.0,
                    // maintainAspectRatio: false,
                    // responsive: true,
                    layout: {
                        padding: 8
                    },
                    // maintainAspectRatio: false,
                    plugins: {
                        tooltip: {
                            callbacks: {
                                title: (item) => item[0].label,
                                label: (item) => item.raw.toFixed(2) + currencySymbol
                            }
                        },
                        legend: {
                            position: 'right',
                            labels: {

                                boxPadding: 4,
                                font: {
                                    size: 10
                                },
                                usePointStyle: true,
                                pointStyle: 'circle',
                            }
                        }
                    }
                }}
                data={data}
                type={"pie"}
            />
        </Fragment>
    );
};

export default PieChart;