// @flow
import React, {useState} from 'react';
import Modal from "../modal/modal";
import Title from "../title/title";
import Button from "../button/button";
import {useTranslation} from "react-i18next";
import {PRODUCT_COMPONENT} from "../../locales/components/namespaces";
import {useOrderData} from "../../hooks/order/useOrderData";
import "./selectOrderModal.css"

type Props = {
    types?: string,
    show?: boolean,
    onClose: Function,
    onSubmit: Function
}

const SelectOrderModal = (props: Props) => {
    const {types, show, onClose, onSubmit} = props
    const {t} = useTranslation(PRODUCT_COMPONENT)

    const {activeOrder: allActiveOrders} = useOrderData()
    const activeOrder = allActiveOrders.filter(o => types.indexOf(o.type) > -1)

    const [selectedOrder, setSelectedOrder] = useState('');

    const handleChange = (e) => {
        setSelectedOrder(e.target.value)
    }

    return (
        <Modal
            show={show}
            onClose={onClose}
            className={'existing-order-modal'}
        >
            <div>
                <Title defaultFont variant={'h4'}>
                    {t('Select An Order')}
                </Title>
                <p>
                    {t('select_order_message')}
                </p>
                <br/>
                <p>
                    <select
                        className={'select-store'}
                        value={selectedOrder}
                        onChange={handleChange}
                    >
                        {activeOrder.map(o => {
                            const {typeDescription, code, id, products} = o
                            return (
                                <option key={id} value={id}>
                                    #{code} | {typeDescription} | {products.length} Προϊόντα
                                </option>
                            )
                        })}
                    </select>
                </p>
                <Button.Group spacingBelow={1} spacingTop={1}>
                    <Button onClick={() => {
                        let selected = selectedOrder;
                        if (selected === '' && activeOrder.length > 0) {
                            selected = activeOrder[0].id
                        }
                        if (selected) {
                            const order = activeOrder.find(o => o.id === selected)
                            onSubmit(order)
                            onClose()
                        }
                    }}>
                        {t('OK')}
                    </Button>
                    <Button onClick={onClose} variant={"link"}>
                        {t('CANCEL')}
                    </Button>
                </Button.Group>
            </div>
        </Modal>
    )
}

export default SelectOrderModal