//@flow
import * as React from 'react';
import Title from "../../../title/title";
import './listItemTitle.css'

type Props = {
    children: React.Node,
    variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'body',
    style?: {}
};

const ListItemTitle = (props: Props) => {
    const {children, variant, style} = props
    return (
        <div className={'list__item-title'} style={style}>
            <Title variant={variant ? variant : "h4"}>
                {children}
            </Title>
        </div>
    );
};

export default ListItemTitle